import React from "react";
import styled, { css } from "styled-components/macro";
import meet1 from "../../assets/meet1.png";
import meet2 from "../../assets/meet2.png";
import meet3 from "../../assets/meet3.png";
import meet4 from "../../assets/meet4.png";
import meet5 from "../../assets/meet5.png";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	background: #363636;
	color: #e0e0e0;
	padding: 4rem 10rem;

	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}

	h2 {
		text-align: center;
	}

	p {
		text-align: center;
		margin-top: 0.5rem;
		font-weight: 540;
	}
`;

const People = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	margin-top: 30px;

	@media screen and (max-width: 1300px) {
		// grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 400px) {
		grid-template-columns: 1fr;
	}
`;

const Image = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 150px;
		height: 150px;
	}
`;

const Meet = () => {
	return (
		<Container>
			<h2>Meet Our People</h2>
			<p> Expert are always helping you</p>
			<People>
				<Image>
					<img src={meet1} alt="expert" />
				</Image>

				<Image>
					<img src={meet2} alt="expert" />
				</Image>

				<Image>
					<img src={meet3} alt="expert" />
				</Image>

				<Image>
					<img src={meet4} alt="expert" />
				</Image>

				<Image>
					<img src={meet5} alt="expert" />
				</Image>
			</People>
		</Container>
	);
};

export default Meet;
