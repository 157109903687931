import { useState, useEffect, useRef } from "react";

//components
import Navbar from "../components/Navbar/Navbar";
import Banner from "../components/Banner/LoanBanner";
import Requirements from "../components/Requirements/Requirements";
import RequestForm from "../components/RequestForm/RequestForm";
import Footer from "../components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { scrollTo } from "../utils";

const Loan = () => {
	const [loggedIn, setLoggedIn] = useState(false);
	const formRef = useRef();
	// const showForm = () => {
	// 	formRef?.current?.scrollIntoView();
	// };
	useEffect(() => {
		scrollTo({
			ref: formRef,
		});
		// return () => {
		// 	formRef.current.value = "";
		// };
	}, []);

	useEffect(() => {}, [formRef]);

	useEffect(() => {
		const getToken = localStorage.getItem("toast");
		console.log(getToken);
		if (getToken) {
			setLoggedIn(true);
		}
	}, [loggedIn]);

	useEffect(() => {
		console.log(loggedIn);
		if (loggedIn) {
			toast.error("You didn't fill loan request form yet");
		}
		return () => {
			localStorage.removeItem("toast");
		};
	}, [loggedIn]);
	return (
		<>
			<Navbar />
			<Banner />
			<Requirements />
			<RequestForm ref={formRef} />
			<Footer />
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</>
	);
};

export default Loan;
