import * as api from "../api/admin";
import * as actionType from "../constant/actionType";

export const DeleteAdmins = (id) => async (dispatch) => {
	try {
		await api.deleteAdmins(id);
		dispatch({
			type: actionType.CREATEADMIN,
			payload: { id },
		});
		FetchAdmins();
	} catch (error) {
		console.log("error");
	}
};

export const FetchAdmins = () => async (dispatch) => {
	try {
		const { data } = await api.getAdmins();
		dispatch({
			type: actionType.GETADMINS,
			payload: data,
		});
	} catch (error) {
		console.log("error");
	}
};

export const FetchLoanStat = () => async (dispatch) => {
	try {
		const { data } = await api.loanStats();
		dispatch({
			type: actionType.GETLOANS,
			payload: data,
		});
		// console.log(data);
	} catch (error) {
		console.log(error);
	}
};

export const FetchAdmin = (formData) => async (dispatch) => {
	try {
		dispatch({
			type: actionType.GETADMIN,
			payload: formData,
		});
	} catch (error) {
		console.log("error");
	}
};

export const UpdateAdmin = (id) => async (dispatch) => {
	try {
		const { data } = await api.updateAdmins(id);
		dispatch({
			type: actionType.UDPDATEADMIN,
			payload: data,
		});
	} catch (error) {
		console.log(error);
	}
};
