import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import dp from "../../assets/avatar.png";
import axios from "axios";
//components
import Topbar from "../../components/DashboardTopbars/Admin";
import LoanDetails from "../../components/LoanDetails/LoanDetails";
import Repayments from "../../components/Repayments/Repayments";
import LoanTable from "../../components/LoanTable/LoanTable.jsx";


import { FetchBioDaata } from "../../actions/user";
import { FetchLoans } from "../../actions/takeLoan";
import { UniqueLoan } from "..";

const UniqueCustomer = () => {
	const [toggle, setToggle] = useState(1);

	const changeTab = (index) => {
		setToggle(index);
	};

	const dispatch = useDispatch();
	const { id } = useParams();
	// console.log(id);
	const [userLoan, setUserLoan] = useState([]);
	const [userData, setUserData] = useState([]);
	const [loading, setLoading] = useState(false);

	const token = localStorage.getItem("admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const clientObject = useParams();
	const client = clientObject?.user;

	//API CALL TO GET INFORMATION ABOUT CUSTOMER
	const retrieveCustomerLoans = async () => {
		axios
			.get(`user/clients/${client}`)
			.then((res) => {
				console.log(res);

				setUserData(res.data);
			})
			.catch((error) => {
				console.log(error);

				setLoading(false);
			});
		// setUserData(Biodata.data.client[0]);
	};

	//API CALL TO GET ALL LOANS FOR A CUSTOMER
	const getLoans = () => {
		setLoading(true);

		axios
			.get(`admin/loans/${client}`, config)
			.then((res) => {
				console.log(res);
				setLoading(false);
				setUserLoan(res.data.LoanHistories);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};


	const disableRepayment = async () => {
		await axios
			.post(`user/stop-repayment/${id}/${userData?.id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => console.log(response))
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		retrieveCustomerLoans();
	}, []);

	return (
		<Container>
			<Contents>
				<Topbar />

				<Board className="info">
					<h2>Customers</h2>

					{userData && (
						<Info>
							<DpNo>
								<Dp>
									<img src={dp} alt="display" />
								</Dp>

								<div>
									<h3>{userData.customer_name}</h3>
									<h5>Mobile :{userData.phone_number}</h5>
								</div>
							</DpNo>

							<Infos>
								<Btns>
									<Button
										className={toggle === 1 ? "btn active-btn" : "btn"}
										onClick={() => changeTab(1)}
									>
										Bio-Data
									</Button>
									<Button
										className={toggle === 2 ? "btn active-btn" : "btn"}
										onClick={() => changeTab(2)}
									>
										Employee Info
									</Button>
									<Button
										className={toggle === 3 ? "btn active-btn" : "btn"}
										onClick={() => changeTab(3)}
									>
										Account Information
									</Button>
									<Button
										className={toggle === 4 ? "btn active-btn" : "btn"}
										onClick={() => {
											changeTab(4);
											getLoans();
										}}
									>
										Loan
									</Button>
								</Btns>

								<Details
									className={
										toggle === 1 ? "content active-content" : "content"
									}
								>
									<Group>
										<div>
											<h3>Client Name</h3>
											<h5>{userData.customer_name}</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>Phone No</h3>
											<h5>{userData.phone_number}</h5>
										</div>

										<div>
											<h3>Email</h3>
											<h5>{userData.email}</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>Age</h3>
											<h5>50</h5>
										</div>

										<div>
											<h3>Date of Birth</h3>
											<h5>04/11/1985</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>Address</h3>
											<h5>1, Motorways Road, Alausa</h5>
										</div>
									</Group>
									<DisEna>
										<button
											style={{
												background: "#43425D",
												borderBottomLeftRadius: "3px",
											}}
											onClick={disableRepayment}
										>
											DISABLE
										</button>
										<button
											style={{
												background: "#3B86FF",
												borderBottomRightRadius: "3px",
											}}
										>
											ENABLE
										</button>
									</DisEna>
								</Details>

								<Details
									className={
										toggle === 2 ? "content active-content" : "content"
									}
								>
									<Group>
										<div>
											<h3>ORGANIZATION</h3>
											<h5>{userData.company_name}</h5>
										</div>

										<div>
											<h3>RETIREMENT DATE</h3>
											<h5>09-09-2025</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>EMPLOYEE NO</h3>
											<h5>497563</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>EMPLOYMENT DATE</h3>
											<h5>09-09-2020</h5>
										</div>
									</Group>
									<DisEna>
										<button
											style={{
												background: "#43425D",
												borderBottomLeftRadius: "3px",
											}}
											onClick={disableRepayment}
										>
											DISABLE
										</button>
										<button
											style={{
												background: "#3B86FF",
												borderBottomRightRadius: "3px",
											}}
										>
											ENABLE
										</button>
									</DisEna>
								</Details>

								<Details
									className={
										toggle === 3 ? "content active-content" : "content"
									}
								>
									<Group>
										<AccountChild>
											<Childbox>
												<div>
													<h3>BANK NAME</h3>
													<h5>Zenith Bank</h5>
												</div>
											</Childbox>
										</AccountChild>
									</Group>

									<Group>
										<div>
											<h3>ACCOUNT NAME</h3>
											<h5>{userData.customer_name}</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>ACCOUNT NO</h3>
											<h5>{userData.account_number}</h5>
										</div>
									</Group>

									<Group>
										<div>
											<h3>BVN</h3>
											<h5>222205312345675</h5>
										</div>
									</Group>

									<DisEna>
										<button
											style={{
												background: "#43425D",
												borderBottomLeftRadius: "3px",
											}}
											onClick={disableRepayment}
										>
											DISABLE
										</button>
										<button
											style={{
												background: "#3B86FF",
												borderBottomRightRadius: "3px",
											}}
										>
											ENABLE
										</button>
									</DisEna>
								</Details>

								<Details
									className={
										toggle === 4 ? "content active-content" : "content"
									}
								>
									<LoanTable userLoan={userLoan} loading={loading} />
									{/* <UniqueLoan userLoan={userLoan} /> */}
								</Details>
							</Infos>
						</Info>
					)}
				</Board>
			</Contents>
		</Container>
	);
};

export default UniqueCustomer;

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 1rem 2rem 2rem 5rem;
	overflow-x: hidden;
	overflow-y: scroll;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 10px;
		font-weight: 200;
	}
`;
const AccountChild = styled.div`
	display: flex;
	justify-content: space-between !important;
	flex-direction: row !important;
	width: 100% !important;
	@media only screen and (max-width: 480px) {
		justify-content: start !important;
	}
`;
const Childbox = styled.div`
	width: 50% !important;
	text-align: start;
	@media only screen and (max-width: 480px) {
		width: 35% !important;
	}
`;
const StatementButton = styled.button`
	border: none;
	outline: none;
	background: #3b86ff;
	color: #fff;
	padding: 0.5rem 0;
	margin-top: 2rem;
	width: 45%;
	@media only screen and (max-width: 480px) {
		width: 80%;
	}
`;
const DpNo = styled.div`
	display: flex;
	padding: 15px;
	position: relative;

	@media screen and (max-width: 770px) {
		flex-direction: column;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;

		h3 {
			color: #4d4f5c;
			margin-bottom: 15px;

			@media screen and (max-width: 400px) {
				font-size: 15px;
			}

			@media screen and (max-width: 770px) {
				margin-top: 15px;
			}
		}

		h5 {
			color: #707070;
			font-weight: 200;
			font-size: 15px;
		}
	}
`;

const Dp = styled.div`
	width: 70px;
	height: 70px;
	border-radius: 50%;
	margin-right: 150px;

	@media screen and (max-width: 860px) {
		margin-right: 50px;
	}

	@media screen and (max-width: 480px) {
		margin-right: 10px;
	}

	@media screen and (max-width: 400px) {
		width: 60px;
		height: 60px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;

const Info = styled.div`
	background: #fff;
	min-height: 70vh;
`;

const Infos = styled.div`
	display: flex;
	flex-direction: column;
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 150px 150px 200px 150px 150px;
	border: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Details = styled.div``;

const Group = styled.div`
	display: flex;
	padding: 25px 0 10px 15px;
	border-bottom: 1px solid #e9e9f0;

	@media screen and (max-width: 860px) {
		padding: 40px 15px 15px 15px;
	}

	@media screen and (max-width: 555px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}

	div {
		display: flex;
		flex-direction: column;
		width: 400px;

		h3 {
			font-size: 17px;
			font-weight: 550;
			color: #616161;
			margin-bottom: 10px;
		}

		h5 {
			color: #757575;
			font-size: 14px;
			font-weight: 200;
		}
	}
`;

const DisEna = styled.div`
	display: flex;
	justify-content: center;
	padding: 2rem;

	button {
		color: #fff;
		padding: 1rem 2rem;
		outline: none;
		border: none;
		cursor: pointer;
	}
`;
