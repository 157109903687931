import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components/macro";
import { useParams } from "react-router-dom";
import { ReactComponent as Paid } from "../../assets/icons/paid.svg";
import { ReactComponent as Pending } from "../../assets/icons/unpaid.svg";
import { FetchLoan } from "../../actions/takeLoan";
import { FetchCharges } from "../../actions/charges";
import axios from "axios";
//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 15px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		padding: 0;
	}

	@media screen and (max-width: 480px) {
		overflow-x: scroll;
	}
`;

const Button = styled.button`
	width: 10%;
	text-align: center;
	padding: 12px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	&:before {
		background: none !important;
	}
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 768px) {
		width: 20%;
	}
	@media only screen and (max-width: 480px) {
		width: 25%;
	}
`;

const Details = styled.div``;

const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;
	margin-top: 10px;

	@media screen and (max-width: 1090px) {
		width: 800px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: center;
		font-size: 13px;
		color: #208518;
		border-bottom: 1px solid #e9e9f0;
	}
`;

const Unpaid = styled.tr`
	td {
		color: #fb3e0d;
	}
`;

const CurrentBox = styled.div`
	width: 90%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 2rem auto;
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
const CurrentChild = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
	h4 {
		font-weight: 550;
		color: #616161;
	}
	p {
		font-weight: 500;
		color: #757575;
		margin-top: 0.2rem;
	}
	@media only screen and (max-width: 480px) {
		h4 {
			font-size: 0.8rem;
		}
	}
`;
const UniqueLoan = ({ userLoan }) => {
	const client = userLoan?.userId;

	const [oneLoan, setOneLoan] = useState([]);
	const [allLoans, setAllLoans] = useState([]);
	const [toggle, setToggle] = useState(null);
	const token = localStorage.getItem("admin-token");
	const charges = useSelector((state) => state.charges.list);
	const dispatch = useDispatch();
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const retrieveCustomerLoan = () => {
		axios
			.get(`admin/loans/${client}`, config)
			.then((response) => {
				// console.log(response);
				setOneLoan(response.data.LoanHistories);
				setToggle(`loan${response.data.LoanHistories[0]?.loanId}`);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		retrieveCustomerLoan();
	}, [client]);
	useEffect(() => {
		dispatch(FetchCharges());
	}, [dispatch]);
	useEffect(() => {}, [toggle]);
	const changeTab = (index) => {
		setToggle(index);
	};

	return (
		<Container>
			<Btns>
				{oneLoan &&
					oneLoan.map((loan, index) => {
						return (
							<>
								<Button
									key={`loan${loan.loanId}`}
									className={
										toggle === `loan${loan.loanId}` ? "btn active-btn" : "btn"
									}
									onClick={() => changeTab(`loan${loan.loanId}`)}
								>
									{index === 0 ? "Current" : "Loan"}
								</Button>
							</>
						);
					})}
			</Btns>

			{oneLoan &&
				oneLoan.map((loan, index) => {
					const { requested_amount, tenure } = loan;
					return (
						<Details
							key={`loan${loan.loanId}`}
							className={
								toggle === `loan${loan.loanId}`
									? "content active-content"
									: "content"
							}
						>
							<CurrentBox>
								<CurrentChild>
									<h4>LOAN AMOUNT (N)</h4>
									<p>{requested_amount}</p>
								</CurrentChild>

								<CurrentChild>
									<h4>TENURE</h4>
									<p> {tenure}</p>
								</CurrentChild>
								<CurrentChild>
									<h4>NET DISBURSEMENT (N)</h4>
									<p>{requested_amount}</p>
								</CurrentChild>
								{charges &&
									charges.map((charge) => {
										const { name, amount } = charge;
										let uppercaseName = name.toUpperCase();
										return (
											<CurrentChild key={charge.id}>
												<h4>{uppercaseName}</h4>
												<p>{amount}</p>
											</CurrentChild>
										);
									})}
							</CurrentBox>
						</Details>
					);
				})}
		</Container>
	);
};

export default UniqueLoan;
