
export const FETCHCHARGES = 'FETCHCHARGES'

export const TAKELOAN = 'TAKELOAN';
export const CONFIRMLOAN = 'CONFIRMLOAN';
export const LOANDETAILS = 'LOANDETAILS';
export const ACCOUNTBALANCE = 'ACCOUNTBALANCE';
export const LOANHISTORY = 'LOANHISTORY';
export const LOANHISTORIES = 'LOANHISTORIES';

export const FETCHBIODATA = 'FETCHBIODATA';
export const FETCHACCOUNTINFO = 'FETCHACCOUNTINFO';
export const FETCHEMPLOYEEINFO = 'FETCHEMPLOYEEINFO';

export const LOGINUSER = 'LOGINUSER';
export const REGISTERUSER = 'REGISTERUSER';

export const FETCHREPAYMENT = 'FETCHREPAYMENT';

export const CREATEADMIN = 'CREATEADMIN';
export const GETADMINS = 'GETADMINS';
export const GETADMIN = 'GETADMIN';
export const UDPDATEADMIN = 'UDPDATEADMIN';
export const GETLOANS = 'GETLOANS';