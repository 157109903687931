import axios from "axios";
import { userAuthHeader } from "../api/authToken";

// const API = axios.create({baseURL: `http://localhost:8000/api/user`});
const API = axios.create({
	baseURL: `https://fullrange-api.petrongsoftware.com/api/user`,
});

export const requestLoan = (formData) => API.post("/loan-login", formData);
export const confirmLoan = (formData) => API.post("/confirm-loan", formData);
export const userDashboard = (id) =>
	API.get(`/accountBalance/${id}`, { headers: userAuthHeader() });
export const loanHistories = (id) =>
	API.get(`/loans/${id}`, { headers: userAuthHeader() });

export const loanHistory = (client, id) =>
	API.get(`/loan/${client}/${id}`, {
		headers: userAuthHeader(),
	});
export const oneLoanHistory = (client) =>
	API.get(`/loan/${client}`, {
		headers: userAuthHeader(),
	});