import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem 0;
`

const Space = styled.div`
    padding: .5rem;
    width: 150px;
    border-right: 1px solid #E9E9F0;

    @media screen and (max-width: 750px) {
        display: none;
    }
`

const Back = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #3B86FF;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;

    @media screen and (max-width: 750px) {
        /* font-size: 11px; */
    }
`

const Edit = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
        padding: 1rem;
        font-weight: 200;
    }
`

const Form = styled.section`
    width: 700px;
    padding: 2rem  0 3rem 2rem;
    position: relative;

    @media screen and (max-width: 1200px) {
        padding: 2rem  1rem 3rem 1rem;
        width: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        input {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 15px;
            color: #808495;
            font-weight: 100;
            border: 1px solid #E9E9F0;
            outline: none;
            margin-bottom: 15px;
        }

        div {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 400px) {
                flex-direction: column;
            }
        }

        button { 
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 7px;
            width: 150px;
            font-size: 13px;
            border-radius: 3px;
            border: none;
            outline: none;
            background: #3B86FF;
            color: #fff;
            cursor: pointer;
        }
    }

`

const Status = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 15px;
    color: #808495;
    font-weight: 100;
    border: 1px solid #E9E9F0;
    outline: none;
    margin-bottom: 15px;
    width: 49%;

    @media screen and (max-width: 400px) {
        width: 100%;
    }
`
const Rank = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 15px;
    color: #808495;
    font-weight: 100;
    border: 1px solid #E9E9F0;
    outline: none;
    margin-bottom: 15px;
    width: 49%;

    @media screen and (max-width: 400px) {
        width: 100%;
    }
`

const EditAdmin = ({showAdministrators, userid}) => {

    const [adminid, setAdminid] = useState('');
    const [fullname, setFullname] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const postData = (e) => {
        e.preventDefault()
        axios.put(`admin/edit/`, {
                adminid,
                fullname,
                phone_number,
                username,
                password,
            })
    }

    return (
        <Container>
            <Space> 
                <Back onClick={showAdministrators}>Back</Back>
            </Space>
            
            <Edit>
                <h3>Edit Administrators</h3>

                <Form>
                    <form onSubmit={postData}>
                        <input type="text" placeholder='ADMIN ID' onChange={(e) => setAdminid(e.target.value)}/>
                        <input type="text" placeholder='FULL NAME' onChange={(e) => setFullname(e.target.value)}/>
                        <input type="text" placeholder='PHONE NO' onChange={(e) => setPhoneNumber(e.target.value)}/>
                        <input type="text" placeholder='USERNAME' onChange={(e) => setUsername(e.target.value)}/>
                        <input type="password" placeholder='PASSWORD' onChange={(e) => setPassword(e.target.value)}/>
                      
                        <div>
                            <Status>STATUS</Status>
                            <Rank>RANK</Rank>
                        </div>
                        
                        <button type='submit'>Create</button>
                    </form>
                </Form>
            </Edit>
        </Container>
    )
}

export default EditAdmin
