import React, { useEffect } from 'react'

//components
import Navbar from '../components/Navbar/Navbar'
import Banner from '../components/Banner/LoanBanner'
import Takeloan from '../components/CustomerInformation/TakeLoan'
import Footer from '../components/Footer/Footer'
  
const TakeLoan = () => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'auto'})
    }, []);

    return (
        <>
            <Navbar /> 
            <Banner /> 
            <Takeloan />
            <Footer />
        </>
    )
}

export default TakeLoan
