import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { TiSocialFacebook } from "react-icons/ti";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { FaRegAddressBook } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	background: #303030;
	position: relative;
	padding: 4rem 0;

	@media screen and (max-width: 1200px) {
		padding: 4rem 0;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 768px) {
		padding: 4rem 0;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 680px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 570px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 500px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 0;
	}

	@media screen and (max-width: 410px) {
		padding: 4rem 0;
	}
`;

const Wrapper = styled.section`
	width: 100% !important;
	padding: 2rem 0 4rem;
	color: #9e9e9e;
	border-top: 1px solid #9e9e9e;
	@media screen and (max-width: 410px) {
		flex-direction: column;
	}

	@media only screen and (max-width: 480px) {
		padding: 2rem 0.5rem 4rem;
	}
`;
const WrapperChild = styled.div`
	width: 50%;
	display: flex;
	margin: 0 auto;
	justify-content: space-evenly;
	div {
		width: 45%;

		@media screen and (max-width: 570px) {
			width: 50%;
		}

		@media screen and (max-width: 410px) {
			width: 100%;
		}

		h5 {
			margin-bottom: 10px;
			font-size: 16px;
			font-weight: 550;
			@media screen and (max-width: 410px) {
				margin-top: 30px;
			}
		}

		p {
			font-size: 14px;
			font-weight: 200;
			margin-bottom: 5px;
			color: #9e9e9e;
			a {
				color: #9e9e9e;
			}
		}
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
const linkstyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border: 2px solid #9e9e9e;
	border-radius: 50%;
	margin-right: 15px;
`;

const Copyright = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background: #2b2b2b;
	padding: 10px 4rem;
	font-size: 14px;
	color: #9e9e9e;
	border-top: 1px solid #9e9e9e;
	font-weight: 200;
	width: 100vw;
	position: absolute;
	left: 0;
	bottom: 0;

	@media only screen and (max-width: 480px) {
		padding: 0.8rem 0.5rem;
		div:first-of-type {
			width: 100%;
		}
	}
	div {
		display: flex;
		align-items: center;
	}

	a {
		${linkstyle};
	}
`;

const Links = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	margin-left: 40px;

	@media screen and (max-width: 480px) {
		margin: 20px 0 0 0;
		justify-content: end;
	}
`;

const iconstyle = css`
	width: 25px;
	height: 25px;
	color: #bdbdbd;
`;

const Fb = styled(TiSocialFacebook)`
	${iconstyle};
`;

const Tw = styled(AiOutlineTwitter)`
	${iconstyle};
`;

const Gm = styled(AiOutlineGooglePlus)`
	${iconstyle};
`;

const Footer = () => {
	// const location = useLocation();

	return (
		<Container>
			<Wrapper>
				<WrapperChild>
					<div style={{ marginRight: "20px" }}>
						<h5>Other Links</h5>
						<p>
							<Link to="/">Our Policy</Link>
						</p>
						<p>
							<Link to="/">Terms and Conditions</Link>
						</p>
						<p>
							<Link to="/">Privacy Policy</Link>
						</p>
					</div>

					<div>
						<h5>Get In Touch</h5>
						<div
							style={{
								width: "100%",
								display: "flex",
								alignItems: "flex-start",
							}}
						>
							<FaRegAddressBook
								style={{ fontSize: "2rem", marginRight: "0.5rem" }}
							/>
							<p>
								First Floor Plot, Asabi House, Plot 108, Adekunle Fajuyi way,
								Adamasingba, Ibadan
							</p>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								alignItems: "flex-start",
							}}
						>
							<FiPhone style={{ marginRight: "0.5rem" }} />
							<p>+234-818-559-1236 </p>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								alignItems: "flex-start",
							}}
						>
							<AiOutlineMail style={{ marginRight: "0.5rem" }} />
							<p>info@fullrangemfb.com</p>
						</div>
					</div>
				</WrapperChild>
			</Wrapper>

			<Copyright>
				<div>
					Copyright © 2021. All Rights Reserved. | Website Developed by Petrong
					Software Solutions
				</div>

				<Links>
					<a href="/">
						{" "}
						<Fb />{" "}
					</a>
					<a href="/">
						{" "}
						<Tw />{" "}
					</a>
					<a href="/">
						{" "}
						<Gm />{" "}
					</a>
				</Links>
			</Copyright>
		</Container>
	);
};

export default Footer;
