import { combineReducers } from 'redux'
import {loan} from './loan'
import {charges} from './charges'
import {users} from './user'
import {repayment} from './repayments'
import {admin} from './admin'

export default combineReducers({
    loan,
    charges,
    users,
    repayment,
    admin
  });