import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components/macro";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const [phone_number, setPhoneNumber] = useState("");
  const [otp, setotp] = useState("");
  const [click, setClick] = useState(true);
  const history = useHistory();
//   const dispatch = useDispatch();
  const getOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`user/getLoginOtp`, { phone_number })
      .then((response) => {
        setLoading(false);
        // console.log(response);

        toast.success("OTP sent to your email");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        if (error.response?.status === 400) {
          toast.error("Phone number is not registered");
        } else {
          toast.error("Error, try later");
        }

        console.log({ error });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setClick(false);
    axios
      .post(`/user/login`, { phone_number, otp })
      .then((response) => {
        console.log(response)
        setLoading(false);
        let { user, token } = response.data[0];
        console.log("user");
        localStorage.setItem("user-token", token);
        localStorage.setItem("user-info", JSON.stringify(user));
        history.push("/dashboard");
        setClick(true);
      })
      .catch((error) => {
        console.log({ error });
        if (error.response?.status === 400) {
          toast.error("Invalid OTP");
        } else {
          toast.error("Error, try later");
        }
        setLoading(false);
        setClick(true);
      });
  };

  useEffect(() => {}, [loading]);

  // const postData = (e) => {
  //     e.preventDefault()

  //     setLoading(true);

  //     axios.post(`user/login`, {
  //       otp,
  //       phone_number,
  //     }).then(response => {
  //       console.log(response.data)
  //       let { user, token } = response.data[0];
  //       localStorage.setItem('user-token', token)
  //       localStorage.setItem('user-info', JSON.stringify(user))
  //       history.push("./dashboard")
  //       if(response.data.error) {
  //         toast.error('Email or otp Incorrect')
  //         setLoading(false)
  //     }
  //     }).catch(e => {
  //       console.log(e);
  //       if(e) {
  //           toast.error("Phone No doesn't exist")
  //           setLoading(false)
  //       }
  //     });
  // }

  useEffect(() => {}, [phone_number]);

  return (
    <Container>
      <Wrapper>
        <div>
          <HomeBox>
            <Link to="/">Back to Homepage</Link>
          </HomeBox>
          <h2>Dashboard</h2>
          <p>Login with your registered phone number and OTP</p>

          <Form>
            <form>
              <div>
                <input
                  type="text"
                  placeholder="PHONE"
                  name="phone_number"
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                <button
                  className={phone_number !== "" ? "active" : null}
                  onClick={getOtp}
                >
                  Get OTP
                </button>
              </div>
              <div>
                <input
                  type="otp"
                  placeholder="ENTER OTP"
                  onChange={(e) => setotp(e.target.value)}
                  value={otp}
                  // required
                />
              </div>

              <Button
                type="submit"
                className={
                  click
                    ? phone_number !== "" && otp !== ""
                      ? "active"
                      : null
                    : "inactive"
                }
                onClick={handleSubmit}
              >
                LOGIN
              </Button>
            </form>
            <div style={{ textAlign: "center" }}>
              Don't have an account?{" "}
              <span>
                <Link to="/signup" style={{ color: "#3b86ff" }}>
                  Register
                </Link>
              </span>
            </div>
            {/* <div style={{ textAlign: "center" }}>
							<span>
								<Link to="/confirm-email" style={{ color: "#3b86ff" }}>
									Reset otp
								</Link>
							</span>
						</div> */}

            <Load loading={loading}>
              <ReactLoading type={"spin"} color="#000" />
            </Load>
          </Form>
        </div>
      </Wrapper>

      <ToastContainer
        className="toast"
        position="top-right"
        autoClose="4000"
        hideProgressBar="false"
        closeOnClick
      ></ToastContainer>
    </Container>
  );
};

export default Login;

//styles
const Container = styled.section`
  display: flex;
  // justify-content: center;
  // align-items: center;
  background: #caced5;
  padding: 4rem 10rem;

  @media screen and (max-width: 1200px) {
    padding: 4rem 8rem;
  }
  @media screen and (max-width: 1000px) {
    padding: 4rem 7rem;
  }

  @media screen and (max-width: 920px) {
    padding: 4rem 6rem;
  }
  @media screen and (max-width: 768px) {
    padding: 4rem 5rem;
  }
  @media screen and (max-width: 600px) {
    padding: 4rem 4rem;
  }
  @media screen and (max-width: 500px) {
    padding: 4rem 3rem;
  }

  @media screen and (max-width: 450px) {
    padding: 4rem 2rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  width: 100%;
  padding: 3rem 1rem;

  h2 {
    color: #2a7b12;
  }

  p {
    margin-bottom: 15px;
    font-weight: 550;
    font-size: 1.1rem;
    color: #37474f;
  }
`;
const HomeBox = styled.div`
  width: 100% !important;
  text-align: end;
  margin-bottom: 2rem;
  a {
    color: #3b86ff;
    font-size: 600 !important;
  }
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 520px) {
    width: 300px;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  @media screen and (max-width: 340px) {
    width: 100%;
  }
  .active {
    background-color: #01579b;
  }

  .inactive {
    background: #89c2d9;
    cursor: not-allowed;
  }
  form {
    margin-bottom: 20px;
    width: 100%;
    div {
      height: 2.8rem;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 1rem 0;
      button {
        width: 25%;
        padding: 0.5rem !important;
        height: 100%;
        margin-bottom: 15px;
      }
      input {
        outline: none;
        border: 1px solid #a4afb7;
        background: #fff;
        height: 100%;
        padding: 10px 20px;
        width: 72%;
        margin-bottom: 15px;
        font-size: 15px;

        &:last-of-type {
          width: 100%;
        }
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    outline: none;
    border: none;
    background: #a4c2f2;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
  }

  div {
    color: #3b86ff;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;

    span {
      cursor: pointer;
      text-decoration: underline;
      font-size: 1.1rem;
      a {
        margin-left: 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    div {
      font-size: 1rem;
      span {
        font-size: 1rem;
        a {
          margin-left: 0;
        }
      }
    }
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  outline: none;
  border: none;
  background: #a4c2f2;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
`;

const Load = styled.div`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
