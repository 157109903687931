import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//components
import DashboardTopbar from "../../components/DashboardTopbars/Admin";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: calc(100vh - 50px);
	padding: 2rem 2rem 2rem 5rem;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 10px;
		font-weight: 200;
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 10px;
	background: #fff;
	position: relative;

	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}

	div {
		display: flex;
	}
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 120px 150px 120px;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;

	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	width: 150px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	outline: none;
	border: none;
	color: #fff;

	@media screen and (max-width: 350px) {
		font-size: 12px;
		padding: 8px;
	}
`;
const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 15rem 20px 2rem;
	overflow: hidden;
	width: 100%;

	@media screen and (max-width: 1100px) {
		padding: 20px 2rem;
	}

	@media screen and (max-width: 950px) {
		width: 100%;
	}

	@media screen and (max-width: 350px) {
		padding: 20px 1rem;
	}

	h3 {
		font-weight: 400;
	}
`;

const Groups = styled.form`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 4rem 8rem;
	margin-top: 3rem;
	width: 100%;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 900px) {
		padding: 1rem 1rem 4rem 0;
	}

	@media screen and (max-width: 360px) {
		padding: 0 0 4rem 0;
	}

	input {
		border: 1px solid #e8e9ec;
		outline: none;
		padding: 8px 20px;
		color: #000;
		font-size: 14px;
		margin-bottom: 15px;
	}
`;

const Edit = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	width: 70%;

	@media screen and (max-width: 1000px) {
		padding: 2rem 1rem;
	}

	h4 {
		font-weight: 200;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin: 20px 5rem 0 10rem;

		@media screen and (max-width: 1000px) {
			margin: 20px 2rem 0 2rem;
		}

		@media screen and (max-width: 1000px) {
			margin: 20px 0 0 0;
		}

		input {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #808495;
			font-weight: 100;
			width: 100%;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}
		.inactive {
			background: #89c2d9;
			cursor: not-allowed;
		}
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px;
			font-size: 13px;
			background: #3b86ff;
			border-radius: 3px;
			color: #fff;
			width: 150px;
			margin-top: 30px;
			cursor: pointer;
			outline: none;
			border: none;
		}
	}
`;

const AdminSettings = () => {
	const history = useHistory();
	const admin = JSON.parse(localStorage.getItem("admin"));
	const initalValues = {
		fullname: admin.fullname,
		username: admin.username,
		old_password: "",
		password: "",
		password_confirmation: "",
	};
	const [profileInfo, setprofileInfo] = useState(initalValues);
	const [click, setClick] = useState(true);
	const token = localStorage.getItem("admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const handleChange = (e) => {
		setprofileInfo({ ...profileInfo, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setClick(false);
		if (profileInfo.password === profileInfo.password_confirmation) {
			await axios.put(`/admin/update`, profileInfo, config);
			history.push("/admin-dashboard");
		} else {
			setClick(true);
			console.log("Passwords are different");
		}
	};

	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("./admin");
		}
	}, []);

	return (
		<Container>
			<Contents>
				<DashboardTopbar />
				<Board>
					<h2>Settings</h2>

					<Info>
						<Details>
							<h3>Update Profile</h3>

							<Groups onSubmit={handleSubmit}>
								<input
									type="text"
									value={profileInfo.fullname}
									name="fullname"
									placeholder="FULLNAME"
									onChange={handleChange}
								/>
								<input
									type="text"
									value={profileInfo.username}
									placeholder="USERNAME"
									name="username"
									onChange={handleChange}
								/>

								<input
									type="password"
									placeholder="OLD PASSWORD"
									value={profileInfo.old_password}
									name="old_password"
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="NEW PASSWORD"
									value={profileInfo.password}
									name="password"
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="CONFIRM NEW PASSWORD"
									value={profileInfo.password_confirmation}
									name="password_confirmation"
									onChange={handleChange}
								/>
								<Button type="submit" className={!click ? "inactive" : null}>
									Update
								</Button>
							</Groups>
						</Details>
					</Info>
				</Board>
			</Contents>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default AdminSettings;
