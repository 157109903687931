import Navbar from "../Navbar/Navbar";
import Banner from "../Banner/LoanBanner";
import Footer from "../Footer/Footer";
import { Details } from "./Details";
const ConfirmLoanDetails = () => {
	return (
		<div>
			<Navbar />
			<Banner />
			<Details />
			<Footer />
		</div>
	);
};

export default ConfirmLoanDetails;
