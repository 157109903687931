import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import bg from "../../assets/adminbg.png";
import logo from "../../assets/logo.png";

const AdminLogin = () => {
	const [loggedIn, setLoggedIn] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, seterror] = useState("");
	const [click, setClick] = useState(true);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	useEffect(() => {
		const getToken = localStorage.getItem("toast");
		console.log(getToken);
		if (getToken) {
			setLoggedIn(true);
		}
	}, [loggedIn]);

	useEffect(() => {
		console.log(loggedIn);
		if (loggedIn) {
			toast.error("You're not logged in, please do.");
		}
		return () => {
			localStorage.removeItem("toast");
		};
	}, [loggedIn]);

	const postData = (e) => {
		e.preventDefault();
		setLoading(true);
		setClick(false);
		axios
			.post(`admin/login`, {
				username,
				password,
			})
			.then((response) => {
				localStorage.setItem("admin-token", response.data[0]?.token);
				localStorage.setItem("admin", JSON.stringify(response.data[0]?.admin));
				setLoading(false);
				if (response.data.error) {
					toast.error("Wrong Credentials");
					setClick(true);
				} else if (response.data[0].token) {
					history.push("./admin-dashboard");
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toast.error("Error, Try again");
				setClick(true);
			});
	};

	return (
		<Container>
			<Bg />
			<Group>
				<HomeBox>
					<Link to="/">Back to Homepage</Link>
				</HomeBox>
				<div>
					<Info>
						<img src={logo} alt="logo" />
						<h2>Pacioli</h2>
						<p>Welcome back! Please login to your account.</p>
					</Info>
					<Form>
						<form onSubmit={postData}>
							<Input
								type="text"
								placeholder="Username"
								onChange={(e) => setUsername(e.target.value)}
							/>
							<Input
								type="password"
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<RemFor>
								<div>
									<input type="checkbox" /> <p>Remember me</p>
								</div>
								<Link to="/confirm-email">Forgot passsword</Link>
							</RemFor>
							<Button type="submit" className={!click ? "inactive" : null}>
								Login
							</Button>
						</form>
						<Load loading={loading}>
							<ReactLoading type={"spin"} color="#000" />
						</Load>
					</Form>
				</div>

				<Powered>
					<p>Powered by Petrong Software Solutions</p>
					<p>Pacioli</p>
				</Powered>
			</Group>

			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default AdminLogin;

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

const Bg = styled.div`
	background-image: url(${bg});
	background-size: cover;
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 500px;
	}
`;

const Group = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	padding: 4rem 3rem 10px 3rem;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		padding: 4rem 1rem 10px 1rem;
	}

	img {
		height: 44px;
		margin-bottom: 10px;
	}

	h2 {
		font-weight: 500;
		font-size: 25px;
		margin-bottom: 10px;
	}

	p {
		text-align: center;
		font-size: 13px;
		margin-bottom: 30px;
	}
`;
const HomeBox = styled.div`
	width: 100% !important;
	text-align: end;
	position: absolute;
	top: 1%;
	right: 1%;
	a {
		color: #43425d;
		font-size: 600 !important;
	}
`;
const Info = styled.section`
	text-align: center;
	h2 {
		letter-spacing: 2px;
		font-weight: 600;
		color: #43425d;
	}
	p {
		color: #9e9e9e;
		font-size: 0.9rem;
	}
`;

const Form = styled.section`
	position: relative;

	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		.inactive {
			background: #89c2d9;
			cursor: not-allowed;
		}
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 50px;
	}
`;
const Input = styled.input`
	border: none;
	outline: none;
	border-bottom: 1px solid #ccc;
	padding: 8px 20px;
	width: 100%;
	color: #43425d;
	font-weight: 500;
	font-size: 1rem;
	margin-bottom: 2rem;
`;
const RemFor = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
	width: 100%;
	color: #43425d;

	div {
		display: flex;
		align-items: center;
		padding: 0;
		font-size: 13px;
		height: 20px;

		input {
			width: 15px;
			height: 15px;
			margin: 0 10px 0 0;
		}

		p {
			margin: 0;
		}
	}

	a {
		font-size: 13px;
		color: #43425d;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.8rem 1rem;
	color: #fff;
	outline: none;
	border: none;
	background: #43425d;
	width: 150px;
	font-size: 13px;
	border-radius: 3px;
	cursor: pointer;
`;

const Powered = styled.section`
	display: flex;
	justify-content: space-between;
	width: 100%;

	p {
		margin: 0;
		color: #43425d;
		font-size: 12px;
	}
`;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
