import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import AdministratorsTable from "../../components/SuperAdminTables/AdministratorsTable";
import { ReactComponent as Prev } from "../../assets/icons/prev.svg";
import { ReactComponent as Next } from "../../assets/icons/next.svg";
import "react-toastify/dist/ReactToastify.css";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import { DeleteAdmins } from "../../actions/admin";

const Administrators = ({ showEdit, showNew }) => {
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState([]);

	const token = localStorage.getItem("super-admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const retrieveAdmins = async () => {
		// setLoading(true);
		const response = await axios.get(`admin/getall`, config);
		setLoading(false);
		setFormData(response.data);
		console.log(response);
	};

	const onDelete = (id) => {
		axios.delete(`admin/delete/${id}`, config).then(() => {
			retrieveAdmins();
		});
	};

	const history = useHistory();
	useEffect(() => {
		retrieveAdmins();
		onDelete();
	}, []);

	// useEffect(() => {
	// 	if (!localStorage.getItem("admin-token")) {
	// 		history.push("/admin");
	// 	} else if (!localStorage.getItem("super-admin-token")) {
	// 		history.push("/admin-dashboard/login");
	// 	}
	// }, []);

	const logOut = () => {
		localStorage.removeItem("super-admin-token");
		history.push("/admin-dashboard");
	};
	const superAdmin = localStorage.getItem("super-admin-token");
	return (
		<>
			<Container>
				<Contents>
					<Topbar />

					<Board>
						<h2>Super Admin</h2>

						<Info className="info">
							{superAdmin ? (
								<Exit onClick={logOut}>Exit Super Admin</Exit>
							) : null}
							<Btns>
								{/* <Button to="/admin-dashboard/edit">Edit Login</Button> */}
								<Button className="btn active-btn">Administrators</Button>
								<Button to="/admin-dashboard/log">Change Log</Button>
								<Button to="/admin-charges/show">Charges</Button>
							</Btns>

							<AdminDiv>
								<Space>
									<New to="/admin-dashboard/create">NEW ADMIN</New>
								</Space>

								<Table>
									<h3>View Administrators</h3>
									<AdministratorsTable showEdit={showEdit} loading={loading} />
								</Table>
							</AdminDiv>
						</Info>
					</Board>
				</Contents>
			</Container>
			{/* <Pages>
				<div>
					<Prev />
				</div>
				<div style={{ background: "#3B86FF", color: "#fff" }}>1</div>
				<div>2</div>
				<div>3</div>
				<div>4</div>
				<div>5</div>
				<div>
					<Next />
				</div>
			</Pages> */}
		</>
	);
};

export default Administrators;

const Container = styled.div`
	display: flex;
	width: 100%;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	overflow: hidden;
	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 5rem 5rem;
	height: calc(100vh - 60px);
	overflow-x: scroll;
	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}
	h2 {
		font-weight: 200;
		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	min-height: 70vh;
	padding: 10px;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
	div {
		display: flex;
		flex-wrap: wrap;
		/* @media screen and (max-width: 860px) {
			justify-content: center;
		} */
	}
`;
const AdminDiv = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		padding: 0;
	}
	@media only screen and (max-width: 480px) {
		border-bottom: 1px solid #bdbdbd;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	background-color: #eeeeee;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		width: 48%;
		margin-bottom: 0.2rem;
	}
	&:first-of-type {
		@media only screen and (max-width: 480px) {
			margin-right: 0.2rem;
		}
	}
	&:last-of-type {
		@media only screen and (max-width: 480px) {
			margin-left: 0.2rem;
		}
	}
`;

const Space = styled.div`
	padding: 0.7rem;
	width: 15%;
	border-right: 1px solid #e9e9f0;
	@media screen and (max-width: 750px) {
		display: none;
	}
	@media only screen and (max-width: 768px) {
		border-right: none;
		width: 22%;
		a {
			padding: 0.7rem;
		}
	}
	@media only screen and (max-width: 480px) {
		border-right: none;
		width: 50%;
		margin-top: 1rem;
	}
`;

const New = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.6rem 1rem;
	background: #3b86ff;
	font-size: 13px;
	color: #fff;
	cursor: pointer;
	height: 35px;
	border-radius: 3px;
	@media screen and (max-width: 750px) {
		/* font-size: 11px; */
	}
`;

const Table = styled.div`
	flex-direction: column;
	width: 85%;
	padding: 1rem;
	h3 {
		font-weight: 200;
		margin-bottom: 1rem;
	}
	&::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-overflow-style: none;
	@media screen and (max-width: 1300px) {
		overflow-x: scroll;
		width: 100%;
	}
	@media only screen and (max-width: 768px) {
		width: 77%;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;

const Pages = styled.div`
	display: flex;
	justify-content: end;
	&:last-child {
		margin-right: 0;
	}
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;
