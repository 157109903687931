import session from "redux-persist/lib/storage/session";
import * as api from "../api/takeLoan";
import * as actionType from "../constant/actionType";

export const TakeLoan = (formData, history) => async (dispatch) => {
	try {
		const { data } = await api.requestLoan(formData);
		dispatch({
			type: actionType.TAKELOAN,
			payload: data,
		});
		localStorage.setItem("loanInfo", JSON.stringify(data.status));
		if (data.status !== "failure") history.push("/confirm-otp");
		console.log(data);
		
	} catch (error) {
		console.log(error.status);
	}
};

export const ConfirmLoan = (formData, history) => async (dispatch) => {
	try {
		const { data } = await api.confirmLoan(formData);
		dispatch({
			type: actionType.CONFIRMLOAN,
			payload: data,
		});
		console.log(data);
		history.push("/success");
	} catch (error) {
		console.log({error});
	}
};

export const LoanDetails = (formData) => async (dispatch) => {
	try {
		dispatch({
			type: actionType.LOANDETAILS,
			payload: formData,
		});
	} catch (error) {
		console.log("error");
	}
};

export const FetchAccountBalance = (id) => async (dispatch) => {
	try {
		const { data } = await api.userDashboard(id);
		dispatch({
			type: actionType.ACCOUNTBALANCE,
			payload: data,
		});
	} catch (error) {
		console.log(error);
	}
};

export const FetchLoans = (id) => async (dispatch) => {
	try {
		const { data } = await api.loanHistories(id);
		dispatch({
			type: actionType.LOANHISTORIES,
			payload: data,
		});
	} catch (error) {
		console.log(error);
	}
};

export const FetchLoan = (client, id) => async (dispatch) => {
	try {
		const { data } = await api.loanHistory(client, id);
		dispatch({
			type: actionType.LOANHISTORY,
			payload: data,
		});
	} catch (error) {
		console.log(error);
	}
};

export const FetchOneLoan = (client, id) => async (dispatch) => {
	try {
		const { data } = await api.oneLoanHistory(client, id);
		dispatch({
			type: actionType.LOANHISTORY,
			payload: data,
		});
	} catch (error) {
		console.log(error);
	}
};
