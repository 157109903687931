import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../components/pagination/usePagination";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import "react-toastify/dist/ReactToastify.css";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import LogTable from "../SuperAdminTables/LogTable";
import { Pajination } from "../pagination/Pajination";

const AdminLog = () => {
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState([]);
	const token = localStorage.getItem("super-admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const retrieveAdminsLog = () => {
		axios
			.get(`admin/log`, config)
			.then((response) => {
				setFormData(response.data);
				setLoading(false);
				// if(response.data.error) {
				//     toast.error('Email or Password Incorrect')
				//     setLoading(false)
				// }
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	};

	useEffect(() => {
		retrieveAdminsLog();
	}, []);

	const history = useHistory();
	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("/admin");
		} else if (!localStorage.getItem("super-admin-token")) {
			history.push("/admin-dashboard/login");
		}
	}, []);

	const logOut = () => {
		localStorage.removeItem("super-admin-token");
		history.push("/admin-dashboard/login");
	};

	//new pagination

	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 15;
	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;

		setCurrentItems(formData?.slice(itemOffset, endOffset));
	}, [itemOffset, itemsPerPage, formData]);
	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Super Admin</h2>

					<Info className="info">
						<Exit onClick={logOut}>Exit Super Admin</Exit>
						<Btns>
							{/* <Button to="/admin-dashboard/edit">Edit Login</Button> */}
							<Button to="/admin-dashboard/show">Administrators</Button>
							<Button className="btn active-btn">Change Log</Button>
							<Button to="/admin-charges/show">Charges</Button>
						</Btns>

						<Wrap>
							<Log>
								<Table>
									<h3>Change Log</h3>
									<LogTable formData={currentItems} loading={loading} />
								</Table>
							</Log>
						</Wrap>
						{/* <Pagination
							loansPerPage={loansPerPage}
							totalLoans={formData?.length}
							paginate={paginate}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/> */}
						<Pajination
							itemsPerPage={itemsPerPage}
							items={formData}
							setItemOffset={setItemOffset}
						/>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default AdminLog;

const Container = styled.div`
	display: flex;
	width: 100%;
	padding: 0.5rem 0;
	overflow: scroll;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 2rem 2rem 2rem 5rem;
	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 200;
		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	min-height: 70vh;
	padding: 10px;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
	div {
		display: flex;
	}
`;

const Wrap = styled.div`
	width: 100%;
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		padding: 0;
	}
	@media only screen and (max-width: 480px) {
		border-bottom: 1px solid #bdbdbd;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	background-color: #eeeeee;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		width: 48%;
		margin-bottom: 0.2rem;
	}
	&:first-of-type {
		@media only screen and (max-width: 480px) {
			margin-right: 0.2rem;
		}
	}
	&:last-of-type {
		@media only screen and (max-width: 480px) {
			margin-left: 0.2rem;
		}
	}
`;

const Table = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 2rem;
	width: 100%;
	h3 {
		font-weight: 200;
		margin-bottom: 1rem;
	}
	&::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-overflow-style: none;
	@media screen and (max-width: 1300px) {
		padding: 1rem 0;
		width: 100%;
	}

	@media screen and (max-width: 1100px) {
		overflow-x: scroll;
	}
`;

const Log = styled.div`
	width: 100%;
`;

const Pages = styled.div`
	display: flex;
	justify-content: end;
	width: 90%;
	@media screen and (max-width: 1300px) {
		width: 100%;
	}
	&:last-child {
		margin-right: 0;
	}
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;
