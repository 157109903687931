import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//components
import Topbar from "../../components/DashboardTopbars/Admin";
// import {CreateAdmins} from '../../actions/admin';

const CreateAdmin = () => {
	const { id } = useParams();
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [editing, setediting] = useState(false);
	const [adminData, setAdminData] = useState([]);
	const [click, setClick] = useState(true);

	const token = localStorage.getItem("super-admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const [admin, setadmin] = useState({
		fullname: "",
		phone_number: "",
		username: "",
		password: "",
		is_senior: 0,
	});

	const adminInfo = id ? adminData.find((p) => p.id === id) : null;

	const retrieveAdmins = async () => {
		const response = await axios.get(`admin/getall`, config);
		setAdminData(response.data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setClick(false);
		setLoading(true);
		if (id) {
			updateAdmin();
		} else {
			axios
				.post("/admin/create", admin, config)
				.then((response) => {
					toast.success("Admin successfully created");
					setLoading(false);
					setClick(true);
					setadmin(response.data);
					setTimeout(() => {
						history.push("/admin-dashboard/show");
					}, 1500);
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					setClick(true);
					toast.success("Error, try again");
				});
		}
	};

	const onChange = (e) => {
		setadmin({ ...admin, [e.target.name]: e.target.value });
	};

	const updateAdmin = async () => {
		setClick(false);
		await axios
			.put(`/admin/edit/${id}`, admin, config)
			.then((response) => {
				setLoading(false);
				setClick(true);
				toast.success("Update Successful");

				console.log(response);
				setTimeout(() => {
					history.push("/admin-dashboard/show");
				}, 12000);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				setClick(true);
				toast.success("Error, try again");
			});
	};

	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("/admin");
		} else if (!localStorage.getItem("super-admin-token")) {
			history.push("/admin-dashboard/login");
		}
		retrieveAdmins();
	}, []);

	const logOut = () => {
		localStorage.removeItem("super-admin-token");
		history.push("/admin-dashboard/login");
	};

	useEffect(() => {
		if (adminInfo) setadmin(adminInfo);
	}, [adminInfo]);

	const editData = async () => {
		setediting(true);
		const adminID = id;
		console.log(adminID);
		await axios
			.get(`/admin/getone/${adminID}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("super-admin-token"),
				},
			})

			.then((response) => {
				const adminInfo = response.data;
				setadmin(adminInfo);
				console.log(response.data);
				console.log(admin);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	useEffect(() => {
		editData();
	}, []);
	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Super Admin</h2>

					<Info className="info">
						<Exit onClick={logOut}>Exit Super Admin</Exit>
						<Btns>
							{/* <Button to="/admin-dashboard/edit">Edit Login</Button> */}
							<Button className="btn active-btn">Administrators</Button>
							<Button to="/admin-dashboard/log">Change Log</Button>
							<Button to="/admin-charges/show">Charges</Button>
						</Btns>
						<div>
							<Space>
								<Back to="/admin-dashboard/show">Back</Back>
							</Space>
							<Edit>
								{id ? <h3>Update Admin Info</h3> : <h3>New Administrators</h3>}
								<Form>
									<form onSubmit={handleSubmit}>
										<input
											type="text"
											placeholder="FULL NAME"
											name="fullname"
											value={admin?.fullname}
											onChange={onChange}
										/>
										<input
											type="text"
											placeholder="PHONE NO"
											name="phone_number"
											value={admin?.phone_number}
											onChange={onChange}
											required
										/>
										<input
											type="text"
											placeholder="USERNAME"
											name="username"
											value={admin?.username}
											onChange={onChange}
											required
										/>

										<input
											type="password"
											placeholder="PASSWORD"
											name="password"
											value={admin?.password}
											onChange={onChange}
											required
										/>
										{admin?.password?.includes(
											"." || "," || "!" || "*" || "-" || "_"
										) ? (
											<p
												style={{
													fontSize: "0.8rem",
													marginBottom: "0.4rem",
													marginTop: "-0.4rem",
													color: "red",
												}}
											>
												Enter password containing only letters and numbers
											</p>
										) : null}
										<div>
											<Rank
												name="is_senior"
												onChange={onChange}
												value={admin?.is_senior}
											>
												<option value={0}>ADMIN</option>
												<option value={1}>SENIOR ADMIN</option>
											</Rank>
										</div>
										<button type="submit" className={!click ? "update" : null}>
											{id ? "Update" : "Create"}
										</button>
									</form>
								</Form>
							</Edit>
						</div>
						<Load
							style={{ display: loading ? "block" : "none" }}
							loading={loading}
						>
							<ReactLoading type={"spin"} color="#000" />
						</Load>
						<ToastContainer
							className="toast"
							position="top-right"
							autoClose="4000"
							hideProgressBar="false"
							closeOnClick
						></ToastContainer>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default CreateAdmin;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Container = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem 0;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 2rem 2rem 2rem 5rem;
	@media screen and (max-width: 600px) {
		padding: 2rem;
	}
	h2 {
		margin-bottom: 20px;
		font-weight: 200;
		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 10px;
	min-height: 70vh;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
	div {
		display: flex;
	}
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		padding: 0;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	background-color: #eeeeee;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		width: 50%;
		margin-buttom: 0.5rem;
	}
`;

const Space = styled.div`
	padding: 0.5rem;
	width: 150px;
	border-right: 1px solid #e9e9f0;
	@media screen and (max-width: 750px) {
		display: none;
	}
`;

const Back = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: #3b86ff;
	color: #fff;
	cursor: pointer;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	width: 100%;
	@media screen and (max-width: 750px) {
		/* font-size: 11px; */
	}
`;

const Edit = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	h3 {
		padding: 1rem;
		font-weight: 200;
	}
`;

const Form = styled.section`
	width: 700px;
	padding: 2rem 0 3rem 2rem;
	position: relative;
	@media screen and (max-width: 1200px) {
		padding: 2rem 1rem 3rem 1rem;
		width: 100%;
	}
	form {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		color: #000;
		input {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #000;
			font-weight: 400;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}
		div {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 400px) {
				flex-direction: column;
			}
		}
		.inactive {
			background: #89c2d9;
			cursor: not-allowed;
		}
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 0.6rem 0;
			width: 120px;
			font-size: 13px;
			border-radius: 3px;
			border: none;
			outline: none;
			background: #3b86ff;
			color: #fff;
			cursor: pointer;
		}
	}
`;

const Status = styled.select`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 15px;
	color: #808495;
	font-weight: 100;
	border: 1px solid #e9e9f0;
	outline: none;
	margin-bottom: 15px;
	width: 49%;
	cursor: pointer;
	@media screen and (max-width: 400px) {
		width: 100%;
	}
`;
const Rank = styled.select`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 15px;
	color: #808495;
	font-weight: 100;
	border: 1px solid #e9e9f0;
	outline: none;
	margin-bottom: 15px;
	width: 49%;
	cursor: pointer;
	@media screen and (max-width: 400px) {
		width: 100%;
	}
`;
