import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import dp from "../../assets/avatar.png";
import "./styles.css";
import Success from "./Success";

import { FetchCharges } from "../../actions/charges";
import { ConfirmLoan } from "../../actions/takeLoan";
import { FetchBioDaata } from "../../actions/user";

const TakeLoan = () => {
	const [toggle, setToggle] = useState(1);
	const [success, setSuccess] = useState(false);

	const changeTab = (index) => {
		setToggle(index);
	};

	const showSuccess = () => {
		setSuccess(true);
	};

	const dispatch = useDispatch();
	const history = useHistory();
	const userInfo = JSON.parse(localStorage.getItem("user-loan"));
	let user_id = userInfo?.id;
	let net_pay = userInfo?.salary;

	const userData = useSelector((state) => state.loan.loanDetails);
	const userBiodata = useSelector((state) => state.users.biodata);
	const requested_amount = localStorage.getItem("amount");

	const [calcNetDisburst, setcalcNetDisburst] = useState([]);
	const [calcCollectionAmount, setcalcCollectionAmount] = useState([]);
	const [monthly_installment, setMonthlyInstallment] = useState([]);
	const [chargeArray, setchargeArray] = useState([]);

	const [interest_rate, setInterestRate] = useState([]);
	const [fixedCharges, setFixedCharges] = useState([]);
	const [percentage, setPercentage] = useState([]);

	const [tenure, setTenure] = useState(1);
	const [loan_type, setLoanType] = useState("");

	// const onChange = (e) => {
	// 	setLoanType({ ...loan, [e.target.name]: e.target.value });
	// };

	useEffect(() => {
		axios.get(`user/admin-charges`).then((response) => {
			let charges = response.data;
			// console.log(charges);
			setchargeArray(charges);
			let interest = [];
			let fixed = [];
			let percentage = [];

			for (let index = 0; index < charges.length; index++) {
				if (charges[index].charge_type === "interest_rate") {
					interest.push(charges[index]?.amount);
				} else if (charges[index].charge_type === "percentage") {
					percentage.push(charges[index]?.amount);
				} else if (charges[index].charge_type === "fixed") {
					fixed.push(charges[index]?.amount);
				}
			}
			if (fixed.length >= 1) {
				let sumFixedValues = fixed.reduce(function (accumulator, currentValue) {
					return accumulator + currentValue;
				});
				setFixedCharges(sumFixedValues);
				console.log(fixedCharges);
			}
			if (percentage.length >= 1) {
				let sumPercentageValues = percentage.reduce(function (
					accumulator,
					currentValue
				) {
					return ((accumulator + currentValue) / 100) * requested_amount;
				});
				setPercentage(
					(Number(sumPercentageValues) / 100) * Number(requested_amount)
				);
			}

			if (interest.length >= 1) {
				let sumInterestRateValues = interest.reduce(function (
					accumulator,
					currentValue
				) {
					return accumulator + currentValue;
				});
				setInterestRate(
					(Number(sumInterestRateValues) / 100) * Number(requested_amount)
				);
			}
		});
	}, []);

	useEffect(() => {
		// const calcInterest = Math.floor(interest_rate / 100);
		const leftSide = Math.floor(Number(requested_amount) / Number(tenure));
		// console.log("left", leftSide);
		// const rightSide = Math.floor(Number(requested_amount) * calcInterest);
		// console.log("right", rightSide);
		// console.log("interst", calcInterest);
		// console.log(requested_amount);
		// console.log(tenure);

		setMonthlyInstallment(Math.floor(leftSide + interest_rate));
		// setMonthlyInstallment(
		// 	Math.floor(
		// 		Number(requested_amount) / Number(tenure) +
		// 			Number(requested_amount) * calcInterest
		// 	)
		// );
	}, [tenure, requested_amount, fixedCharges, interest_rate]);

	useEffect(() => {
		if (fixedCharges.length === 0) setFixedCharges(0);
		// console.log(fixedCharges);
		// console.log(percentage);
		// console.log(interest_rate);
		const totalCharges = fixedCharges + percentage;
		setcalcNetDisburst(
			Math.floor(Number(requested_amount) - Number(totalCharges))
		);
		// console.log(totalCharges);
		// console.log(fixedCharges);
		// console.log(percentage);
		// console.log(interest_rate);
	}, [interest_rate, requested_amount, fixedCharges, percentage]);

	useEffect(() => {
		setcalcCollectionAmount(
			Math.floor(
				Number(requested_amount) +
					Number(fixedCharges) +
					Number(percentage) +
					Number(interest_rate)
			)
		);
	}, [interest_rate, requested_amount, fixedCharges, percentage]);

	// console.log(calcNetDisburst);

	const newDate = new Date();
	let date_issued =
		newDate.getFullYear() +
		"-" +
		(newDate.getMonth() + 1) +
		"-" +
		newDate.getDate() +
		" " +
		newDate.getHours() +
		":" +
		newDate.getMinutes() +
		":" +
		newDate.getSeconds();

	const customerLoan = {
		user_id,
		requested_amount,
		date_issued,
		net_pay,
		tenure,
		net_disburs: calcNetDisburst,
		total_collection_amount: calcCollectionAmount,
		monthly_installment,
		loan_type,
		interest_rate,
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(customerLoan);
		localStorage.setItem("customerLoan", JSON.stringify(customerLoan));
		history.push("/confirm-loan");
	};
	const newdate = date_issued.split(" ");
	useEffect(() => {
		dispatch(FetchCharges());
	}, [dispatch]);

	useEffect(() => {
		dispatch(FetchBioDaata(user_id));
	}, [user_id, dispatch]);

	const EmployeeData = () => {
		const userInfo = JSON.parse(localStorage.getItem("user-info"));
		return (
			<>
				<Group>
					<div>
						<h3>ORGANIZATION</h3>
						<h5>{userInfo?.company_name}</h5>
					</div>

					<div>
						<h3>RETIREMENT DATE</h3>
						<h5>{}</h5>
					</div>
				</Group>

				<Group>
					<div>
						<h3>EMPLOYEE NO</h3>
						<h5>{}</h5>
					</div>
				</Group>

				<Group>
					<div>
						<h3>EMPLOYMENT DATE</h3>
						<h5>{}</h5>
					</div>
				</Group>
			</>
		);
	};

	const AccountInfo = () => {
		return (
			<>
				<Group>
					<div>
						<h3>BANK NAME</h3>
						<h5>{userInfo?.bank_code}</h5>
					</div>
				</Group>

				<Group>
					<div>
						<h3>ACCOUNT NAME</h3>
						<h5>{userInfo?.customer_name}</h5>
					</div>
				</Group>

				<Group>
					<div>
						<h3>ACCOUNT NO</h3>
						<h5>{userInfo?.account_number}</h5>
					</div>
				</Group>

				<Group>
					<div>
						<h3>BVN</h3>
						<h5>{}</h5>
					</div>
				</Group>
			</>
		);
	};

	const BioData = () => {
		return (
			<>
				<Group>
					<div>
						<h3>Client Name</h3>
						<h5>{userInfo?.customer_name}</h5>
					</div>
				</Group>

				<Group>
					<div>
						<h3>Phone No</h3>
						<h5>{userInfo?.phone_number}</h5>
					</div>

					<div>
						<h3>Email</h3>
						<h5>{userInfo?.email}</h5>
					</div>
				</Group>

				{/* <Group>
					<div>
						<h3>Age</h3>
						<h5></h5>
					</div>

					<div>
						<h3>Date of Birth</h3>
						<h5></h5>
					</div>
				</Group> */}

				{/* <Group>
					<div>
						<h3>Address</h3>
						<h5></h5>
					</div>
				</Group> */}
			</>
		);
	};

	return (
		<Container className="info">
			{userInfo && (
				<>
					<h1>Customer Information</h1>
					<p> Confirm that the details retrieved match your profile</p>

					<Wrapper>
						<DpNo>
							<div>
								<h3>{userBiodata?.customer_name}</h3>
								<h5>Mobile : {userBiodata.phone_number}</h5>
							</div>
						</DpNo>

						<Infos>
							<Btns>
								<Button
									className={toggle === 1 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(1)}
								>
									Bio-Data
								</Button>
								<Button
									className={toggle === 2 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(2)}
								>
									Employee Info
								</Button>
								<Button
									className={toggle === 3 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(3)}
								>
									Account Information
								</Button>
								<Button
									className={toggle === 4 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(4)}
								>
									Loan Details
								</Button>
							</Btns>
							<Details
								className={toggle === 1 ? "content active-content" : "content"}
							>
								<BioData />
							</Details>
							<Details
								className={toggle === 4 ? "content active-content" : "content"}
							>
								<form onSubmit={handleSubmit}>
									<InfoDiv style={{ padding: "0 0.5rem" }}>
										<p style={{ marginBottom: 0, fontSize: "0.8rem" }}>
											Kindly input your Loan Tenure and select your loan type,
											then confirm that other details are correct before
											clicking the Proceed button at the bottom of this page
										</p>
									</InfoDiv>
									<Grid>
										<Range>
											<div className="field">
												<p style={{ display: "block", margin: "0 0 2rem 0" }}>
													MOVE TO CHOOSE LOAN TENURE (MONTH)
												</p>
												<input
													type="range"
													min="1"
													max="18"
													value={tenure}
													onChange={(e) => setTenure(e.target.value)}
												/>
											</div>
										</Range>
										<div>
											{/* <p style={{ textAlign: "start", margin: 0 }}>
												SELECT LOAN TYPE
											</p>
											<select
												className="style-input"
												value={loan_type}
												onChange={(e) => setLoanType(e.target.value)}
												required
											>
												<option value="">Please select</option>
												<option value="new_loan">NEW LOAN</option>
												<option value="topup_loan">TOPUP LOAN</option>
											</select> */}
										</div>
										<div>
											INPUT LOAN TENURE: (MONTH)
											<input
												id="tenure"
												className="style-input"
												type="number"
												value={tenure}
												onChange={(e) => setTenure(e.target.value)}
												placeholder="TENURE"
												required
											/>
										</div>
										<div>
											REQUESTED AMOUNT: (₦)
											<Box>{requested_amount ? requested_amount : ""}</Box>
										</div>
										{/* <div>
											AMOUNT IN WORDS:
											<Box> </Box>
										</div> */}
										<div>
											APPROVED AMOUNT: (₦)
											<Box>{requested_amount ? requested_amount : ""}</Box>
										</div>
										<div>
											DATE ISSUED:
											<Box>{newdate[0]} </Box>
										</div>

										{tenure === "" ? (
											<div>
												MONTHLY INSTALLMENT: (₦)
												<Box> </Box>
											</div>
										) : (
											<div>
												MONTHLY INSTALLMENT: (₦)
												<Box>{monthly_installment}</Box>
											</div>
										)}

										<div>
											NET PAY(SALARY): (₦)
											<Box>{net_pay ? net_pay : ""}</Box>
										</div>
										{chargeArray &&
											chargeArray.map((charge, index) => (
												<div key={index}>
													{charge.name}:{" "}
													{charge.charge_type === "fixed" ? "(₦)" : "(%)"}
													<Box>{charge.amount}</Box>
												</div>
											))}

										<div>
											NET DISBURSEMENT: (₦)
											<Box>{calcNetDisburst ? calcNetDisburst : ""}</Box>
										</div>
										<Proceed>PROCEED</Proceed>
									</Grid>
								</form>
							</Details>
							<Details
								className={toggle === 2 ? "content active-content" : "content"}
							>
								<EmployeeData />
							</Details>
							<Details
								className={toggle === 3 ? "content active-content" : "content"}
							>
								<AccountInfo />
							</Details>
						</Infos>
					</Wrapper>
				</>
			)}
			{/* {!userInfo && (
				<Wrapper success={success}>
					<Success />
				</Wrapper>
			)} */}
		</Container>
	);
};

export default TakeLoan;

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4rem 10rem;
	background: #e8e9ec;

	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}

	h1 {
		text-align: center;
	}

	p {
		margin: 10px 0 2rem 0;
		font-size: 17px;
		text-align: center;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: #fff;
	width: 100%;
`;

const DpNo = styled.div`
	display: flex;
	padding: 15px;

	@media screen and (max-width: 330px) {
		flex-direction: column;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-weight: 600;
		h3 {
			color: #4d4f5c;
			margin-bottom: 15px;

			@media screen and (max-width: 400px) {
				font-size: 15px;
			}

			@media screen and (max-width: 330px) {
				margin-top: 15px;
			}
		}

		h5 {
			color: #707070;
			font-weight: 100;
			font-size: 14px;
		}
	}
`;

const Dp = styled.div`
	width: 90px;
	height: 90px;
	border-radius: 50%;
	margin-right: 150px;

	@media screen and (max-width: 860px) {
		margin-right: 50px;
	}

	@media screen and (max-width: 480px) {
		margin-right: 10px;
	}

	@media screen and (max-width: 400px) {
		width: 60px;
		height: 60px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;

const Infos = styled.div`
	display: flex;
	flex-direction: column;
`;

const InfoDiv = styled.div`
	width: 70%;
	margin: 0 auto;

	@media only screen and (max-width: 480px) {
		width: 95%;
	}
`;
const Btns = styled.div`
	display: grid;
	grid-template-columns: 150px 150px 200px 150px;
	border: 1px solid #e9e9f0;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;

	&:hover {
		background: #023e8a;
	}

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Details = styled.div``;

const Group = styled.div`
	display: flex;
	padding: 40px 0 15px 50px;
	border-bottom: 1px solid #e9e9f0;

	@media screen and (max-width: 860px) {
		padding: 40px 15px 15px 15px;
	}

	@media screen and (max-width: 555px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}

	div {
		display: flex;
		flex-direction: column;
		width: 400px;

		h3 {
			font-weight: 550;
			color: #4d4f5c;
			margin-bottom: 15px;
		}

		h5 {
			color: #757575;
			font-size: 15px;
			font-weight: 500;
		}
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;
	grid-row-gap: 1rem;
	padding: 15px 15px 60px 15px;
	width: 80%;
	margin: 0 auto;
	position: relative;

	@media screen and (max-width: 860px) {
		width: 100%;
	}

	@media screen and (max-width: 610px) {
		grid-template-columns: 1fr;
	}
	.style-input {
		outline: none;
		border: 1px solid #bbb;
		padding: 8px 20px;
		color: #bbb;
		font-size: 14px;
		font-weight: 500;
		width: 100%;
		::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
	.style-select {
		outline: none;
		border: 1px solid #bbb;
		padding: 8px 20px;
		color: #bbb;
		font-size: 14px;
		font-weight: 500;
	}
	div {
		color: #bbb;
	}
`;

const Box = styled.div`
	outline: none;
	border: 1px solid #bbb;
	padding: 8px 20px;
	color: #bbb;
	font-size: 14px;
	font-weight: 500;
	min-height: 2.5rem;
	.charges {
		width: 100%;
	}
`;

const Range = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;

	.field {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		text-align: start;
	}
`;

const Proceed = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.7rem 1.5rem;
	border-radius: 3px;
	background: #3b86ff;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	position: absolute;
	right: 15px;
	bottom: 15px;
	cursor: pointer;
	outline: none;
	border: none;
`;
