import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { ReactComponent as Prev } from "../../assets/icons/prev.svg";
import { ReactComponent as Next } from "../../assets/icons/next.svg";

const Pagination = ({ loansPerPage, totalLoans, paginate }) => {
	const [pageBound, setPageBound] = useState(3);
	const [upperPageBound, setUpperPageBound] = useState(3);
	const [lowerPageBound, setLowerPageBound] = useState(0);
	const [isPrevBtnActive, setIsPrevButtonActive] = useState("disabled");
	const [isNextBtnActive, setIsNextBtnActive] = useState("");
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalLoans / loansPerPage); i++) {
		pageNumbers.push(i);
	}
	
	return (
		<Pages>
			<Paginate>
				<div>
					<Prev />
				</div>
				{pageNumbers.map((number) => {
					return <div onClick={() => paginate(number)}>{number}</div>;
				})}

				<div>
					<Next />
				</div>
			</Paginate>
		</Pages>
	);
};

export default Pagination;

const Pages = styled.div`
	display: flex;
	justify-content: flex-end;
	&:last-child {
		margin-right: 0;
	}
`;
const Paginate = styled.div`
	width: 20vw;
	display: flex;
	justify-content: flex-end;
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;
