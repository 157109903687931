import React, {useState} from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { useTable } from "react-table";
import ReactLoading from "react-loading";


import { ReactComponent as View } from "../../assets/icons/search.svg";

const CustomersTableAdmin = ({ formData, loading }) => {
	let dataInfo = formData;
	
	return (
		<Table>
			<tr>
				<th>#</th>
				<th>CLIENT NAMES</th>
				<th>PHONE NO</th>
				<th>GROUP NAME/EMPLOYER</th>
				<th>LOAN AMOUNT</th>
				<th>ACTIONS</th>
			</tr>

			{dataInfo &&
				dataInfo.map((data) => {
					return (
						<tr key={data.id}>
							<td>{data.id}</td>
							<td>{data.customer_name}</td>
							<td>{data.phone_number}</td>
							<td>{data.company_name}</td>
							<td>{data.requested_amount}</td>
							<td>
								<Link to={`/admin-dashboard/customers/${data.id}`}>
									<View />
								</Link>
							</td>
						</tr>
					);
				})}
			<Loader style={{ display: loading ? "block" : "none" }} loading={loading}>
				<ReactLoading type={"spin"} color="#000" />
			</Loader>
			
		</Table>
	);
};

export default CustomersTableAdmin;



//styles
const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;

	@media screen and (max-width: 1500px) {
		width: 1000px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: center;
		font-size: 13px;
		border-bottom: 1px solid #e9e9f0;
	}
`;
const Loader = styled.div`
	/* display: ${({ loading }) => (loading ? "flex" : "none")}; */
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;