import { Route, Redirect } from "react-router-dom";

export const LoanProtectedRoute = ({ component: Component, ...rest }) => {
	const Loan = localStorage.getItem("Init");

	const isLoggedIn = Loan;

	const toast = "load toast";
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isLoggedIn) {
					return <Component {...props} />;
				} else {
					localStorage.setItem("toast", toast);
					return (
						<Redirect
							to={{
								pathname: "/loan",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};
