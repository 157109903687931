import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { Link, useParams } from "react-router-dom";
import Topbar from "../../components/DashboardTopbars/Admin";
import ReactLoading from "react-loading";
import { ReactComponent as Paid } from "../../assets/icons/paid.svg";
import { ReactComponent as Pending } from "../../assets/icons/unpaid.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

//styles
const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const Btns = styled.div`
  display: grid;
  grid-template-columns: 150px 150px;
  border: 1px solid #e9e9f0;
  border-right: none;
  border-left: none;
  color: #43425d;
  padding: 0 15px;
  height: 3.5rem;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  outline: none;
  border-left: 1px solid #e9e9f0;
  border-right: 1px solid #e9e9f0;
  border-top: none;
  border-bottom: none;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: 860px) {
    margin-right: 0;
  }
`;

const Details = styled.div``;
const Board = styled.div`
  background: #f0f0f7;
  height: 100%;
  padding: 1rem 2rem 2rem 4rem;

  @media screen and (max-width: 600px) {
    padding: 2rem;
  }

  h2 {
    margin-bottom: 10px;
    font-weight: 200;
  }
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 190px;

  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Group = styled.div`
  display: flex;
  padding: 25px 0 10px 15px;
  border-bottom: 1px solid #e9e9f0;

  @media screen and (max-width: 860px) {
    padding: 40px 15px 15px 15px;
  }

  @media screen and (max-width: 555px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 400px;

    h3 {
      font-size: 17px;
      font-weight: 400;
      color: #4d4f5c;
      margin-bottom: 10px;
    }

    h5 {
      color: #43425d;
      font-size: 14px;
      font-weight: 200;
    }
  }
`;
const Info = styled.div`
  background: #fff;
  min-height: 70vh;
  margin: 4rem auto;
  h3 {
    font-size: 1.5rem;
    margin: 1.5rem 0 1.2rem 1rem;
    padding-top: 1rem;
    color: #4d4f5c;
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1090px) {
    width: 800px;
  }

  th {
    background: #e9e9f0;
    color: #808495;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    border: none;
  }

  td {
    padding: 8px;
    color: #43425d;
    text-align: center;
    font-size: 13px;
    color: #208518;
    border-bottom: 1px solid #e9e9f0;
  }
`;
const CurrentBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem auto;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const CurrentChild = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  h4 {
    font-weight: 550;
    color: #616161;
  }
  p {
    font-weight: 500;
    color: #757575;
    margin-top: 0.2rem;
  }
  @media only screen and (max-width: 480px) {
    h4 {
      font-size: 0.8rem;
    }
  }
`;
const TableBox = styled.div`
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media screen and (max-width: 1100px) {
    overflow-x: scroll;
  }
`;
const Loader = styled.div`
  /* display: ${({ loading }) => (loading ? "flex" : "none")}; */
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoanDetails = () => {
  const [toggle, setToggle] = useState(1);
  const [loanInfo, setLoanInfo] = useState({});
  const [charges, setCharges] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useParams();
  const { id } = useParams();
  const token = localStorage.getItem("admin-token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const getCharges = () => {
    axios
      .get(`user/admin-charges`)
      .then((res) => {
        // console.log(res);
        setCharges(res.data);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error fetching charges");
      });
  };
  const getLoanInfo = () => {
    setLoading(true);
    axios
      .get(`admin/loan/${user}/${id}`, config)
      .then((res) => {
        console.log(res);
        setLoanInfo(res.data.loanHistory[0]);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error fetching loan info");
      });

    getCharges();
    setLoading(false);
  };

  useEffect(() => {
    getLoanInfo();
  }, []);

  const changeTab = (index) => {
    setToggle(index);
  };
  useEffect(() => {}, [loanInfo, charges]);

  const { requested_amount, tenure, monthly_installment, repayments } =
    loanInfo;
  return (
    <Container>
      <Contents>
        <Topbar />
        <Board className="info">
          <Info>
            <h3>Loan Details</h3>
            <Btns>
              <Button
                className={toggle === 1 ? "btn active-btn" : "btn"}
                onClick={() => changeTab(1)}
              >
                Loan
              </Button>
              <Button
                className={toggle === 2 ? "btn active-btn" : "btn"}
                onClick={() => changeTab(2)}
              >
                Repayment
              </Button>
            </Btns>

            <Details
              className={toggle === 1 ? "content active-content" : "content"}
            >
              <CurrentBox>
                <CurrentChild>
                  <h4>LOAN AMOUNT (₦)</h4>
                  <p>{requested_amount} </p>
                </CurrentChild>

                <CurrentChild>
                  <h4>TENURE</h4>
                  <p>{tenure} </p>
                </CurrentChild>
                <CurrentChild>
                  <h4>Monthly Installment (₦)</h4>
                  <p>{monthly_installment} </p>
                </CurrentChild>
                {charges &&
                  charges.map((charge) => {
                    const { name, amount, charge_type } = charge;
                    let uppercaseName = name.toUpperCase();
                    return (
                      <CurrentChild key={charge.id}>
                        <h4>
                          CHARGE NAME -{uppercaseName}{" "}
                          {charge_type === "fixed" ? "(₦)" : "(%)"}
                        </h4>
                        <p>{amount}</p>
                      </CurrentChild>
                    );
                  })}
              </CurrentBox>
              <Loader
                style={{ display: loading ? "block" : "none" }}
                loading={loading}
              >
                <ReactLoading type={"spin"} color="#000" />
              </Loader>
            </Details>

            <Details
              className={toggle === 2 ? "content active-content" : "content"}
            >
              <TableBox>
                <Table>
                  <tr>
                    <th>#</th>
                    <th>DATE</th>
                    <th>MONTHLY REPAYMENT</th>
                    <th>INTEREST</th>
                    <th>PRINCIPAL</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                  {repayments &&
                    repayments.map((repayment, index) => {
                      const {repaymentDate, monthlyInstallment, interestRate, principal, status} = repayment
                      return (
                        <tr>
                          <td>{index +1}</td>
                          <td>{repaymentDate}</td>
                          <td>{monthlyInstallment}</td>
                          <td>{interestRate}</td>
                          <td>{principal}</td>
                          <td>{status}</td>
                          <td>
                            <Paid />
                          </td>
                        </tr>
                      );
                    })}
                 
                </Table>
              </TableBox>
            </Details>
          </Info>
        </Board>
      </Contents>
    </Container>
  );
};

export default LoanDetails;
