import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
// import styled, { css } from 'styled-components/macro'
import styled from "styled-components/macro";
import Pagination from "../../components/pagination/usePagination";

//svgs
import { ReactComponent as Srch } from "../../assets/icons/search.svg";
import ReactLoading from "react-loading";
import { ReactComponent as Prev } from "../../assets/icons/prev.svg";
import { ReactComponent as Next } from "../../assets/icons/next.svg";
import { ReactComponent as View } from "../../assets/icons/search.svg";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import { Pajination } from "../../components/pagination/Pajination";
import NumberFormat from "react-number-format";

const AdminCustomers = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState([]);
  const [loan, setLoan] = useState(0);
  const [loan_logic, setLoanLogic] = useState("greater");
  const [repaymentDue, setRepaymentDue] = useState(0);
  const [repaymentDue_logic, setRepaymentDueLogic] = useState("greater");
  const [repaymentMade, setRepaymentMade] = useState(0);
  const [repaymentMade_logic, setRepaymentMadeLogic] = useState("greater");

  const token = localStorage.getItem("admin-token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const retrieveCustomers = () => {
    axios
      .get(`admin/clients`, config)
      .then((response) => {
        console.log(response.data);
        setFormData(response.data.reverse());
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  useEffect(() => {}, [formData]);

  const option = [
    { value: "less", label: "Less than" },
    { value: "equal", label: "Equal to" },
    { value: "greater", label: "Greater than" },
  ];

  useEffect(() => {
    retrieveCustomers();
  }, []);

  //Handle Loan Changes
  const handleLoanChange = (e) => {
    setLoan(e.target.value );
  };
  const handleLoanLogicChange = (value) => {
    setLoanLogic({
      loan_logic: value.value,
    });
  };

  //Handle Repayment Due Changes
  const handleRepaymentDueChange = (e) => {
    setRepaymentDue(e.target.value);
  };
  const handleRepaymentDueLogicChange = (value) => {
    setRepaymentDueLogic({
      repaymentDue_logic: value.value,
    });
  };

  //Handle Repayment Made Changes
  const handleRepaymentMadeChange = (e) => {
    setRepaymentMade( e.target.value );
  };
  const handleRepaymentMadeLogicChange = (value) => {
    setRepaymentMadeLogic({
      repaymentMade_logic: value.value,
    });
  };

  //new pagination

  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(formData?.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, formData]);

  useEffect(() => {}, [currentItems]);

  return (
    <Container>
      <Contents>
        <Topbar />

        <Board>
          <HeadingSearch>
            <h2>Customers</h2>
          </HeadingSearch>

          <Info>
            {/* <Space> </Space> */}

            <Details>
              {/* <div>
                <Report>
                  <SelectBox>
                    <Box>
                      <Select
                        placeholder="Loan"
                        options={option}
                        onChange={handleLoanLogicChange}
                        name="loan_logic"
                      />
                      <Input
                        type="number"
                        value={loan}
                        name="loan"
                        onChange={handleLoanChange}
                      />
                    </Box>

                    <Box>
                      <Select
                        placeholder="Repayment Due"
                        options={option}
                        onChange={handleRepaymentDueLogicChange}
                        name="repaymentDue_logic"
                      />
                      <Input
                        type="number"
                        value={repaymentDue}
                        name="repaymentDue"
                        onChange={handleRepaymentDueChange}
                      />
                    </Box>
                    <Box>
                      <Select
                        placeholder="Repayment Made"
                        options={option}
                        onChange={handleRepaymentMadeLogicChange}
                        name="repaymentMade_logic"
                      />
                      <Input
                        type="number"
                        value={repaymentMade}
                        name="repaymentMade"
                        onChange={handleRepaymentMadeChange}
                      />
                    </Box>
                  </SelectBox>
                  <button>Generate Report</button>
                </Report>
              </div> */}

              <TableBox>
                <Table>
                  <tr>
                    <th>#</th>
                    <th>CUSTOMER</th>
                    <th>TOTAL LOAN (&#8358;)</th>
                    <th>TOTAL REPAYMENTS DUE(&#8358;)</th>
                    <th>TOTAL REPAYMENTS MADE(&#8358;)</th>
                    <th>ACTIONS</th>
                  </tr>

                  {currentItems &&
                    formData.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.id}</td>
                          <td>{data.customer_name}</td>
                          <td>
                            <NumberFormat
                              value={data.sumloan}
                              
                              decimalSeparator="."
                              thousandSeparator={true}
                              displayType="text"
                            />
                          </td>
                          <td> <NumberFormat
                              value={data.repaymentsdue}
                              
                              decimalSeparator="."
                              thousandSeparator={true}
                              displayType="text"
                            /></td>
                          <td><NumberFormat
                              value={data.repaymentspaid}
                             
                              decimalSeparator="."
                              thousandSeparator={true}
                              displayType="text"
                            /></td>
                          <td>
                            <Link to={`/admin-dashboard/customers/${data.id}`}>
                              <View />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  <Loader
                    style={{ display: loading ? "block" : "none" }}
                    loading={loading}
                  >
                    <ReactLoading type={"spin"} color="#000" />
                  </Loader>
                </Table>
              </TableBox>

              {formData.length > 15 ? (
                <Pajination
                  itemsPerPage={itemsPerPage}
                  items={formData}
                  setItemOffset={setItemOffset}
                />
              ) : null}
            </Details>
          </Info>
        </Board>
      </Contents>
    </Container>
  );
};

export default AdminCustomers;

//styles
const Container = styled.section`
  display: flex;
  width: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 200px;

  @media screen and (max-width: 600px) {
    padding-left: 0;
  }
`;

const Board = styled.div`
  background: #f0f0f7;
  padding: 1rem 2rem 2rem 5rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  @media screen and (max-width: 600px) {
    padding: 1rem 2rem 2rem 2rem;
  }
`;
const TextBox = styled.div`
  width: 15%;
  h3 {
    color: #616161;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const HeadingSearch = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }

  h2 {
    font-weight: 200;

    @media screen and (max-width: 920px) {
      margin-bottom: 15px;
    }
  }
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.2rem;
  width: 400px;
  background: #fff;
  button {
    width: 8%;
    border-radius: 50%;
    padding: 0.3rem 0.2rem;
    border: none;
    padding: none;
  }
  @media screen and (max-width: 920px) {
    width: 100%;
  }

  input {
    width: 87%;
    outline: none;
    border: none;
  }
`;

const Info = styled.div`
  display: flex;
  background: #fff;
  min-height: 70vh;
`;

const Space = styled.div`
  width: 150px;
  border-right: 1px solid #e9e9f0;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;

// const Select = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	margin-bottom: 10px;

// 	@media screen and (max-width: 1320px) {
// 		flex-direction: column;
// 	}

// 	h3 {
// 		font-weight: 400;
// 	}
// `;

const Report = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
 
  flex-wrap: wrap;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #3b86ff;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 0 0.8rem;
    cursor: pointer;
    font-size: 15px;
    height: 3rem;
	 @media only screen and (max-width: 1024px) and (min-width: 920px) {
	  width: 18%;
  }
  @media only screen and (max-width: 919px) {
    width: 95%;
	margin: 0 auto;
  }
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;
const SelectBox = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and (max-width: 1024px) and (min-width: 920px) {
	  width: 80%;
  }
  
  @media only screen and (max-width: 919px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  font-size: 15px;
  color: #808495;
  font-weight: 100;
  .css-b62m3t-container {
    width: 100%;
  }
@media only screen and (max-width: 1024px) and (min-width: 920px) {
	  padding: 10px 8px;
  }
  @media only screen and (max-width: 570px) and (min-width: 481px) {
	   padding: 10px 4px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  @media screen and (max-width: 930px) {
    margin-right: 0;
  }
`;
const Input = styled.input`
  display: block;
  width: 100%;
  height: 2rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 0.2rem 0.5rem;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus {
    outline: none;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100% !important;
  margin-top: 1rem;

  @media screen and (max-width: 1500px) {
    width: 1000px;
  }

  th {
    background: #e9e9f0;
    color: #808495;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    border: none;
    text-align: left;
  }

  td {
    padding: 8px;
    color: #43425d;

    font-size: 13px;
    border-bottom: 1px solid #e9e9f0;
  }
`;
const Loader = styled.div`
  /* display: ${({ loading }) => (loading ? "flex" : "none")}; */
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Pages = styled.div`
  display: flex;
  justify-content: end;

  &:last-child {
    margin-right: 0;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    color: #43425d;
    font-size: 14px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e9e9f0;
    margin-right: 5px;
    cursor: pointer;
  }
`;

const TableBox = styled.div`
  
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-overflow-style: none;
  @media screen and (max-width: 1240px) {
	  overflow-x: scroll;
  }
`;
