import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import check from "../../assets/check.svg";

const Success = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "auto" });
	}, []);

	return (
		<Container>
			<h2>Your Request was Successful!</h2>
			{/* <div>Your account will be credited within 24hrs.</div> */}
			<img src={check} alt="check" />
			<ButtonDiv>
				<DashBtn to="/dashboard">Go to Dashboard</DashBtn>		
			</ButtonDiv>
		</Container>
	);
};

export default Success;

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 5rem 1rem;

	h2 {
		color: #2a7b12;

		@media screen and (max-width: 300px) {
			font-size: 22px;
		}
	}

	div {
		margin: 15px 0 0 0;
		font-size: 15px;
	}

	img {
		height: 150px;
	}
`;

const ButtonDiv = styled.div`
width: 100%;
display: flex;
justify-content: center;
`
const DashBtn = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #0288d1;
	color: #fff;
	padding: 8px 20px;
	font-size: 15px;
	&:first-of-type {
		margin-right: 1rem;
	}
	&:last-of-type {
		margin-left: 1rem;
	}
`;
