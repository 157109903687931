import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components/macro";
import ReactLoading from "react-loading";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import Pagination from "../../components/pagination/usePagination";
import axios from "axios";
import { FetchAdmins } from "../../actions/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pajination } from "../pagination/Pajination";

const AdministratorsTable = ({ showEdit, loading }) => {
	const { admins } = useSelector((state) => state.admin);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(FetchAdmins());
		// dispatch(DeleteAdmins(id));
	}, [dispatch]);
	const onDelete = (id) => {
		axios
			.delete(`admin/delete/${id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then(() => {
				toast.success("Admin Deleted");
				dispatch(FetchAdmins());
			})
			.catch((error) => console.log(error));
	};
	//new pagination

	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 15;
	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;

		setCurrentItems(admins?.slice(itemOffset, endOffset));
	}, [itemOffset, itemsPerPage, admins]);

	const superAdmin = localStorage.getItem("super-admin-token");
	return (
		<>
			<Table>
				<tr>
					<th>#</th>
					<th>FULL NAME</th>
					<th>PHONE NO</th>
					<th>USERNAME</th>
					<th>RANK</th>
					<th>DATE CREATED</th>
					{superAdmin ? <th>ACTIONS</th> : null}
				</tr>

				{admins.map((data, index) => {
					let date = data.created_at?.split("T");
					return (
						<tr key={index}>
							<td>{index + 1}</td>
							<td>{data.fullname}</td>
							<td>{data.phone_number}</td>
							<td>{data.username}</td>
							<td>{data.is_senior ? "SUPER ADMIN" : "ADMIN"}</td>
							<td>{date ? date[0] : null}</td>
							{superAdmin ? (
								<td>
									<Link to={`/admin-dashboard/edit/${data.id}`}>
										<button onClick={showEdit} style={{ marginRight: "10px" }}>
											{" "}
											<Edit userid={data.id} />{" "}
										</button>{" "}
									</Link>
									<button onClick={() => onDelete(data.id)}>
										<Delete />{" "}
									</button>
								</td>
							) : null}
						</tr>
					);
				})}
				{admins.length > 10 ? (
					<Pajination
						itemsPerPage={itemsPerPage}
						items={admins}
						setItemOffset={setItemOffset}
					/>
				) : null}
				<Load style={{ display: loading ? "block" : "none" }} loading={loading}>
					<ReactLoading type={"spin"} color="#000" />
				</Load>
				<ToastContainer
					className="toast"
					position="top-right"
					autoClose="4000"
					hideProgressBar="false"
					closeOnClick
				></ToastContainer>
			</Table>
		</>
	);
};

export default AdministratorsTable;

//styles
const Table = styled.table`
	border-collapse: collapse;
	/* margin-bottom: 15px; */
	width: 100%;

	@media screen and (max-width: 1300px) {
		width: 900px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
		text-align: left;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: left;
		font-size: 13px;
		border-bottom: 1px solid #e9e9f0;

		button {
			outline: none;
			border: none;
			background: transparent;
			cursor: pointer;
		}
	}
`;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
