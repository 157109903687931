import { useState, useEffect } from "react";

//components
import Navbar from "../components/Navbar/Navbar";
import Banner from "../components/Banner/LoanBanner";
import Login from "../components/LoginRegister/Login";
import Footer from "../components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomerLogin = () => {
	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "auto" });
	}, []);
	useEffect(() => {
		const getToken = localStorage.getItem("toast");
		console.log(getToken);
		if (getToken) {
			setLoggedIn(true);
		}
	}, [loggedIn]);

	useEffect(() => {
		console.log(loggedIn);
		if (loggedIn) {
			toast.error("You're not logged in, please do.");
		}
		return () => {
			setTimeout(() => {
				localStorage.removeItem("toast");
			}, 2000);
		};
	}, [loggedIn]);
	return (
		<>
			<Navbar />
			<Banner />
			<Login />
			<Footer />
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</>
	);
};

export default CustomerLogin;
