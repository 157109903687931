import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import styled, { css } from "styled-components/macro";
import dp from "../../assets/avatar.png";
import { ReactComponent as Paid } from "../../assets/icons/paid.svg";
import { ReactComponent as Pending } from "../../assets/icons/unpaid.svg";

//components
import Topbar from "../../components/DashboardTopbars/Client";
import NumberFormat from "react-number-format";

// import { FetchBioDaata } from "../../actions/user";
// import { UniqueLoan } from "..";

const UniqueCustomer = () => {
  const [toggle, setToggle] = useState(1);
  const [loandetails, setloandetails] = useState([]);
  const [editing, setediting] = useState(false);
  const [repayment, setrepayment] = useState(null);
  const changeTab = (index) => {
    setToggle(index);
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const token = localStorage.getItem("user-token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const charges = useSelector((state) => state.charges.list);
  const { customer_id } = JSON.parse(localStorage.getItem("user-info"));
  const user = JSON.parse(localStorage.getItem("user-info"));
  console.log(user.id);
  console.log(customer_id);
  const getSingleLoan = async () => {
    axios
      .get(`/user/loan/${user.id}/${id}`, config)
      .then((response) => {
        // console.log(response.data);
        console.log(response.data.loanHistory);
        setloandetails(response.data?.loanHistory);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRepayment = async () => {
    const token = localStorage.getItem("user-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios
      .get(`/user/loan-repayment/${id}/${user?.id}`, config)
      .then((response) => {
        console.log(response);
        setrepayment(response.data.LoanRepayments);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    // dispatch(FetchBioDaata(id));
    getSingleLoan();
    getRepayment();
  }, []);
  const disableRepayment = async () => {
    const token = localStorage.getItem("user-token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await axios
      .post(`user/stop-repayment/${id}/${user?.id}`, config)
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };
  return (
    <Container>
      <Contents>
        <Topbar />

        <Board className="info">
          <h2>Customers</h2>

          {user && (
            <Info>
              <DpNo>
                <div>
                  <h3>{user.customer_name}</h3>
                  <h5>Mobile :{user.phone_number}</h5>
                </div>
              </DpNo>

              <Infos>
                <Btns>
                  <Space>
                    <Back to="/dashboard/history">Back</Back>
                  </Space>
                  <Button
                    className={toggle === 1 ? "btn active-btn" : "btn"}
                    onClick={() => changeTab(1)}
                  >
                    Loan Details
                  </Button>
                  <Button
                    className={toggle === 2 ? "btn active-btn" : "btn"}
                    onClick={() => changeTab(2)}
                  >
                    Repayments
                  </Button>
                </Btns>
                {loandetails &&
                  loandetails.map((loan) => {
                    const { requested_amount, tenure } = loan;
                    return (
                      <Details
                        key={loan.id}
                        className={
                          toggle === 1 ? "content active-content" : "content"
                        }
                      >
                        <CurrentBox>
                          <CurrentChild>
                            <h4>LOAN AMOUNT</h4>
                            <p>
                              <NumberFormat
                                value={requested_amount}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
                            </p>
                          </CurrentChild>

                          <CurrentChild>
                            <h4>TENURE</h4>
                            <p> {tenure}</p>
                          </CurrentChild>
                          <CurrentChild>
                            <h4>NET DISBURSEMENT</h4>
                            <p>
                              <NumberFormat
                                value={requested_amount}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
                            </p>
                          </CurrentChild>
                          {charges &&
                            charges.map((charge) => {
                              const { name, amount, charge_type } = charge;
                              let uppercaseName = name.toUpperCase();
                              return (
                                <CurrentChild key={charge.id}>
                                  <h4>
                          CHARGE NAME -{uppercaseName}{" "}
                          {charge_type === "fixed" ? "(₦)" : "(%)"}
                        </h4>
                                  <p>
                                    <NumberFormat
                                      value={amount}
                                      
                                      decimalSeparator="."
                                      thousandSeparator={true}
                                      displayType="text"
                                    />
                                  </p>
                                </CurrentChild>
                              );
                            })}
                        </CurrentBox>
                      </Details>
                    );
                  })}

                <Details
                  className={
                    toggle === 2 ? "content active-content" : "content"
                  }
                >
               <TableBox>
                    <Table>
                    <tr>
                      <th>#</th>
                      <th>DATE</th>
                      <th>MONTHLY REPAYMENT</th>
                      <th>INTEREST</th>
                      <th>PRINCIPAL</th>
                      <th>STATUS</th>
                      <th>ACTION</th>
                    </tr>
                    {repayment &&
                      repayment.map((payment, index) => {
                        const {
                          interestRate,
                          id,
                          monthlyInstallment,
                          principal,
                          repaymentDate,
                          status,
                        } = payment;
                        return (
                          <tr key={payment.id}>
                            <td>{index + 1}</td>
                            <td>{repaymentDate}</td>
                            <td><NumberFormat
                                value={monthlyInstallment}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></td>
                            <td><NumberFormat
                                value={interestRate}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></td>
                            <td><NumberFormat
                                value={principal}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></td>
                            <td>{status}</td>
                            <td>
                              {status !== "paid" ? <Pending /> : <Paid />}
                            </td>
                          </tr>
                        );
                      })}
                  </Table>
               </TableBox>
                  <DisEna>
                    <button
                      style={{
                        background: "#43425D",
                        borderBottomLeftRadius: "3px",
                      }}
                      onClick={disableRepayment}
                    >
                      DISABLE
                    </button>
                    <button
                      style={{
                        background: "#3B86FF",
                        borderBottomRightRadius: "3px",
                      }}
                    >
                      ENABLE
                    </button>
                  </DisEna>
                </Details>
              </Infos>
            </Info>
          )}
        </Board>
      </Contents>
    </Container>
  );
};

export default UniqueCustomer;

//styles
const Container = styled.section`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 200px;

  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Board = styled.div`
  background: #f0f0f7;
  height: 100%;
  padding: 1rem 2rem 2rem 5rem;
  overflow-x: hidden;
  overflow-y: scroll;

  @media screen and (max-width: 600px) {
    padding: 2rem;
  }

  h2 {
    margin-bottom: 10px;
    font-weight: 200;
  }
`;
const Space = styled.div`
  padding: 0.5rem;
  width: 150px;
  border-right: 1px solid #e9e9f0;

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

const Back = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #3b86ff;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  height: 35px;
  border-radius: 3px;
  width: 100%;

  @media screen and (max-width: 750px) {
    /* font-size: 11px; */
  }
`;

const DpNo = styled.div`
  display: flex;
  padding: 15px;
  position: relative;

  @media screen and (max-width: 770px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      color: #4d4f5c;
      margin-bottom: 15px;

      @media screen and (max-width: 400px) {
        font-size: 15px;
      }

      @media screen and (max-width: 770px) {
        margin-top: 15px;
      }
    }

    h5 {
      color: #707070;
      font-weight: 200;
      font-size: 15px;
    }
  }
`;
const CurrentBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem auto;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const CurrentChild = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  h4 {
    font-weight: 550;
    color: #616161;
  }
  p {
    font-weight: 500;
    color: #757575;
    margin-top: 0.2rem;
  }
  @media only screen and (max-width: 480px) {
    h4 {
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
`;

const Dp = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 150px;

  @media screen and (max-width: 860px) {
    margin-right: 50px;
  }

  @media screen and (max-width: 480px) {
    margin-right: 10px;
  }

  @media screen and (max-width: 400px) {
    width: 60px;
    height: 60px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const Info = styled.div`
  background: #fff;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
`;
const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
  margin-top: 10px;

  @media screen and (max-width: 1090px) {
    width: 800px;
  }

  th {
    background: #e9e9f0;
    color: #808495;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    border: none;
  }

  td {
    padding: 8px;
    color: #43425d;
    text-align: center;
    font-size: 13px;
    color: #208518;
    border-bottom: 1px solid #e9e9f0;
  }
`;
const Btns = styled.div`
  display: grid;
  grid-template-columns: 150px 150px 200px 150px 150px;
  border: 1px solid #e9e9f0;
  border-right: none;
  border-left: none;
  color: #43425d;
  padding: 0 15px;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0;
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  outline: none;
  border-left: 1px solid #e9e9f0;
  border-right: 1px solid #e9e9f0;
  border-top: none;
  border-bottom: none;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  @media screen and (max-width: 860px) {
    margin-right: 0;
  }
`;

const Details = styled.div``;

const Group = styled.div`
  display: flex;
  padding: 25px 0 10px 15px;
  border-bottom: 1px solid #e9e9f0;

  @media screen and (max-width: 860px) {
    padding: 40px 15px 15px 15px;
  }

  @media screen and (max-width: 555px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 400px;

    h3 {
      font-size: 17px;
      font-weight: 400;
      color: #4d4f5c;
      margin-bottom: 10px;
    }

    h5 {
      color: #43425d;
      font-size: 14px;
      font-weight: 200;
    }
  }
`;

const DisEna = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;

  button {
    color: #fff;
    padding: 10px 20px;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

const TableBox = styled.div`
width: 100%;
overflow-x: scroll;
/* width */
&::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
&::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`