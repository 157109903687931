import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import bg from "../assets/adminbg.png";
import logo from "../assets/logo.png";
import axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

const Bg = styled.div`
	background-image: url(${bg});
	background-size: 100% 100%;
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 500px;
	}
`;

const Group = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;
	padding: 4rem 3rem 10px 3rem;
	position: relative;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		padding: 4rem 1rem 10px 1rem;
	}

	h2 {
		font-weight: 700;
		font-size: 22px;
		margin-bottom: 10px;
		letter-spacing: 5px;
	}

	p {
		text-align: center;
		font-size: 13px;
		margin-bottom: 30px;
		color: #707070;
	}
`;
const HomeBox = styled.div`
	width: 100% !important;
	text-align: end;
	position: absolute;
	top: 1%;
	right: 1%;
	a {
		color: #43425d;
		font-size: 600 !important;
	}
`;
const Info = styled.section`
	text-align: center;
	width: 70%;
	@media screen and (max-width: 480px) {
		width: 90%;
	}
`;

const Form = styled.section`
	width: 70%;
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	input {
		border: none;
		outline: none;
		border-bottom: 1px solid #ccc;
		padding: 8px 20px;
		width: 100%;
		font-size: 15px;
		margin-bottom: 15px;
	}

	@media screen and (max-width: 768px) {
		width: 80%;
		margin-bottom: 50px;
	}
	@media screen and (max-width: 480px) {
		width: 90%;
		margin-bottom: 50px;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.8rem 1rem;
	color: #fff;
	outline: none;
	border: none;
	background: #43425d;
	width: 150px;
	font-size: 13px;
	border-radius: 3px;
	cursor: pointer;
`;

const Powered = styled.section`
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 10px;
	left: 10px;
	right: 10px;

	p {
		margin: 0;
		color: #43425d;
		font-size: 12px;
	}
`;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const ConfirmEmail = () => {
	let history = useHistory();

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		axios
			.post("user/verifyemail", { email })
			.then((response) => {
				console.log(response);

				// if (response.data.error) {
				//     toast.error("Email or Password Incorrect")
				//     setLoading(false)
				// } else if (response.data.token) {
				//     history.push('/dashboard')
				// }
			})
			.catch((error) => {
				console.log(error);
				if (error) {
					setLoading(false);
					toast.error("Email does not exist");
				}
			});
	};
	return (
		<Container>
			<Bg />
			<Group>
				<HomeBox>
					<Link to="/">Back to Homepage</Link>
				</HomeBox>
				<div style={{ width: "80%" }}>
					<Info>
						{/* <h2>Kephren</h2> */}
						<Link to="/">
							<img
								src={logo}
								alt="logo"
								style={{ width: "150px", marginBottom: "10px" }}
							/>
						</Link>
						<p>Enter the email you registered with.</p>
					</Info>
					<Form>
						<form onSubmit={onSubmit}>
							<input
								type="email"
								placeholder="Email"
								style={{ marginBottom: "30px" }}
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>

							<Button type="submit">Send Request</Button>
						</form>
						<Load loading={loading}>
							<ReactLoading type={"spin"} color="#4D4F5C" />
						</Load>
					</Form>
				</div>
				<Powered>
					<p>Powered by Petrong Software Solutions</p>
					<p>Pacioli</p>
				</Powered>
			</Group>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default ConfirmEmail;
