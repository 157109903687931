import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//components
import DashboardTopbar from "../../components/DashboardTopbars/Client";
import axios from "axios";
const CustomerSettings = () => {
	const [click, setClick] = useState(true);
	const user = JSON.parse(localStorage.getItem("user-info"));
	const initalValues = {
		name: user.customer_name,
		email: user.email,
		old_password: "",
		password: "",
		password_confirmation: "",
	};
	const [profileInfo, setprofileInfo] = useState(initalValues);

	const handleChange = (e) => {
		setprofileInfo({ ...profileInfo, [e.target.name]: e.target.value });
	};
	const history = useHistory();
	useEffect(() => {
		if (!localStorage.getItem("user-token")) {
			history.push("./login");
		}
	}, []);
	const token = localStorage.getItem("user-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		// console.log(profileInfo);
		setClick(false);
		if (profileInfo.password === profileInfo.password_confirmation) {
			console.log(profileInfo);
			axios
				.put(`/user/update`, profileInfo, config)
				.then((response) => {
					console.log(response);

					if (response.data.status) {
						toast.success("Update successful");
					} else if (!response.data.status) {
						toast.error("Error, missing fields");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			toast.error("Passwords are different, please review");
			console.log("Passwords are different");
		}
	};
	return (
		<Container>
			<Contents>
				<DashboardTopbar />

				<Board>
					<h2>Settings</h2>

					<Info>
						<Details>
							<h3>Update Profile</h3>

							<Groups onSubmit={handleSubmit}>
								<input
									type="text"
									defaultValue={profileInfo.name}
									name="username"
									placeholder="EMAIL"
									// value={profileInfo.username}
									onChange={handleChange}
								/>
								<input
									type="email"
									defaultValue={profileInfo.email}
									placeholder="NAME"
									name="fullname"
									// value={profileInfo.fullname}
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="OLD PASSWORD"
									value={profileInfo.old_password}
									name="old_password"
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="NEW PASSWORD"
									value={profileInfo.password}
									name="password"
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="CONFIRM NEW PASSWORD"
									value={profileInfo.password_confirmation}
									name="password_confirmation"
									onChange={handleChange}
								/>
								<Button type="submit" className={click ? null : "inactive"}>
									Update
								</Button>
							</Groups>
						</Details>
					</Info>
				</Board>
			</Contents>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default CustomerSettings;

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;
const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 10px;
	background: #fff;
	position: relative;

	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}

	div {
		display: flex;
	}
`;
const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	width: 150px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	outline: none;
	border: none;
	color: #fff;

	@media screen and (max-width: 350px) {
		font-size: 12px;
		padding: 8px;
	}
`;
const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 15rem 20px 2rem;
	overflow: hidden;
	width: 100%;

	@media screen and (max-width: 1100px) {
		padding: 20px 2rem;
	}

	@media screen and (max-width: 950px) {
		width: 100%;
	}

	@media screen and (max-width: 350px) {
		padding: 20px 1rem;
	}

	h3 {
		font-weight: 400;
	}
`;

const Groups = styled.form`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 4rem 8rem;
	margin-top: 3rem;
	width: 100%;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 900px) {
		padding: 1rem 1rem 4rem 0;
	}

	@media screen and (max-width: 360px) {
		padding: 0 0 4rem 0;
	}

	input {
		border: 1px solid #e8e9ec;
		outline: none;
		padding: 8px 20px;
		color: #000;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.inactive {
		background: #89c2d9;
		cursor: not-allowed;
	}
`;
const Board = styled.div`
	background: #f0f0f7;
	height: calc(100vh - 50px);
	padding: 2rem 2rem 2rem 5rem;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 10px;
		font-weight: 200;
	}
`;
