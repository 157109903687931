import * as api from '../api/charges'
import * as actionType from '../constant/actionType';

export const FetchCharges = () => async (dispatch) => {
    try {
        const {data} = await api.getCharges()
        dispatch({ 
          type: actionType.FETCHCHARGES, 
          payload: data,
        });
      } catch (error) {
        console.log('error');
      }
    };

// export const fetchCharges = () => dispatch => {
//     api.postMessage().fetchAll()
//         .then(res => {
//             console.log(res)
//             dispatch({
//                 type: actionType.FETCHCHARGES,
//                 payload: res.data.users
//                 })
//     })
//     .catch(err => console.log(err))
// }

// export const create = (datea, onSuccess) => dispatch => {
//     api.postMessage().create(datea)
//         .then(res => {
//             dispatch({
//                 type: ACTION_TYPES.CREATE,
//                 payload: res.datea
//                 })
//             onSuccess()
//         })
// }

// export const update = (id, data, onSuccess) => dispatch => {
//     api.postMessage().put(id, data)
//         .then(res => {
//             dispatch({
//                 type: ACTION_TYPES.UPDATE,
//                 payload: res.data.users
//                 })
//             onSuccess()
//         })
// }

// export const Delete = (id, onSuccess) => dispatch => {
//     api.postMessage().delete(id)
//         .then(res => {
//             dispatch({
//                 type: ACTION_TYPES.DELETE,
//                 payload: id
//                 })
//             onSuccess()
//         })
// }