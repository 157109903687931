import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//components
import Topbar from "../DashboardTopbars/Admin";
import ChargesTable from "../SuperAdminTables/ChargesTable";
import { Pajination } from "../pagination/Pajination";



const ShowAdminCharges = () => {
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState([]);
	const token = localStorage.getItem("super-admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const retrieveAdminCharges = () => {
		axios
			.get(`admin/admin-charge`, config)
			.then((response) => {
				setFormData(response.data);
				setLoading(false);
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	};

	useEffect(() => {
		retrieveAdminCharges();
		console.log(formData);
	}, []);

	const onDelete = (id) => {
		axios.delete(`admin/admin-charge/${id}`, config).then(() => {
			retrieveAdminCharges();
		});
	};

	const history = useHistory();
	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("/admin");
		} else if (!localStorage.getItem("super-admin-token")) {
			history.push("/admin-dashboard/login");
		}
	}, []);

	const logOut = () => {
		localStorage.removeItem("super-admin-token");
		history.push("/admin-dashboard/login");
	};

	//new pagination

	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 15;
	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;
		setCurrentItems(formData?.slice(itemOffset, endOffset));
	}, [itemOffset, itemsPerPage, formData]);
	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Super Admin</h2>

					<Info className="info">
						<Exit onClick={logOut}>Exit Super Admin</Exit>
						<Btns>
							{/* <Button to="/admin-dashboard/edit">Edit Login</Button> */}
							<Button to="/admin-dashboard/show">Administrators</Button>
							<Button to="/admin-dashboard/log">Change Log</Button>
							<Button className="btn active-btn">Charges</Button>
						</Btns>

						<div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
							<Space>
								<New to="/admin-charges/create">Create</New>
							</Space>

							<Table>
								<h3>Charges</h3>
								<ChargesTable
									formData={currentItems}
									onDelete={onDelete}
									loading={loading}
								/>
							</Table>
						</div>
						{formData?.length > 10 ? (
							<Pajination
								itemsPerPage={itemsPerPage}
								items={formData}
								setItemOffset={setItemOffset}
							/>
						) : null}
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default ShowAdminCharges;

const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	padding: 1rem 0;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: calc(100vh - 60px);
	padding: 2rem 2rem 2rem 5rem;
	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 200;
		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	min-height: 70vh;
	padding: 10px;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
	div {
		display: flex;
		/* @media only screen and (max-width: 480px) {
			justify-content: center;
		} */
	}
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		padding: 0;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	background-color: #eeeeee;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		width: 48%;
		margin-bottom: 0.2rem;
	}
	&:first-of-type {
		@media only screen and (max-width: 480px) {
			margin-right: 0.2rem;
		}
	}
	&:last-of-type {
		@media only screen and (max-width: 480px) {
			margin-left: 0.2rem;
		}
	}
`;
const Space = styled.div`
	padding: 0.5rem;
	width: 15%;
	border-right: 1px solid #e9e9f0;
	@media screen and (max-width: 750px) {
		display: none;
	}
	@media only screen and (max-width: 768px) {
		width: 22%;
	}
	@media only screen and (max-width: 480px) {
		width: 60%;
		border-right: none;
		margin-top: 1rem;
	}
`;

const New = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: #3b86ff;
	font-size: 13px;
	color: #fff;
	cursor: pointer;
	height: 35px;
	border-radius: 3px;
	width: 100%;
	@media screen and (max-width: 750px) {
		/* font-size: 11px; */
	}
`;

const Table = styled.div`
	flex-direction: column;
	width: 84%;
	padding: 1rem;
	h3 {
		font-weight: 200;
		margin-bottom: 1rem;
	}
	/* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-overflow-style: none;
	@media screen and (max-width: 1300px) {
		overflow-x: scroll;
		width: 100%;
	}
	@media only screen and (max-width: 768px) {
		width: 75%;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
