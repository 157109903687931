import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ActionDiv,
  ButtonBox,
  Container,
  Content,
  ContentChild,
  Heading,
} from "./Details.style";
import { useHistory } from "react-router-dom";
// import { ConfirmLoan } from "../../actions/takeLoan";
import { ToWords } from "to-words";
import NumberFormat from "react-number-format";
import moment from "moment";
import ReactLoading from "react-loading";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Details = () => {
  const [click, setClick] = useState(true);
  const [loading, setLoading] = useState(false);
  const [check, setcheck] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user-loan"));

  const loanDetails = JSON.parse(localStorage.getItem("customerLoan"));
  const amount = localStorage.getItem("amount");
  const history = useHistory();
  const toWords = new ToWords({
    localeCode: "en-NG",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });
  let words = toWords?.convert((0 || amount), {
    currency: true,
    ignoreDecimal: true,
  });
  // console.log(words);

  const handleClick = () => {
    setLoading(true);
    setClick(false);
    axios
      .post(`/user/confirm-loan`, {
        date_issued: loanDetails?.date_issued,
        interest_rate: loanDetails?.interest_rate,
        loan_type: loanDetails?.loan_type,
        monthly_installment: loanDetails?.monthly_installment,
        net_disburs: loanDetails?.net_disburs,
        net_pay: loanDetails?.net_pay,
        requested_amount: loanDetails?.requested_amount,
        tenure: loanDetails?.tenure,
        total_collection_amount: loanDetails?.total_collection_amount,
        user_id: loanDetails?.user_id,
      })
      .then((response) => {
        console.log(response);
        
        localStorage.removeItem("Init");
        setLoading(false);
        history.push("/success");
		localStorage.removeItem("amount");
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        toast.error("Error, try later");
        setClick(true);
        // localStorage.removeItem("Init");
      });
  };

  const handleCheck = (e) => {
    setcheck(e.target.checked);
    // console.log(check);
  };
  useEffect(() => {}, [check, click]);

  const momentDate = moment().format("MMM Do YY").split(" ");
  // console.log(momentDate[1])
  return (
    <Container>
      <Content>
        <Heading>Read through Loan summary</Heading>
        <ContentChild>
          <p>Name: {userInfo.customer_name}</p>
          <p>Company: {userInfo.company_name}</p>
          <p>Account No: {userInfo.account_number}</p>

          <p>
            Approved Loan Amount: {""}
            <NumberFormat
              value={amount}
              prefix="&#8358;"
              decimalSeparator="."
              thousandSeparator={true}
              displayType="text"
            />
          </p>
          <p>Amount in words: {words} </p>
          <p>Loan tenure: {loanDetails.tenure} months</p>
          <p>Repayment interval: {momentDate[1]} day of every month</p>
          <p>
            Interest: {""}
            <NumberFormat
              value={loanDetails.requested_amount - loanDetails.net_disburs}
              prefix="&#8358;"
              decimalSeparator="."
              thousandSeparator={true}
              displayType="text"
            />
          </p>
          <p>
            Net Disbursement: {""}
            <NumberFormat
              value={loanDetails.net_disburs}
              prefix="&#8358;"
              decimalSeparator="."
              thousandSeparator={true}
              displayType="text"
            />
          </p>
          <p>
            Monthly Installment: {""}
            <NumberFormat
              value={loanDetails.monthly_installment}
              prefix="&#8358;"
              decimalSeparator="."
              thousandSeparator={true}
              displayType="text"
            />
          </p>
          <p>
            Total Repayment: {""}
            <NumberFormat
              value={loanDetails.monthly_installment * loanDetails.tenure}
              prefix="&#8358;"
              decimalSeparator="."
              thousandSeparator={true}
              displayType="text"
            />
          </p>
        </ContentChild>
        <ActionDiv>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              name="agree"
              id="agree"
              checked={check}
              onChange={handleCheck}
            />
            {!check ? (
              <p style={{ marginLeft: "0.2rem" }}>
                I've confirmed that the above information is correct.{" "}
              </p>
            ) : (
              <span style={{ color: "red" }}>
                You can't go back after clicking 'Confirm'
              </span>
            )}
          </div>
        </ActionDiv>
        <ActionDiv>
          <ButtonBox style={{ justifyContent: "flex-start" }}>
            <button onClick={history.goBack}>GO BACK</button>
          </ButtonBox>
          <ButtonBox onClick={handleClick}>
            <button
              disabled={!check ? true : false}
              className={click ? (!check ? "disable" : null) : "inactive"}
            >
              CONFIRM
            </button>
          </ButtonBox>
        </ActionDiv>
        <Load loading={loading}>
          <ReactLoading type={"spin"} color="#000" />
        </Load>
      </Content>
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose="4000"
        hideProgressBar="false"
        closeOnClick
      ></ToastContainer>
    </Container>
  );
};

const Load = styled.div`
  display: ${({ loading }) => (loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
