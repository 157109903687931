import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import NumberFormat from "react-number-format";

//components
import Topbar from "../../components/DashboardTopbars/Admin";

//chart images
import requested from "../../assets/requested.png";
import repaid from "../../assets/repaid.png";
import outstanding from "../../assets/outstanding.png";

import { FetchLoanStat } from "../../actions/admin";

//svgs
import { ReactComponent as Arr } from "../../assets/icons/red-arrow.svg";

const AdminDashboard = () => {
	const dispatch = useDispatch();

	const adminData = useSelector((state) => state.admin.loanStat[0]);

	useEffect(() => {
		dispatch(FetchLoanStat());
	}, []);

	const history = useHistory();
	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("/admin");
		}
	}, []);

	return (
		// <h4>hello</h4>
		<Container className="dashboard">
			<Contents>
				<Topbar />

				<Board>
					<h2>Admin Dashboard</h2>

					<Info>
						<div>
							<Link to="/admin-dashboard/customers">
								<h5>Total no of customers</h5>

								<PrCh>
									<h3>{adminData ? adminData?.totalusers : "0"}</h3>

									<Ch>
										<img src={requested} alt="chart" />
									</Ch>
								</PrCh>
							</Link>
						</div>

						<div>
							<Link to="/admin-dashboard/repayments">
								{" "}
								<h5>Total Amount of Repayment Due</h5>
								<PrCh>
									<h3>{adminData ?(<NumberFormat
                                value={adminData?.repayments_due}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />)  : 0}</h3>

									<Ch>
										<img src={repaid} alt="chart" />
									</Ch>
								</PrCh>
							</Link>
						</div>
						<div>
							<Link to="/admin-dashboard/repayments">
								<h5>Total Amount of repayment made</h5>

								<PrCh>
									<h3>{adminData ? (<NumberFormat
                                value={adminData?.repayments_paid}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />) : 0}</h3>

									<Ch>
										<img src={repaid} alt="chart" />
									</Ch>
								</PrCh>
							</Link>
						</div>

						<div>
							<Link to="/admin-dashboard/disbursements">
								<h5>Total Amount of loan disbursed</h5>

								<PrCh>
									<div>
										<h3>
											<NumberFormat
                                value={adminData?.totalamountdisbursed}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
											</h3>
										{/* <h5>
											<Arr style={{ marginRight: "5px" }} />
											13.8%
										</h5> */}
									</div>

									<Ch>
										<img src={outstanding} alt="chart" />
									</Ch>
								</PrCh>
							</Link>
						</div>
						<div>
							<Link to="/admin-dashboard/customers/current">
								<h5>Total Amount of customers who have taken loan</h5>

								<PrCh>
									<div>
										<h3>{adminData ? adminData?.loanUsersCount : 0}</h3>
										{/* <h5>
										<Arr style={{ marginRight: "5px" }} />
										13.8%
									</h5> */}
									</div>

									{/* <Ch>
									<img src={outstanding} alt="chart" />
								</Ch> */}
								</PrCh>
							</Link>
						</div>
						<div>
							<Link to="/admin-dashboard/show">
								<h5>Total Amount of admins</h5>

								<PrCh>
									<div>
										<h3>{adminData ? adminData?.admins : 0}</h3>
										{/* <h5>
										<Arr style={{ marginRight: "5px" }} />
										13.8%
									</h5> */}
									</div>

									{/* <Ch>
									<img src={outstanding} alt="chart" />
								</Ch> */}
								</PrCh>
							</Link>
						</div>
					</Info>

					<Btns>
						<Button
							to="/admin-dashboard/customers"
							style={{ background: "#43425D" }}
						>
							View Customers
						</Button>
						<Button
							to="/admin-dashboard/create"
							style={{ background: "#3B86FF" }}
						>
							Create Admin
						</Button>
					</Btns>
				</Board>
			</Contents>
		</Container>
	);
};

export default AdminDashboard;

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 2rem 2rem 2rem 5rem;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 200;

		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-bottom: 40px;

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}

	div {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		background: #fff;

		h5 {
			font-size: 14px;
			font-weight: 300;
			color: #ccc;
			height: 50px;
		}
	}
`;

const PrCh = styled.section`
	display: flex;
	justify-content: space-between;
	height: 40px;

	h3 {
		color: #43425d;
	}

	div {
		padding: 0;

		h5 {
			line-height: 15px;
			color: #ff4141;
			font-weight: bold;
			margin: 5px 0 0 0;
		}
	}
`;

const btnstyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 15px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 12px;
	color: #fff;
	background: transparent;
	margin-right: 15px;
	font-size: 15px;

	@media screen and (max-width: 630px) {
		margin-bottom: 15px;
		margin-right: 0;
	}
	@media screen and (max-width: 600px) {
		margin-bottom: 0;
		margin-right: 15px;
	}
	@media screen and (max-width: 380px) {
		margin-bottom: 15px;
		margin-right: 0;
	}
`;

const Btns = styled.div`
	display: flex;

	@media screen and (max-width: 630px) {
		flex-direction: column;
	}
	@media screen and (max-width: 600px) {
		flex-direction: row;
	}
	@media screen and (max-width: 380px) {
		flex-direction: column;
	}
`;

const Button = styled(Link)`
	${btnstyle};
`;

const Ch = styled.section`
	display: flex;
	width: 100px;
	height: 100%;

	img {
		height: 100%;
		width: 100%;
	}
`;
