import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
`;

export const ContainerChild = styled.div`
  width: 85%;
  margin: 1rem auto;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media only screen and (max-width: 940px) {
    overflow-x: scroll;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const Table = styled.table`
	width: 100%;
`;
export const HeadRow = styled.thead`
	width: 95%;
	margin: 0 auto;
	padding: 0.7rem 0;
	border-bottom: 1px solid #f5f5f5;
	display: flex;
	justify-content: space-between;
`;

export const Row = styled.tr`
	width: 95%;
	margin: 0 auto;
	padding: 0.7rem 0;
	border-bottom: 1px solid #f5f5f5;
	display: flex;
	justify-content: space-between;
`;

export const Head = styled.th`
  font-weight: 600;
  color: gray;
text-align: start;

  &:nth-of-type(2) {
    margin-left: -5rem;
  }
 
`;

export const Body = styled.td`
  color: gray;
  font-weight: 400;
  text-align: center;
  &:last-of-type {
    min-width: 25%;
  }
`;
export const Loader = styled.div`
  /* display: ${({ loading }) => (loading ? "flex" : "none")}; */
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
