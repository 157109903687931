import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
// import styled, { css } from 'styled-components/macro'
import styled from "styled-components/macro";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//svgs

import ReactLoading from "react-loading";
// import { ReactComponent as Arr } from "../../assets/icons/downarrowbig.svg";
import { ReactComponent as View } from "../../assets/icons/search.svg";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
// import CustomersTableAdmin from "../../components/CustomersTableAdmin/CustomersTableAdmin";
import { Pajination } from "../../components/pagination/Pajination";
import NumberFormat from "react-number-format";

const CurrentCustomers = () => {
  // const history = useHistory()
  // useEffect(() => {
  //    if (!localStorage.getItem('admin-token')) {
  //     history.push('/admin')
  //    }
  // }, [])

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState([]);

  const [yearArray, setyearArray] = useState([]);
  const [reportInfo, setreportInfo] = useState({
    month: "0",
    year: "0",
  });

  const token = localStorage.getItem("admin-token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const retrieveLoans = () => {
    axios
      .get(`admin/loans`, config)
      .then((response) => {
        console.log(response.data.client);
        setFormData(response.data.client);
        setLoading(false);
        let days = [];
        let dates = [];
        let months = [];
        for (let i = 0; i < response.data.client.length; i++) {
          let day = response.data.client[i]?.company_name;
          let date = response.data.client[i].created_at;
          let year = new Date(date).getFullYear();
          let month = new Date(date).getMonth();
          let newMonth = month + 1;

          months.push(newMonth);
          if (!days.includes(day)) {
            days.push(day);
          }
          if (!dates.includes(year)) {
            dates.push(year);
          }
        }
        // console.log(formData);
       
        setyearArray(dates);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
		toast.error("Error retrieving data")
      });
  };
  // console.log(dayArray);
  useEffect(() => {}, [formData]);
  useEffect(() => {}, [yearArray]);
  const userInfo = JSON.parse(localStorage.getItem("admin"));
  let user_id = userInfo?.id;
  const config2 = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "arraybuffer",
  };
  const generateReport = (e) => {
    e.preventDefault();
    console.log(reportInfo);
  
    axios
      .get(
        `/user/generate-report/${user_id}/${reportInfo?.year}/${reportInfo?.month}`,
        config2
      )
      .then((response) => {
        console.log(response);

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        console.log(url);
        link.href = url;
        link.setAttribute("download", "loan-report"); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log({ error });
        toast.error("Error Generating Report");
      });
  };

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const yearOptions = yearArray.map((year, index) => {
    return {
      label: year,
      value: year,
      key: index,
    };
  });
  useEffect(() => {
    retrieveLoans();
  }, []);

  const handleMonthChange = (value) => {
    setreportInfo({
      ...reportInfo,
      month: value.value,
    });
  };
  const handleYearChange = (value) => {
    setreportInfo({
      ...reportInfo,
      year: value.value,
    });
  };

  //new pagination

  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(formData?.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, formData]);

  useEffect(() => {}, [currentItems]);

  return (
    <Container>
      <Contents>
        <Topbar />

        <Board>
          <HeadingSearch>
						<h2>Loan Disbursements</h2>

					
					</HeadingSearch>

          <Info>
            {/* <Space> </Space> */}

            <Details>
              {/* <div>
                <Report>
                 
                  <SelectBox>
                    <Box>
                      <Select
                        placeholder="Year"
                        options={yearOptions}
                        onChange={handleYearChange}
                        name="year"
                      />
                      
                    </Box>
                    <Box>
                      <Select
                        placeholder="Month"
                        options={monthOptions}
                        onChange={handleMonthChange}
                        name="month"
                      />
                      
                    </Box>
                   
                  </SelectBox>
                  <button onClick={generateReport}>Generate Report</button>
                </Report>
              </div> */}

              <TableBox>
                <Table>
                  <tr>
                    <th>#</th>
                    <th>CLIENT NAMES</th>
                    <th>LOAN AMOUNT (&#8358;)</th>
                    <th>DATE</th>
                    <th>REPAYMENT MADE (&#8358;)</th>
                    <th>TOTAL REPAYMENT (&#8358;)</th>
                    <th>ACTIONS</th>
                  </tr>

                  {currentItems &&
                    currentItems.map((data, index) => {
                      const date = data.date_of_disbursment.split(" ")[0];
                      return (
                        <tr key={data.id}>
                          <td>{index + 1}</td>
                          <td>{data.user?.customer_name}</td>

                          <td>
                            <NumberFormat
                              value={data.requested_amount}
                              decimalSeparator="."
                              thousandSeparator={true}
                              displayType="text"
                            />
                          </td>
                          <td>{date}</td>
                          <td>
                            <NumberFormat
                              value={data.repaymentspaid}
                              decimalSeparator="."
                              thousandSeparator={true}
                              displayType="text"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={data.repaymentsdue}
                              decimalSeparator="."
                              thousandSeparator={true}
                              displayType="text"
                            />
                          </td>
                          <td>
															<Link
																to={`/admin-dashboard/customers/loanInfo/${data.user_id}/${data.id}`}
															>
																<View />
															</Link>
														</td>
                        </tr>
                      );
                    })}
                  <Loader
                    style={{ display: loading ? "block" : "none" }}
                    loading={loading}
                  >
                    <ReactLoading type={"spin"} color="#000" />
                  </Loader>
                </Table>
              </TableBox>

              {formData.length > 15 ? (
                <Pajination
                  itemsPerPage={itemsPerPage}
                  items={formData}
                  setItemOffset={setItemOffset}
                />
              ) : null}
            </Details>
          </Info>
        </Board>
      </Contents>
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose="4000"
        hideProgressBar="false"
        closeOnClick
      ></ToastContainer>
    </Container>
  );
};

export default CurrentCustomers;

//styles
const Container = styled.section`
  display: flex;
  width: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 200px;

  @media screen and (max-width: 600px) {
    padding-left: 0;
  }
`;

const Board = styled.div`
  background: #f0f0f7;
  padding: 1rem 2rem 2rem 5rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  @media screen and (max-width: 640px) {
    padding: 1rem 2rem 2rem 2rem;
  }
`;
const TextBox = styled.div`
  width: 15%;
  h3 {
    color: #616161;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
const HeadingSearch = styled.section`
display: flex;
 justify-content: space-between;
margin-bottom: 20px;

 @media screen and (max-width: 920px) {
    flex-direction: column;
}

 h2 {
 font-weight: 200;

@media screen and (max-width: 920px) {
   margin-bottom: 15px;
  }
 }
 `;

// const Search = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0.6rem 0.2rem;
//   width: 400px;
//   background: #fff;
//   button {
//     width: 8%;
//     border-radius: 50%;
//     padding: 0.3rem 0.2rem;
//     border: none;
//     padding: none;
//   }
//   @media screen and (max-width: 920px) {
//     width: 100%;
//   }

//   input {
//     width: 87%;
//     outline: none;
//     border: none;
//   }
// `;

const Info = styled.div`
  display: flex;
  background: #fff;
  min-height: 70vh;
`;


const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;

// const Select = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	margin-bottom: 10px;

// 	@media screen and (max-width: 1320px) {
// 		flex-direction: column;
// 	}

// 	h3 {
// 		font-weight: 400;
// 	}
// `;

const Report = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
 

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #3b86ff;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 0 1.5rem;
    cursor: pointer;
    font-size: 15px;
    height: 3rem;
    @media only screen and (max-width: 759px) {
    width: 30%;
    
  }
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;
const SelectBox = styled.div`
  width: 60%;
  display: flex;

  @media only screen and (max-width: 759px) {
    width: 75%;
    margin: 1rem 0;
  }
`;

const Box = styled.div`
  width: 42%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  font-size: 15px;
  color: #808495;
  font-weight: 100;
  .css-b62m3t-container {
    width: 100%;
  }
  
  p {
    display: none;
  }
@media only screen and (max-width: 759px) {
   width: 48%;
  }
  @media screen and (max-width: 930px) {
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) and (min-width: 760px) {
    width: 50%;
  }
  @media only screen and (max-width: 480px) {
    width: 50%;
    padding: 0;
    p {
      display: block;
      margin-bottom: 0.2rem;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100% !important;
  margin-top: 1rem;

  @media screen and (max-width: 1500px) {
    width: 1000px;
  }

  th {
    background: #e9e9f0;
    color: #808495;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    border: none;
    text-align: left;
  }

  td {
    padding: 8px;
    color: #43425d;

    font-size: 13px;
    border-bottom: 1px solid #e9e9f0;
  }
`;
const Loader = styled.div`
  /* display: ${({ loading }) => (loading ? "flex" : "none")}; */
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Pages = styled.div`
  display: flex;
  justify-content: end;

  &:last-child {
    margin-right: 0;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    color: #43425d;
    font-size: 14px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e9e9f0;
    margin-right: 5px;
    cursor: pointer;
  }
`;

const TableBox = styled.div`
  
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-overflow-style: none;
  @media screen and (max-width: 1240px) {
	  overflow-x: scroll;
  }
`;
