import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Prev } from "../../assets/icons/prev.svg";
import { ReactComponent as Next } from "../../assets/icons/next.svg";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import Administrators from "../../components/Administrators/Administrators";
import EditAdmin from "../../components/EditAdmin/EditAdmin";
import CreateAdmin from "../../components/CreateAdmin/CreateAdmin";
import LogTable from "../../components/SuperAdminTables/LogTable";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	overflow: hidden;
	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 5rem 5rem;
	height: calc(100vh - 60px);
	overflow-x: scroll;
	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}
	h2 {
		font-weight: 200;
		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 10px;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		padding: 0;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	background-color: #eeeeee;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		width: 48%;
		margin-bottom: 0.2rem;
	}
	&:first-of-type {
		@media only screen and (max-width: 480px) {
			margin-right: 0.2rem;
		}
	}
	&:last-of-type {
		@media only screen and (max-width: 480px) {
			margin-left: 0.2rem;
		}
	}
`;

const Edit = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	@media screen and (max-width: 1000px) {
		padding: 2rem 1rem;
	}
	h4 {
		font-weight: 200;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin: 20px 5rem 0 10rem;
		@media screen and (max-width: 1000px) {
			margin: 20px 2rem 0 2rem;
		}

		@media screen and (max-width: 1000px) {
			margin: 20px 0 0 0;
		}
		input {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #000000;
			font-weight: 400;
			width: 100%;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}
	}
`;

const Update = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 13px;
	background: #3b86ff;
	border-radius: 3px;
	color: #fff;
	width: 150px;
	margin-top: 30px;
	cursor: pointer;
`;

const Table = styled.div`
	padding: 1rem 2rem;
	h3 {
		font-weight: 200;
		margin-bottom: 1rem;
	}
	@media screen and (max-width: 1300px) {
		padding: 1rem 0;
		width: 100%;
	}

	@media screen and (max-width: 1100px) {
		overflow-x: scroll;
	}
`;

const Log = styled.div``;

const Pages = styled.div`
	display: flex;
	justify-content: end;
	width: 90%;
	@media screen and (max-width: 1300px) {
		width: 100%;
	}
	&:last-child {
		margin-right: 0;
	}
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;

const SuperAdmin = () => {
	const [toggle, setToggle] = useState(1);
	const [edit, setEdit] = useState(false);
	const [newAdmin, setNewAdmin] = useState(false);

	const changeTab = (index) => {
		setToggle(index);
	};

	const showEdit = () => {
		setEdit(true);
	};

	const showNew = () => {
		setNewAdmin(true);
	};

	const showAdministrators = () => {
		setEdit(false);
		setNewAdmin(false);
	};

	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Super Admin</h2>

					<Info className="info">
						<Exit>Exit Super Admin</Exit>
						<Btns>
							<Button className="btn active-btn">Edit Login</Button>
							<Button to="/admin-dashboard/show">Administrators</Button>
							<Button to="/admin-dashboard/log">Change Log</Button>
							<Button to="/admin-charges/show">Charges</Button>
						</Btns>

						<Edit>
							<h4>Edit Super Admin Login</h4>

							<form action="">
								<input type="text" placeholder="USERNAME" />
								<input type="text" placeholder="PASSWORD" />

								<Update>Update</Update>
							</form>
						</Edit>

						{/* <div
							className={toggle === 2 ? "content active-content" : "content"}
						>
							{!edit && !newAdmin ? (
								<Administrators showEdit={showEdit} showNew={showNew} />
							) : edit ? (
								<EditAdmin
									edit={edit}
									showAdministrators={showAdministrators}
								/>
							) : (
								<CreateAdmin
									newAdmin={newAdmin}
									showAdministrators={showAdministrators}
								/>
							)}
						</div> */}

						<Log
							className={toggle === 3 ? "content active-content" : "content"}
						>
							<Table>
								<h3>Change Log</h3>
								<LogTable />
							</Table>
						</Log>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default SuperAdmin;
