import React from "react";

import styled, { css } from "styled-components/macro";
import calc from "../../assets/calculator.svg";
import profits from "../../assets/profits.svg";
import investment from "../../assets/investment.svg";
import portfolio from "../../assets/portfolio.svg";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { MdAccountCircle } from "react-icons/md";
import { AiOutlineAlert } from "react-icons/ai";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 4rem 10rem;
	text-align: center;
	font-weight: 550;
	p {
		color: #757575;
	}
	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}
`;

const Grid = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const Item = styled.div`
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0.6rem 0;
	div:last-of-type {
		min-height: 5rem;
	}
	h5 {
		color: #143763;
		font-size: 15px;
		margin-bottom: 30px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			margin-left: 50%;
			transform: translateX(-50%);
			height: 2px;
			width: 40px;
			background: #f79421;
		}
	}

	p {
		font-size: 13px;
	}
	@media only screen and (max-width: 768px) {
		width: 50%;
		margin: 0 auto;
	}
	@media only screen and (max-width: 480px) {
		width: 90%;
		margin: 0 auto;
	}
`;

const Svg = styled.div`
	width: 40px;
	height: 40px;
	margin-bottom: 15px;
	font-size: 2.5rem;
	img {
		width: 100%;
		height: 100%;
	}
`;

const Requirements = () => {
	return (
		<Container>
			<h2 style={{ color: "#212121" }}>Loan Requirements </h2>
			<p
				style={{ marginTop: "1.2rem", marginBottom: "60px", color: "#757575" }}
			>
				If you Satisfy the below requirements, Proceed to the Request Loan Form
			</p>

			<Grid>
				<Item>
					<Svg>
						<BsPhone />
					</Svg>

					<h5>Phone Number</h5>
					<div>
						<p>An active phone number is required for your authentication</p>
					</div>
				</Item>

				<Item>
					<Svg>
						<AiOutlineMail />
					</Svg>

					<h5>Email Address</h5>

					<div>
						<p>
							Your OTP will be sent to your Email address to complete loan
							disbursement
						</p>
					</div>
				</Item>

				<Item>
					<Svg>
						<MdAccountCircle />
					</Svg>

					<h5>Remita Account</h5>

					<div>
						<p>
							You must be a salary earner who receives salary through Remita.
						</p>
					</div>
				</Item>

				<Item>
					<Svg>
						<AiOutlineAlert />
					</Svg>

					<h5>Alert</h5>

					<div>
						<p>
							After your first loan request, you cannot use a different email
							with your phone number
						</p>
					</div>
				</Item>
			</Grid>
		</Container>
	);
};

export default Requirements;
