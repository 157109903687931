import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";

//components
import Topbar from "../../components/DashboardTopbars/Admin";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 5rem 5rem;
	height: calc(100vh - 60px);
	overflow: hidden;
	width: 100%;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}

	h2 {
		font-weight: 500;
		color: #37474f;
		margin-left: 2rem;
		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	background: #fff;
	width: 95%;
	margin: 1rem auto 0;

	div {
		width: 50%;
		margin: 0 auto;

		@media screen and (max-width: 880px) {
			width: 100%;
		}

		h3 {
			font-weight: 200;
		}

		form {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			input {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 10px;
				font-size: 15px;
				color: #000000;
				font-weight: 400;
				border: 1px solid #e9e9f0;
				outline: none;
				margin-bottom: 15px;
			}
			.inactive {
				background: #89c2d9;
				cursor: not-allowed;
			}
		}
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	background: #3b86ff;
	cursor: pointer;
	outline: none;
	border: none;
	color: #fafafa;
	font-weight: 400;
	font-size: 1rem;
	border-radius: 4px;
`;

const SuperAdminLogin = () => {
	const history = useHistory();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [click, setClick] = useState(true);

	const postData = (e) => {
		e.preventDefault();
		setClick(false);
		axios
			.post(`admin/login`, {
				username,
				password,
			})
			.then((response) => {
				// console.log(response);
				let token = response.data[0].token;
				setClick(true);
				localStorage.setItem("super-admin-token", token);
				history.push("/admin-dashboard/show");
			});
	};

	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("./admin");
		}
	}, []);

	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Super Admin</h2>

					<Info>
						<div>
							<h3>Login</h3>
							<form onSubmit={postData}>
								<input
									type="text"
									placeholder="USERNAME"
									onChange={(e) => setUsername(e.target.value)}
								/>
								<input
									type="password"
									placeholder="PASSWORD"
									onChange={(e) => setPassword(e.target.value)}
								/>

								<Button type="submit" className={click ? null : "inactive"}>
									Login
								</Button>
							</form>
						</div>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default SuperAdminLogin;
