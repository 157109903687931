import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import man from "../../assets/man.png";
import { forwardRef } from "react";

const RequestForm = (props, confirmRef) => {
	const [loading, setLoading] = useState(false);
	const [otpInput, setotpInput] = useState(false);
	const [click, setClick] = useState(true);

	const showOtp = () => {
		setotpInput(true);
	};
	const history = useHistory();
	useEffect(() => {
		// if (!otpInput) {
		// 	history.replace("/loan");
		// }
	}, [otpInput]);

	const { id } = JSON.parse(localStorage.getItem("user-loan"));
	const [code, setOTPCode] = useState("");
console.log(id)
	const postData = (e) => {
		e.preventDefault();
		let form = document.getElementById("otp-form");
		let formData = new FormData(form);
		console.log(formData)
		setClick(false);
		setLoading(true);
		axios
			.post(`user/verify-otp`, formData)
			.then((response) => {
				
				console.log(response.data);
				setLoading(false);
				setClick(true);
				if (response.data.error) {
					toast.error("Invalid OTP Code");
				}
				history.replace("/take-loan");
			})
			.catch((e) => {
				console.log({e});
				setClick(true);
				if (e) {
					toast.error("Invalid OTP. Code does not exist");
					setLoading(false);
				}
			});
	};

	return (
		<Container ref={confirmRef} {...props}>
			<Childbox>
				<h4 style={{ marginBottom: "15px" }}>
					Enter theOne-Time-Password that was sent to your email
				</h4>

				<Form >
					<form onSubmit={postData} id="otp-form">
						<input
							type="text"
							placeholder="OTP"
							name="code"
							onChange={(e) => setOTPCode(e.target.value)}
							required
						/>
						<input type="hidden" name="user_id" value={id} />
						<Error>
							<div>
								<a href="#">Resend OTP</a>
							</div>
							<div>Invalid OTP</div>
						</Error>
						<Button
							type="submit"
							className={click ? (code !== "" ? "active" : null) : "inactive"}
						>
							Proceed
						</Button>
					</form>
					<Load loading={loading}>
						<ReactLoading type={"spin"} color="#000" />
					</Load>
				</Form>
			</Childbox>

			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default forwardRef(RequestForm);

//styles

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #039be5;
	background-image: url(${man});
	background-size: cover;
	color: #fff;
	padding: 2rem 10rem;
	position: relative;

	@media screen and (max-width: 1200px) {
		padding: 2rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 2rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 2rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 2rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 2rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 2rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 2rem 2rem;
	}
`;
const Childbox = styled.div`
	width: 40%;
	margin: 0 auto;

	h4 {
		font-weight: 500;
		font-size: 1.2rem;
	}
	@media screen and (min-width: 769px) and (max-width: 1206px) {
		width: 50%;
	}
	@media screen and (max-width: 768px) {
		width: 60%;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
	}
`;
const Form = styled.div`
	display: flex;
	flex-direction: column;
	width: 90%;
	margin: 0 auto;
	z-index: 1;
	position: relative;

	input {
		outline: none;
		border: 1px solid #a4afb7;
		background: #fff;
		font-size: 15px;
		padding: 10px 20px;
		width: 100%;
	}
	.active {
		background-color: #01579b;
	}
	.inactive {
		background: #89c2d9;
		cursor: not-allowed;
	}
`;
const Textbox = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;
const Error = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 0.2rem;

	div {
		font-size: 1rem;
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
	@media screen and (max-width: 400px) {
		font-size: 10px;
	}

	@media screen and (max-width: 320px) {
		font-size: 8px;
	}
`;

const btnstyle = css`
	outline: none;
	border: none;
	padding: 10px 20px;
	background: #a4c2f2;
	width: 100%;
	color: #fff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 15px;
	font-weight: 500;
	font-size: 1.2rem;
	&:hover {
		background: #023e8a;
	}
`;

const Button = styled.button`
	${btnstyle};
`;

const Proceed = styled(Link)`
	${btnstyle};
`;
