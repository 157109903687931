import React, { useEffect } from 'react'

//components
import Navbar from '../components/Navbar/Navbar'
import Banner from '../components/Banner/HomepageBanner'
import Description from '../components/Description/Description'
import Consultation from '../components/Consultation/Consultation'
import Services from '../components/Services/Services'
import Numbers from '../components/Numbers/Numbers'
import VidDet from '../components/VidDet/VidDet'
import Meet from '../components/Meet/Meet'
import Customers from '../components/Customers/Customers'
import Footer from '../components/Footer/Footer'

const Homepage = () => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'auto'})
    }, []);
    
    return (
        <>
           <Navbar /> 
           <Banner /> 
           <Description />
           <Consultation />
           <Services />
           {/* <Numbers />
           <VidDet />
           <Meet />
           <Customers /> */}
           <Footer />
        </>
    )
}

export default Homepage
