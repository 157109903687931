import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { ReactComponent as Paid } from "../../assets/icons/paid.svg";
import { ReactComponent as Pending } from "../../assets/icons/unpaid.svg";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 15px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		padding: 0;
	}

	@media screen and (max-width: 480px) {
		overflow-x: scroll;
	}
`;

const Button = styled.button`
	width: 10%;
	text-align: center;
	padding: 12px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 768px) {
		width: 20%;
	}
	@media only screen and (max-width: 480px) {
		width: 25%;
	}
`;
const Details = styled.div``;

const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;
	margin-top: 10px;

	@media screen and (max-width: 1090px) {
		width: 800px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: center;
		font-size: 13px;
		color: #208518;
		border-bottom: 1px solid #e9e9f0;
	}
`;

const Unpaid = styled.tr`
	td {
		color: #fb3e0d;
	}
`;
const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const DisEna = styled.div`
	display: flex;
	justify-content: center;
	padding: 2rem;

	button {
		color: #fff;
		padding: 1rem 2rem;
		outline: none;
		border: none;
		cursor: pointer;
	}
`;

const Repayments = ({ userLoan, userData }) => {
	const client = userLoan?.userId;
	const loan = userLoan?.loanId;
	const [oneLoan, setOneLoan] = useState([]);
	const [loading, setLoading] = useState(false);

	const token = localStorage.getItem("admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const { id } = useParams();
	const retrieveCustomerLoan = () => {
		axios
			.get(`admin/loans/${client}`, config)
			.then((response) => {
				// console.log(response);
				setOneLoan(response.data.LoanHistories);
				setToggle(`loan${response.data.LoanHistories[0]?.loanId}`);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		const client = userLoan?.userId;
		retrieveCustomerLoan();
	}, [userLoan]);
	useEffect(() => {}, [oneLoan]);
	const [toggle, setToggle] = useState(null);
	const changeTab = (index) => {
		setToggle(index);
	};
	const [repayment, setrepayment] = useState(null);

	const getRepayment = async (loanId) => {
		const token = localStorage.getItem("admin-token");
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		// console.log(loanId);
		await axios
			.get(`/admin/loan-repayment/${loanId}/${client}`, config)
			.then((response) => {
				// console.log(response);
				setLoading(false);
				setrepayment(response.data.LoanRepayments);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error("Error, try again later");
			});
	};
	const disableRepayment = async () => {
		await axios
			.post(`user/stop-repayment/${id}/${userData?.id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => console.log(response))
			.catch((error) => console.log(error));
	};
	useEffect(() => {
		const client = userLoan?.userId;
		// console.log(oneLoan[0]?.loanId);
		getRepayment(oneLoan[0]?.loanId);
	}, [userLoan, oneLoan]);
	useEffect(() => {
		// console.log(oneLoan);
		// console.log(repayment);
	}, [oneLoan, repayment]);

	useEffect(() => {}, [toggle]);

	return (
		<Container>
			<Btns>
				{oneLoan &&
					oneLoan.map((loan, index) => {
						return (
							<>
								<Button
									key={`loan${loan.loanId}`}
									className={
										toggle === `loan${loan.loanId}` ? "btn active-btn" : "btn"
									}
									onClick={() => {
										changeTab(`loan${loan.loanId}`);
										setrepayment(null);
										let loanId = loan.loanId;
										setLoading(true);
										getRepayment(loanId);
										console.log(loanId);
									}}
								>
									{index === 0 ? "Current" : "Loan"}
								</Button>
							</>
						);
					})}
			</Btns>
			{oneLoan &&
				oneLoan.map((loan, index) => {
					return (
						<Details
							key={`loan${loan.loanId}`}
							className={
								toggle === `loan${loan.loanId}`
									? "content active-content"
									: "content"
							}
						>
							<Table>
								<tr>
									<th>#</th>
									<th>DATE</th>
									<th>MONTHLY REPAYMENT</th>
									<th>INTEREST</th>
									<th>PRINCIPAL</th>
									<th>STATUS</th>
									<th>ACTION</th>
								</tr>

								{repayment &&
									repayment.map((payment, index) => {
										const {
											monthlyInstallment,
											principal,
											status,
											repaymentDate,
											interestRate,
										} = payment;
										return (
											<tr key={payment.id}>
												<td>{index + 1}</td>
												<td>{repaymentDate}</td>
												<td>{monthlyInstallment}</td>
												<td>{interestRate}</td>
												<td>{principal}</td>
												<td>{status}</td>
												<td>{status !== "Paid" ? <Pending /> : <Paid />}</td>
											</tr>
										);
									})}
							</Table>
						</Details>
					);
				})}
			<DisEna>
				<button
					style={{
						background: "#43425D",
						borderBottomLeftRadius: "3px",
					}}
					onClick={disableRepayment}
				>
					DISABLE
				</button>
				<button
					style={{
						background: "#3B86FF",
						borderBottomRightRadius: "3px",
					}}
				>
					ENABLE
				</button>
			</DisEna>
			<Load loading={loading}>
				<ReactLoading type={"spin"} color="#000" />
			</Load>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default Repayments;
