import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import styled, { css } from "styled-components/macro";
import "react-toastify/dist/ReactToastify.css";
//components
import Topbar from "../DashboardTopbars/Admin";

const CreateAdminCharge = () => {
	const { id } = useParams();
	const history = useHistory();

	const token = localStorage.getItem("super-admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const [loading, setLoading] = useState(false);
	const [chargeData, setChargeData] = useState([]);
	const [charge, setcharge] = useState({
		name: "",
		amount: "",
		charge_type: "",
	});
	const [click, setClick] = useState(true);
	const chargeInfo = id ? chargeData.find((p) => p.id == id) : null;

	const retrieveCharges = async () => {
		const response = await axios.get(`admin/admin-charge`, config);
		setChargeData(response.data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setClick(false);
		setLoading(true);
		if (id) {
			updateData();
		} else {
			await axios.post("/admin/admin-charge", charge, config);
			setLoading(false);
			setClick(true);
			history.push("/admin-charges/show");
		}
		setClick(true);
	};

	const onChange = (e) => {
		setcharge({ ...charge, [e.target.name]: e.target.value });
	};

	const updateData = async () => {
		await axios.put(`/admin/admin-charge/${id}`, charge, config);
		setLoading(false);
		setClick(true);
		history.push("/admin-charges/show");
	};

	const logOut = () => {
		localStorage.removeItem("super-admin-token");
		history.push("/admin-dashboard/login");
	};

	useEffect(() => {
		if (!localStorage.getItem("admin-token")) {
			history.push("/admin");
		} else if (!localStorage.getItem("super-admin-token")) {
			history.push("/admin-dashboard/login");
		}
		retrieveCharges();
	}, []);

	useEffect(() => {
		if (chargeInfo) setcharge(chargeInfo);
	}, [chargeInfo]);

	return (
		<Container>
			<Contents>
				<Topbar />
				<Board>
					<h2>Super Admin</h2>
					<Info className="info">
						<Exit onClick={logOut}>Exit Super Admin</Exit>
						<Btns>
							{/* <Button to="/admin-dashboard/edit">Edit Login</Button> */}
							<Button to="/admin-dashboard/show">Administrators</Button>
							<Button to="/admin-dashboard/log">Change Log</Button>
							<Button className="btn active-btn">Charges</Button>
						</Btns>
						<div>
							<Space>
								<Back to="/admin-charges/show">Back</Back>
							</Space>
							<Wrap>
								<h3>Charges</h3>
								<div>
									<Edit>
										<form onSubmit={handleSubmit}>
											<input
												type="text"
												placeholder="CHARGE NAME"
												name="name"
												onChange={onChange}
												value={charge.name}
												required
											/>
											<select
												value={charge.charge_type}
												name="charge_type"
												onChange={onChange}
											>
												<option value="">Please select</option>
												<option value="percentage">PERCENTAGE</option>
												<option value="interest_rate">INTEREST RATE</option>
												<option value="fixed">FIXED</option>
											</select>
											<input
												type="number"
												placeholder="AMOUNT (N) | PERCENTAGE (%)"
												name="amount"
												onChange={onChange}
												value={charge.amount}
												required
											/>
											<button
												type="submit"
												className={!click ? "inactive" : null}
											>
												{id ? "Update" : "Create"}
											</button>
										</form>
									</Edit>
								</div>
							</Wrap>
						</div>
						<Load
							style={{ display: loading ? "block" : "none" }}
							loading={loading}
						>
							<ReactLoading type={"spin"} color="#000" />
						</Load>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default CreateAdminCharge;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Rank = styled.select`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 15px;
	color: #808495;
	font-weight: 100;
	border: 1px solid #e9e9f0;
	outline: none;
	margin-bottom: 15px;
	width: 100%;
	cursor: pointer;
	@media screen and (max-width: 400px) {
		width: 100%;
	}
`;
const Container = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem 0;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 2rem 2rem 2rem 5rem;
	@media screen and (max-width: 600px) {
		padding: 2rem;
	}
	h2 {
		margin-bottom: 20px;
		font-weight: 200;
		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 10px;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
	div {
		display: flex;
	}
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		padding: 0;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		width: 48%;
		margin-bottom: 0.2rem;
	}
	&:first-of-type {
		@media only screen and (max-width: 480px) {
			margin-right: 0.2rem;
		}
	}
	&:last-of-type {
		@media only screen and (max-width: 480px) {
			margin-left: 0.2rem;
		}
	}
`;

const Space = styled.div`
	padding: 0.5rem;
	width: 150px;
	border-right: 1px solid #e9e9f0;
	@media screen and (max-width: 750px) {
		display: none;
	}
`;

const Back = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: #3b86ff;
	color: #fff;
	cursor: pointer;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	width: 100%;
	@media screen and (max-width: 750px) {
		/* font-size: 11px; */
	}
`;

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	h3 {
		padding: 1rem;
		font-weight: 200;
	}
`;

const Edit = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	width: 100%;
	@media screen and (max-width: 1000px) {
		padding: 2rem 1rem;
	}
	h4 {
		font-weight: 200;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin: 20px 5rem 0 10rem;
		@media screen and (max-width: 1000px) {
			margin: 20px 2rem 0 2rem;
		}
		@media screen and (max-width: 1000px) {
			margin: 20px 0 0 0;
		}
		input {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #000000;
			font-weight: 400;
			width: 100%;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}
		select {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #808495;
			font-weight: 100;
			width: 100%;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}
		.inactive {
			background: #89c2d9;
			cursor: not-allowed;
		}
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px;
			font-size: 13px;
			background: #3b86ff;
			border-radius: 3px;
			color: #fff;
			width: 150px;
			margin-top: 30px;
			cursor: pointer;
			outline: none;
			border: none;
		}
	}
`;
