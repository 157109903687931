import React from "react";
import ReactLoading from "react-loading";
import styled, { css } from "styled-components/macro";

import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";

const LogTable = ({ formData, loading }) => {
	return (
		<>
			<Table>
				<tr>
					<th>#</th>
					<th>CHANGE BY</th>
					<th>CHANGES MADE</th>
					<th>OBJECT</th>
					<th>DATE</th>
				</tr>
				{formData &&
					formData.map((data) => {
						return (
							<tr key={data.id}>
								<td>{data.id}</td>
								<td>{data.fullname}</td>
								<td>{data.activity}</td>
								<td>{data.object}</td>
								<td>{data.created_at}</td>
							</tr>
						);
					})}
				<Load style={{ display: loading ? "block" : "none" }} loading={loading}>
					<ReactLoading type={"spin"} color="#000" />
				</Load>
			</Table>
		</>
	);
};

export default LogTable;

//styles
const Table = styled.table`
	border-collapse: collapse;
	/* margin-bottom: 15px; */
	width: 100%;

	@media screen and (max-width: 1300px) {
		width: 100%;
	}

	@media screen and (max-width: 1100px) {
		width: 800px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
		text-align: left;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: left;
		font-size: 13px;
		border-bottom: 1px solid #e9e9f0;

		button {
			outline: none;
			border: none;
			background: transparent;
			cursor: pointer;
		}
	}
`;

const Load = styled.div`
	/* display: ${({ loading }) => (loading ? "flex" : "none")}; */
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
