export function userAuthHeader() {
    const user = localStorage.getItem('user-token');
    if (user) {
      return  { Authorization: 'Bearer ' + user };
    } else {
      return {};
    }
  }

export function adminAuthHeader() {
    const admin = localStorage.getItem('admin-token');
    if (admin) {
      return  { Authorization: 'Bearer ' + admin };
    } else {
      return {};
    }
  }