import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import check from '../../assets/check.svg'
//components
import Topbar from '../../components/DashboardTopbars/Admin'


const CreateAdmin = () => {

    const history = useHistory()
    const logOut = () => {
        localStorage.removeItem("super-admin-token");
        history.push('/admin-dashboard/login')
      };

    return (
        <Container>
            <Contents>
                <Topbar />

                <Board>
                    <h2>Super Admin</h2>
                    
                    <Info className='info'>
                        <Exit onClick={logOut}>Exit Super Admin</Exit>
                        <Btns>
                            <Button to='/admin-dashboard/edit'>Edit Login</Button>
                            <Button  to='/admin-dashboard/show'>Administrators</Button>
                            <Button to='/admin-dashboard/log'>Change Log</Button>                
                            <Button className='btn active-btn'>Charges</Button>                
                        </Btns>

                        <Wrap>
                            <h2>Charge was created Successfuly!</h2>
                            <img src={check} alt='check' />
                            <DashBtn to='/admin-charges/show'>View Charges</DashBtn>
                        </Wrap>
                    </Info>
                </Board>
            </Contents>
        </Container>
    )
}

export default CreateAdmin

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem 0;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 200px;

    @media screen and (max-width: 600px) {
        padding: 0;
    }
`

const Board = styled.div`
    background: #F0F0F7;
    height: 100%;
    padding: 2rem 2rem 2rem 5rem;  

    @media screen and (max-width: 600px) {
        padding: 2rem;
    }
    
    h2 {
        margin-bottom: 20px;
        font-weight: 200;

        @media screen and (max-width: 350px) {
            font-size: 18px;
        }
    }
`


const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 10px;
    background: #fff;
    position: relative;

    @media screen and (max-width: 860px) {
        padding-top: 4rem;
    }

    div {
        display: flex;
    }
`
const Exit = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    background: #43425D;
    position: absolute;
    top: 10px;
    right: 10px;
`

const Btns = styled.div`
    display: grid;
    grid-template-columns: 120px 150px 120px;
    border-bottom: 1px solid #E9E9F0;
    border-right: none;
    border-left: none;
    color: #43425D;

    @media screen and (max-width: 860px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        padding: 0;
    }
    
    @media screen and (max-width: 320px) {
        grid-template-columns: 1fr;
    }
`

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    outline: none;
    border: 1px solid #E9E9F0;
    border-bottom: none;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: black;

    @media screen and (max-width: 860px) {
        margin-right: 0;
    }
`

const Space = styled.div`
    padding: .5rem;
    width: 150px;
    border-right: 1px solid #E9E9F0;

    @media screen and (max-width: 750px) {
        display: none;
    }
`

const Back = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #3B86FF;
    color: #fff;
    cursor: pointer; 
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    width: 100%;

    @media screen and (max-width: 750px) {
        /* font-size: 11px; */
    }
`

const Wrap = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5rem 1rem;

    h2 {
        color: #2A7B12;

        @media screen and (max-width: 300px) {
            font-size: 22px;
        }
    }

    div {
        margin: 15px 0 0 0;
        font-size: 15px;
    }

    img {
        height: 150px;
    }
`

const DashBtn = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #A4C2F2;
    color: #fff;
    padding: 8px 20px;
    font-size: 15px;
`