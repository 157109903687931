import * as api from "../api/user";
import * as actionType from "../constant/actionType";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const FetchBioDaata = (id) => async (dispatch) => {
	try {
		const { data } = await api.getBioDaata(id);
		dispatch({
			type: actionType.FETCHBIODATA,
			payload: data,
		});
		console.log(data);
	} catch (error) {
		console.log("error");
	}
};

export const FetchAccountInfo = (id) => async (dispatch) => {
	try {
		const { data } = await api.getAccountInfo(id);
		dispatch({
			type: actionType.FETCHACCOUNTINFO,
			payload: data,
		});
	} catch (error) {
		console.log(error);
	}
};

export const FetchEmployeeInfo = (id) => async (dispatch) => {
	try {
		const { data } = await api.getEmployeeInfo(id);
		dispatch({
			type: actionType.FETCHEMPLOYEEINFO,
			payload: data,
		});
	} catch (error) {
		console.log("error");
	}
};

export const RegisterUser = (formData, history) => async (dispatch) => {
	try {
		const { data } = await api.registerUser(formData);
		dispatch({
			type: actionType.REGISTERUSER,
			payload: data,
		});
		history.push("/login");
	} catch (error) {
		console.log("error");
	}
};

export const LoginUser = (formData, history) => async (dispatch) => {
	const { data } = await api.loginUser(formData);
	try {
		dispatch({
			type: actionType.LOGINUSER,
			payload: data,
		});
		let { user, token } = data[0];
		console.log("user");
		localStorage.setItem("user-token", token);
		
		localStorage.setItem("user-info", JSON.stringify(user));
		history.push("/dashboard");
	} catch (error) {
		console.log({error});
		toast.error("Wrong Credentials");
	}
};
