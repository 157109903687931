import { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";

export const Pajination = ({ itemsPerPage, items, setItemOffset }) => {
	const [pageCount, setpageCount] = useState(0);
	useEffect(() => {
		setpageCount(Math.ceil(items?.length / itemsPerPage));
	}, [items, itemsPerPage]);

	const handlePageClick = (e) => {
		const newOffset = (e.selected * itemsPerPage) % items.length;
		setItemOffset(newOffset);
	};
	return (
		<Container>
			<ReactPaginate
				nextLabel="Next"
				onPageChange={handlePageClick}
				pageRangeDisplayed={2}
				pageCount={pageCount}
				previousLabel="Prev"
				renderOnZeroPageCount={null}
				containerClassName="pagination"
				activeClassName="active"
				style={{ color: "black" }}
			/>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
	.pagination {
		display: flex;
		list-style: none;
		li {
			padding: 0.8rem;
			border: 1px solid #f5f5f5;
			&:hover {
				cursor: pointer;
			}
			a {
				color: #29b6f6;
			}
		}
		.active {
			background-color: #29b6f6;
			a {
				color: white;
			}
		}
	}
	@media only screen and (max-width: 480px) {
		width: 95%;
		margin: 0 auto;
		justify-content: flex-start;
		.pagination {
			width: 95vw;
			margin: 1rem auto;
			flex-wrap: wrap;
			li {
				padding: 0.6rem;
				font-size: 0.8rem;
			}
		}
	}
`;
