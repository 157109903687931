import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import dp from "../../assets/avatar.png";

//svgs
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

//components
import DashboardTopbar from "../../components/DashboardTopbars/Client";
import { FetchBioDaata } from "../../actions/user";

const CustomerProfile = () => {
	const [toggle, setToggle] = useState(1);

	const changeTab = (index) => {
		setToggle(index);
	};

	const dispatch = useDispatch();
	const { id } = JSON.parse(localStorage.getItem("user-info"));
	console.log(id);
	const userData = useSelector((state) => state.users.biodata);

	useEffect(() => {
		dispatch(FetchBioDaata(id));
	}, [dispatch]);

	const history = useHistory();
	useEffect(() => {
		if (!localStorage.getItem("user-token")) {
			history.push("./login");
		}
	}, []);

	const userId = localStorage.getItem("User ID");

	return (
		<Container>
			<Contents>
				<DashboardTopbar />

				<Board className="info">
					<h2>My Profile</h2>

					<Info>
						<DpNo>
							<div>
								<h3>{userData.customer_name}</h3>
								<h5>Mobile : {userData.phone_number}</h5>
							</div>
							{/* <Link to="/dashboard/profile/edit">
								<Edit
									style={{ position: "absolute", top: "15px", right: "15px" }}
								/>
							</Link> */}
						</DpNo>

						<Infos>
							<Btns>
								<Button
									className={toggle === 1 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(1)}
								>
									Bio-Data
								</Button>
								<Button
									className={toggle === 2 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(2)}
								>
									Employee Info
								</Button>
								<Button
									className={toggle === 3 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(3)}
								>
									Account Information
								</Button>{" "}
							</Btns>

							<Details
								className={toggle === 1 ? "content active-content" : "content"}
							>
								<Group>
									<div>
										<h3>Client Name</h3>
										<h5>{userData.customer_name}</h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>Phone No</h3>
										<h5>{userData.phone_number}</h5>
									</div>

									<div>
										<h3>Email</h3>
										<h5>{userData.email}</h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>Age</h3>
										<h5>50</h5>
									</div>

									<div>
										<h3>Date of Birth</h3>
										<h5></h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>Address</h3>
										<h5></h5>
									</div>
								</Group>
							</Details>

							<Details
								className={toggle === 2 ? "content active-content" : "content"}
							>
								<Group>
									<div>
										<h3>ORGANIZATION</h3>
										<h5>{userData.company_name}</h5>
									</div>

									<div>
										<h3>RETIREMENT DATE</h3>
										<h5></h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>EMPLOYEE NO</h3>
										<h5></h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>EMPLOYMENT DATE</h3>
										<h5></h5>
									</div>
								</Group>
							</Details>

							<Details
								className={toggle === 3 ? "content active-content" : "content"}
							>
								<Group>
									<div>
										<h3>BANK NAME</h3>
										<h5>{userData.bank_code}</h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>ACCOUNT NAME</h3>
										<h5>{userData.customer_name}</h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>ACCOUNT NO</h3>
										<h5>{userData.account_number}</h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>BVN</h3>
										<h5></h5>
									</div>
								</Group>
							</Details>
						</Infos>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default CustomerProfile;

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 1rem 2rem 2rem 5rem;
	overflow-x: hidden;
	overflow-y: scroll;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 10px;
		font-weight: 200;
	}
`;

const DpNo = styled.div`
	display: flex;
	padding: 15px;
	position: relative;

	@media screen and (max-width: 770px) {
		flex-direction: column;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;

		h3 {
			color: #4d4f5c;
			margin-bottom: 15px;

			@media screen and (max-width: 400px) {
				font-size: 15px;
			}

			@media screen and (max-width: 770px) {
				margin-top: 15px;
			}
		}

		h5 {
			color: #707070;
			font-weight: 200;
			font-size: 15px;
		}
	}
`;

const Dp = styled.div`
	width: 70px;
	height: 70px;
	border-radius: 50%;
	margin-right: 150px;

	@media screen and (max-width: 860px) {
		margin-right: 50px;
	}

	@media screen and (max-width: 480px) {
		margin-right: 10px;
	}

	@media screen and (max-width: 400px) {
		width: 60px;
		height: 60px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;

const Info = styled.div`
	background: #fff;
`;

const Infos = styled.div`
	display: flex;
	flex-direction: column;
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 150px 150px 200px 150px;
	border: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Details = styled.div``;

const Group = styled.div`
	display: flex;
	padding: 25px 0 10px 15px;
	border-bottom: 1px solid #e9e9f0;

	@media screen and (max-width: 860px) {
		padding: 40px 15px 15px 15px;
	}

	@media screen and (max-width: 555px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}

	div {
		display: flex;
		flex-direction: column;
		width: 400px;

		h3 {
			font-size: 17px;
			font-weight: 550;
			color: #4d4f5c;
			margin-bottom: 10px;
		}

		h5 {
			color: #43425d;
			font-size: 14px;
			font-weight: 200;
		}
	}
`;
