import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import dp from '../../assets/avatar.png'

//components
import Topbar from '../../components/DashboardTopbars/Admin'
import LoanDetails from '../../components/LoanDetails/LoanDetails'
import Repayments from '../../components/Repayments/Repayments'

import {FetchBioDaata } from '../../actions/user';

const AdminBioData = () => {
    const [toggle, setToggle] = useState(1);

    const changeTab = (index) => {
        setToggle(index);
    }

    const dispatch = useDispatch()
    const {id} = useParams();
    const userData = useSelector((state) => state.users.biodata);

    useEffect(() => {
        dispatch(FetchBioDaata(id));
    }, [dispatch])

    return (
        <Container>
            
            <Contents>
                <Topbar />

                <Board className='info'>
                    <h2>Customers</h2>

                    <Info>
                        <DpNo>
                            <Dp><img src={dp} alt="display picture" /></Dp>

                            <div>
                                <h3>{userData.customer_name}</h3>
                                <h5>Mobile : {userData.phone_number}</h5>
                            </div>
                        </DpNo>

                        <Infos>
                            <Btns>
                                <Button to={`/admin-bio-data/${id}`}>Bio-Data</Button>
                                <Button to={`/admin-employee-info/${id}`}>Employee Info</Button>
                                <Button to={`/admin-account-info/${id}`}>Account Information</Button>
                                <Button to='/take-loan'>Loan Details</Button> 
                            </Btns>

                            <Details>
                                <Group>
                                    <div>
                                        <h3>Phone No</h3>
                                        <h5>{userData.phone_number}</h5>
                                    </div>
                                    
                                    <div>
                                        <h3>Email</h3>
                                        <h5>{userData.email}</h5>
                                    </div>
                                </Group>
                            
                                <Group>
                                    <div>
                                        <h3>Age</h3>
                                        <h5></h5>
                                    </div>
                                    
                                    <div>
                                        <h3>Date of Birth</h3>
                                        <h5></h5>
                                    </div>
                                </Group>
                                
                                <Group>
                                    <div>
                                        <h3>Address</h3>
                                        <h5></h5>
                                    </div>
                                </Group>
                            </Details>
                            
                            <Details className={ toggle === 4 ? 'content active-content' : 'content'}>
                                <LoanDetails />
                            </Details>

                            <Details className={ toggle === 5 ? 'content active-content' : 'content'}>
                                <Repayments />
                            </Details>
                                <DisEna>
                                    <button style={{background: '#43425D', borderBottomLeftRadius: '3px'}}>DISABLE</button>
                                    <button style={{background: '#3B86FF', borderBottomRightRadius: '3px'}}>ENABLE</button>
                                </DisEna>
                        </Infos>
                    </Info>
                </Board>
            </Contents>
        </Container>
    )
}

export default AdminBioData



//styles
const Container = styled.section`
    display: flex;
    height: 100vh;
    overflow: hidden;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 200px;

    @media screen and (max-width: 600px) {
        padding: 0;
    }
`

const Board = styled.div`
    background: #F0F0F7;
    height: 100%;
    padding: 1rem 2rem 2rem 5rem; 
    overflow-x: hidden;
    overflow-y: scroll;

    @media screen and (max-width: 600px) {
        padding: 2rem;
    }
    
    h2 {
        margin-bottom: 10px;
        font-weight: 200;
    }
`

const DpNo = styled.div`
    display: flex;
    padding: 15px;
    position: relative;

    @media screen and (max-width: 770px) {
        flex-direction: column;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            color: #4D4F5C;
            margin-bottom: 15px;

            @media screen and (max-width: 400px) {
                font-size: 15px;  
            }

            @media screen and (max-width: 770px) {
                margin-top: 15px;
            }
        }

        h5 {
            color: #707070;
            font-weight: 200;
            font-size: 15px;
        }
    }
`

const Dp = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 150px;

    @media screen and (max-width: 860px) {
        margin-right: 50px;
    }
    
    @media screen and (max-width: 480px) {
        margin-right: 10px;
    }

    @media screen and (max-width: 400px) {
        width: 60px;
        height: 60px;   
    }

    img {
        width: 100%;
        height: 100%;
    }
`

const Info = styled.div`
    background: #fff; 
`

const Infos = styled.div`
    display: flex;
    flex-direction: column;
`

const Btns = styled.div`
    display: grid;
    grid-template-columns: 150px 150px 200px 150px 150px;
    border: 1px solid #E9E9F0;
    border-right: none;
    border-left: none;
    color: #43425D;
    padding: 0 15px;

    @media screen and (max-width: 860px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        padding: 0;
    }
    
    @media screen and (max-width: 320px) {
        grid-template-columns: 1fr;
    }
`

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    outline: none;
    border-left: 1px solid #E9E9F0;
    border-right: 1px solid #E9E9F0;
    border-top: none;
    border-bottom: none;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: black;

    @media screen and (max-width: 860px) {
        margin-right: 0;
    }
`

const Details = styled.div`
`

const Group = styled.div`
    display : flex;
    padding: 25px 0 10px 15px;
    border-bottom: 1px solid #E9E9F0;

    @media screen and (max-width: 860px) {
       padding: 40px 15px 15px 15px;
    }
        
    @media screen and (max-width: 555px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    div {
        display : flex;
        flex-direction: column;
        width: 400px;


        h3 {
            font-size: 17px;
            font-weight: 400;
            color: #4D4F5C;
            margin-bottom: 10px;
        }

        h5 {
            color: #43425D;
            font-size: 14px;
            font-weight: 200;
        }

    }
`

const DisEna = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;

    button {
        color: #fff;
        padding: 10px 20px;
        outline: none;
        border: none;
        cursor: pointer;
    }
`
