import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
// import styled, { css } from 'styled-components/macro'
import styled from "styled-components/macro";
import Pagination from "../../components/pagination/usePagination";

//svgs
import { ReactComponent as Srch } from "../../assets/icons/search.svg";
import ReactLoading from "react-loading";
// import { ReactComponent as Arr } from "../../assets/icons/downarrowbig.svg";
import { ReactComponent as View } from "../../assets/icons/search.svg";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import CustomersTableAdmin from "../../components/CustomersTableAdmin/CustomersTableAdmin";
import { Pajination } from "../../components/pagination/Pajination";

const AdminCustomerList = () => {
	// const history = useHistory()
	// useEffect(() => {
	//    if (!localStorage.getItem('admin-token')) {
	//     history.push('/admin')
	//    }
	// }, [])

	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState([]);
	const [employerArray, setemployerArray] = useState([]);
	const [monthArray, setmonthArray] = useState([]);
	const [yearArray, setyearArray] = useState([]);
	const [searchInfo, setsearchInfo] = useState({
		name: "",
		month: "",
		year: "",
	});
	const [searchInput, setsearchInput] = useState("");
	const token = localStorage.getItem("admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	// const retrieveCustomers = () => {
	// 	axios
	// 		.get(`admin/clientsEntries`, config)
	// 		.then((response) => {
	// 			// console.log(response.data.client);
	// 			setFormData(response.data.client);
	// 			setLoading(false);
	// 			let employers = [];
	// 			let dates = [];
	// 			let months = [];
	// 			for (let i = 0; i < response.data.client.length; i++) {
	// 				let employer = response.data.client[i]?.company_name;
	// 				let date = response.data.client[i].created_at;
	// 				let year = new Date(date).getFullYear();
	// 				let month = new Date(date).getMonth();
	// 				let newMonth = month + 1;

	// 				months.push(newMonth);
	// 				if (!employers.includes(employer)) {
	// 					employers.push(employer);
	// 				}
	// 				if (!dates.includes(year)) {
	// 					dates.push(year);
	// 				}
	// 			}
	// 			// console.log(formData);
	// 			setemployerArray(employers);
	// 			setmonthArray(months);
	// 			setyearArray(dates);
	// 		})
	// 		.catch((e) => {
	// 			console.log(e);
	// 			setLoading(false);
	// 		});
	// };
	// // console.log(employerArray);
	// const employerOptions = employerArray.map((employer, index) => {
	// 	return {
	// 		label: employer,
	// 		value: employer,
	// 		key: index,
	// 	};
	// });

	// const monthOptions = [
	// 	{ value: 1, label: "January" },
	// 	{ value: 2, label: "February" },
	// 	{ value: 3, label: "March" },
	// 	{ value: 4, label: "April" },
	// 	{ value: 5, label: "May" },
	// 	{ value: 6, label: "June" },
	// 	{ value: 7, label: "July" },
	// 	{ value: 8, label: "August" },
	// 	{ value: 9, label: "September" },
	// 	{ value: 10, label: "October" },
	// 	{ value: 11, label: "November" },
	// 	{ value: 12, label: "December" },
	// ];
	// const yearOptions = yearArray.map((year, index) => {
	// 	return {
	// 		label: year,
	// 		value: year,
	// 		key: index,
	// 	};
	// });
	// useEffect(() => {
	// 	retrieveCustomers();
	// }, []);
	// const handleEmployerChange = (value) => {
	// 	setsearchInfo({
	// 		...searchInfo,
	// 		name: value.value,
	// 	});
	// };
	// const handleMonthChange = (value) => {
	// 	setsearchInfo({
	// 		...searchInfo,
	// 		month: value.value,
	// 	});
	// };
	// const handleYearChange = (value) => {
	// 	setsearchInfo({
	// 		...searchInfo,
	// 		year: value.value,
	// 	});
	// };
	const handleInputChange = (e) => {
		setsearchInput(e.target.value);
	};

	//new pagination

	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 10;
	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;
		// console.log(`Loading items from ${itemOffset} to ${endOffset}`);
		setCurrentItems(formData?.slice(itemOffset, endOffset));
	}, [itemOffset, itemsPerPage, formData]);

	useEffect(() => {}, [currentItems]);
	// const handleSearch = () => {
	// 	console.log(config);
	// 	axios
	// 		.get(`/admin/search`, searchInput, config)
	// 		.then((response) => {
	// 			console.log(response);
	// 		})
	// 		.catch((error) => console.log(error));
	// };
	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<HeadingSearch>
						<h2>Customers</h2>

						{/* <Search>
							<button>
								<Srch />{" "}
							</button>
							<input
								type="text"
								placeholder="Search..."
								value={searchInput}
								onChange={handleInputChange}
							/>
						</Search> */}
					</HeadingSearch>

					<Info>
						{/* <Space> </Space> */}

						<Details>
							<div>
								<Report>
									<TextBox>
										<h3>View Customers</h3>
									</TextBox>
								</Report>
							</div>

							<div style={{width: "100%"}}>
								<Table>
									<tr>
										<th>#</th>
										<th>CLIENT NAMES</th>
										<th>PHONE NO</th>
										<th>GROUP NAME/EMPLOYER</th>
										{/* <th>LOAN AMOUNT</th> */}
										{/* <th>ACTIONS</th> */}
									</tr>

									<tr>
										<td>1</td>

										<td>John Doe</td>

										<td>070999999977</td>
										<td>PSS</td>

										{/* <td>
															<Link
																to={`/admin-dashboard/customers/${data.id}`}
															>
																<View />
															</Link>
														</td> */}
									</tr>

									{/* <Loader
										style={{ display: loading ? "block" : "none" }}
										loading={loading}
									>
										<ReactLoading type={"spin"} color="#000" />
									</Loader> */}
								</Table>
							</div>

							{formData.length > 15 ? (
								<Pajination
									itemsPerPage={itemsPerPage}
									items={formData}
									setItemOffset={setItemOffset}
								/>
							) : null}
						</Details>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default AdminCustomerList;

//styles
const Container = styled.section`
	display: flex;
	width: 100%;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 2rem 5rem;
	height: 100%;
	// overflow-y: scroll;
	// overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}
`;
const TextBox = styled.div`
	width: 15%;
	h3 {
		color: #616161;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
const HeadingSearch = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	@media screen and (max-width: 920px) {
		flex-direction: column;
	}

	h2 {
		font-weight: 200;

		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Search = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.6rem 0.2rem;
	width: 400px;
	background: #fff;
	button {
		width: 8%;
		border-radius: 50%;
		padding: 0.3rem 0.2rem;
		border: none;
		padding: none;
	}
	@media screen and (max-width: 920px) {
		width: 100%;
	}

	input {
		width: 87%;
		outline: none;
		border: none;
	}
`;

const Info = styled.div`
	display: flex;
	background: #fff;
	min-height: 70vh;
`;

const Space = styled.div`
	width: 150px;
	border-right: 1px solid #e9e9f0;

	@media screen and (max-width: 1200px) {
		display: none;
	}
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
`;

// const Select = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	margin-bottom: 10px;

// 	@media screen and (max-width: 1320px) {
// 		flex-direction: column;
// 	}

// 	h3 {
// 		font-weight: 400;
// 	}
// `;

const Report = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	@media screen and (max-width: 930px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
	}

	@media screen and (max-width: 670px) {
		grid-template-columns: 1fr;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		background: #3b86ff;
		border-radius: 3px;
		outline: none;
		border: none;
		padding: 0 1.5rem;
		cursor: pointer;
		font-size: 15px;
		height: 3rem;
	}
	@media only screen and (max-width: 480px) {
		margin-bottom: 1.5rem;
	}
`;
const SelectBox = styled.div`
	width: 70%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;

const Box = styled.div`
	width: 32%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 12px;
	font-size: 15px;
	color: #808495;
	font-weight: 100;
	.css-b62m3t-container {
		width: 100%;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
	@media screen and (max-width: 930px) {
		margin-right: 0;
	}
`;

const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100% !important;

	@media screen and (max-width: 1500px) {
		width: 1000px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: center;
		font-size: 13px;
		border-bottom: 1px solid #e9e9f0;
	}
`;
const Loader = styled.div`
	/* display: ${({ loading }) => (loading ? "flex" : "none")}; */
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Pages = styled.div`
	display: flex;
	justify-content: end;

	&:last-child {
		margin-right: 0;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;
