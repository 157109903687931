import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	background-color: white;
	margin: 2rem 0;
`;
export const Content = styled.div`
	width: 50%;
	margin: 0 auto;
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 1rem;
	align-items: center;
	justify-content: flex-start;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
	@media only screen and (max-width: 768px) {
		width: 70%;
		margin: 0 auto;
	}
	@media only screen and (max-width: 480px) {
		width: 90%;
		margin: 0 auto;
	}
`;
export const ContentChild = styled.div`
	width: 100%;
	text-align: start;
	p {
		margin: 1.8rem 0;
		border-bottom: 1px solid #f5f5f5;
		font-weight: 500;
		box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
			rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
		height: 2.5rem;
	}
`;
export const Heading = styled.h4`
	font-size: 1.5rem;
	margin-bottom: 1rem;
	color: #757575;
`;

export const ButtonBox = styled.div`
	width: 50%;
	margin: 1rem 0;
	display: flex;
	justify-content: flex-end;

	.disable {
		background: #81d4fa;
		cursor: not-allowed;
	}
	.inactive {
		background: #81d4fa;
		cursor: not-allowed;
	}

	button {
		padding: 0.7rem 1.5rem;
		border-radius: 3px;
		background: #3b86ff;
		cursor: pointer;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
		outline: none;
		border: none;
	}

	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;

export const ActionDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	div {
		p {
			font-size: 0.9rem;
		}
	}
`;
