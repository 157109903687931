import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";

import DashboardSidebar from "../../components/DashboardSidebars/Admin";

import { AiOutlineMenu } from "react-icons/ai";

//images
import avatar from "../../assets/new_avatar.png";

//svgs
import { ReactComponent as Pf } from "../../assets/icons/profile.svg";
import { ReactComponent as Message } from "../../assets/icons/message.svg";
import { ReactComponent as Notifications } from "../../assets/icons/notification.svg";
import { ReactComponent as Down } from "../../assets/icons/arrowdown.svg";

const AdminTopbar = () => {
	const [isopen, setisopen] = useState(false);
	const [dropdown, setDropdown] = useState(false);

	const toggle = () => {
		setisopen(!isopen);
	};

	const showDropdown = () => {
		setDropdown(!dropdown);
	};

	const history = useHistory();

	const admin = localStorage.getItem("admin");
	const admin_info = JSON.parse(admin);
	const logOut = () => {
		localStorage.removeItem("admin-token");
		localStorage.removeItem("admin");
		localStorage.removeItem("super-admin-token");
		history.push("/");
	};

	return (
		<>
			<Topbar>
				<MenuIcon onClick={toggle} isopen={isopen} />
				<h5></h5>

				<Right>
					<div>
						{/* <Pf />
                        <Message />
                        <Notifications /> */}
					</div>

					<Profile>
						<div style={{ position: "relative" }}>
							<h3
								style={{ textTransform: "capitalize", cursor: "pointer" }}
								onClick={showDropdown}
							>
								{admin_info.username} <Down style={{ margin: "0 10px" }} />
							</h3>
							<Dropdown dropdown={dropdown}>
								<Link to="/admin/dashboard/profile">My Profile</Link>
								<Button onClick={logOut}>Logout</Button>
							</Dropdown>
						</div>
						<Dp>
							<img src={avatar} alt="display" />
						</Dp>
					</Profile>
				</Right>
			</Topbar>
			<DashboardSidebar isopen={isopen} toggle={toggle} />
		</>
	);
};

export default AdminTopbar;

//styles
const Dropdown = styled.div`
	display: flex;
	display: none;
	flex-direction: column;
	position: absolute;
	bottom: -100px;
	right: 0;
	background: #43425d;
	padding: 1rem;
	border-radius: 3px;
	visibility: ${({ dropdown }) => (dropdown ? "visible" : "hidden")};

	a {
		font-size: 13px;
		margin-bottom: 10px;
	}
`;
const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	border-radius: 3px;
	cursor: pointer;
	color: #fff;
	background: transparent;
	font-size: 13px;
	background: #3b86ff;
`;
const Topbar = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	padding: 10px 2rem;
	background: #fff;

	@media screen and (max-width: 620px) {
		padding: 10px 2rem;
	}

	h5 {
		font-size: 13px;
		font-weight: 300;
		color: #4d4f5c;

		@media screen and (max-width: 400px) {
			display: none;
		}
	}
`;

const MenuIcon = styled(AiOutlineMenu)`
	display: none;

	@media screen and (max-width: 600px) {
		display: flex;
		width: 25px;
		height: 25px;
		color: #000;
	}
`;

const Right = styled.div`
	display: flex;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 2px solid #f0f0f7;

		@media screen and (max-width: 680px) {
			display: none;
		}

		svg {
			margin-right: 20px;

			@media screen and (max-width: 620px) {
				margin-right: 15px;
			}
		}
	}
`;

const Profile = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		border-right: none;
	}

	h3 {
		margin-left: 20px;
		font-weight: 300;
		font-size: 14px;

		@media screen and (max-width: 820px) {
			display: none;
		}
	}
`;

const Dp = styled.section`
	width: 35px;
	height: 35px;
	border-radius: 50%;
	overflow: hidden;

	@media screen and (max-width: 820px) {
		margin-left: 10px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;
