import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import logo from "../../assets/logo.png";
import Sidebar from "../Sidebar/Sidebar";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";

//styles
const Nav = styled.section`
	width: 100%;
	padding: 0 10rem;
	position: fixed;
	top: 0;
	background: #fff;
	z-index: 100;
	@media screen and (max-width: 1200px) {
		padding: 0 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 0 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 0 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 0 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 0 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 0 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 0 2rem;
	}
`;

const NavChild = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
`;
const Logo = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const MenuIcon = styled.div`
	display: none;
	position: relative;

	div {
		display: ${({ isopen }) => (isopen ? "none" : "flex")};
		height: 3px;
		background: #3b86ff;
		width: 80%;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		width: 40px;
		color: #0847a8;
		cursor: pointer;

		@media screen and (max-width: 340px) {
			height: 35px;
			width: 35px;
		}
	}

	&:before {
		content: "";
		position: absolute;
		width: 80%;
		height: 3px;
		background-color: #3b86ff;
		transform: ${({ isopen }) =>
			isopen ? "translateY(0px) rotate(45deg)" : "translateY(-10px)"};
		transition: 0.1s;
	}

	&:after {
		content: "";
		position: absolute;
		width: 80%;
		height: 3px;
		background-color: #3b86ff;
		transition: 0.1s;
		transform: ${({ isopen }) =>
			isopen ? "translateY(0px) rotate(-45deg)" : "translateY(10px)"};
	}
`;

const NavMenu = styled.div`
	display: flex;
	align-items: center;
	padding-top: 25px;

	p {
		padding-right: 12px;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 15px;
	color: #fff;
`;
const InfoDiv = styled.div`
	width: 100%;
	text-align: end;
	padding-top: 0.5rem;
	p {
		margin-right: 0
		color: #757575;
		font-size: 1rem;
		margin-bottom: 0;
	}
	div{
		.first, .second{
			margin-right: 0.6rem;
			p{
				margin-right: 0.5rem;
			}
		}
		.second, .third{
			margin-left: 0.6rem;
		}
	}
	@media only screen and (max-width: 480px) {
		font-size: 0.6rem;
		p {
			margin-right: 0;
			font-size: 0.6rem;
		}
		div{
			.first, .second{
				margin-right: 0.2rem;
				p{
					margin-right: 0;
				}
			}
			.second, .third{
				margin-left: 0.2rem;
			}
		}
	}
`;
const Navbar = () => {
	const [isopen, setisopen] = useState(false);

	const toggle = () => {
		setisopen(!isopen);
	};

	const user = localStorage.getItem("user-token");
	const admin = localStorage.getItem("admin-token");

	return (
		<>
			<Nav>
				<InfoDiv>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<div
							style={{
								display: "flex",

								alignItems: "center",
							}}
							className="first"
						>
							<AiOutlineMail
								style={{ marginTop: "0.2rem", marginRight: "0.2rem" }}
							/>
							<p> info@fullrangemfb.com </p>
						</div>
						|
						<div
							style={{
								display: "flex",

								alignItems: "center",
							}}
							className="second"
						>
							<AiOutlinePhone
								style={{
									marginTop: "0.2rem",
									marginRight: "0.2rem",
								}}
							/>{" "}
							<p> +234-818-559-1236 </p>
						</div>
						|
						<div style={{ display: "flex" }} className="third">
							<p>Contact Us</p>
						</div>
					</div>
				</InfoDiv>
				<NavChild>
					<Logo to="/">
						<img src={logo} alt="logo" style={{ height: "50px" }} />
					</Logo>

					<MenuIcon onClick={toggle} isopen={isopen}>
						{" "}
						<div />
					</MenuIcon>

					<NavMenu>
						{admin ? (
							<Button
								to="/admin-dashboard"
								style={{ background: "#3B86FF", marginRight: "20px" }}
							>
								Admin
							</Button>
						) : user ? (
							<Button
								to="/dashboard"
								style={{ background: "#3B86FF", marginRight: "20px" }}
							>
								Dashboard
							</Button>
						) : (
							<Button
								to="/login"
								style={{ background: "#3B86FF", marginRight: "20px" }}
							>
								Login
							</Button>
						)}

						<Button to="/loan" style={{ background: "#50CDF6" }}>
							Instant Loan
						</Button>
					</NavMenu>
				</NavChild>
			</Nav>
			<Sidebar isopen={isopen} toggle={toggle} />
		</>
	);
};

export default Navbar;
