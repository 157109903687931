import React, { useState, useEffect, forwardRef } from "react";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import man from "../../assets/man.png";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios"

function RequestForm(props, formRef) {
	const [loading, setLoading] = useState(false);
	// const [otpInput, setotpInput] = useState(false);
	const [error, seterror] = useState(null);
	const [click, setClick] = useState(true);
	
	// const showOtp = () => {
	// 	setotpInput(true);
	// };
	const userInfo = useSelector((state) => state.loan?.list[0]);

	useEffect(() => {
		if (userInfo?.status === "failure") {
			seterror(userInfo.message);
			console.log(error);
		}
	}, [userInfo, error]);

	const userData =  useSelector((state) => state?.users?.employeeInfo[0]?.user);

	const [phone_number, setPhoneNumber] = useState(userData?.phone_number || "");
	const [requested_amount, setAmount] = useState("");
	const [email, setEmail] = useState(userData?.email ||"");
	const history = useHistory()
	const handleSubmit = (e) => {
		e.preventDefault();
		setClick(false);
		const loanInit = "loan";
		// console.log(phone_number);
		setLoading(true);
		axios.post(`/user/loan-login`, {phone_number, requested_amount, email})
		.then((response) => {
			setLoading(false);
			console.log(response)
			localStorage.setItem("Init", loanInit);
			localStorage.setItem("user-loan", JSON.stringify(response?.data?.User));
			localStorage.setItem("amount",(response?.data?.requested_amount));
			history.push("/confirm-otp")
		})
		.catch((error) => {
			setLoading(false)
			// console.log(error.response.status)
			if(error?.response?.status === 400){
				toast.error(error.response.data.message)
			}
			else if(error?.response?.status === 401){
				toast.error(error.response.data.message)
			
			}else if(error?.response?.status === 402){
				toast.error(error.response.data.message)
			
			}else if(error?.response?.status === 500){
				toast.error(error.response.data.message)
			}
			// toast.error(error.message)
		})
		setClick(true)
	};

	useEffect(() => {
	  
	}, [error])
	

	return (
		<Container ref={formRef} {...props}>
			<h4 style={{ marginBottom: "15px", fontWeight: "bold" }}>
				FILL THE FORM TO REQUEST LOAN
			</h4>

			<Form>
				<form onSubmit={handleSubmit} id="loan-form">
					<InputBox>
						<div>
							<BsPhone />
						</div>
						<input
							type="number"
							placeholder="Phone"
							value={phone_number}
							onChange={(e) => setPhoneNumber(e.target.value)}
							className="first"
							required
						/>
					</InputBox>
					{error && (
						<Error>
							This phone number was not found. &nbsp;{" "}
							<a href="/"> Find Out Why</a>
						</Error>
					)}
					<InputBox>
						<div>
							<p>&#8358;</p>
						</div>
						<input
							type="number"
							placeholder="Loan Amount"
							onChange={(e) => setAmount(e.target.value)}
							className="second"
							required
						/>
					</InputBox>

					<InputBox>
						<div>
							<AiOutlineMail />
						</div>
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="third"
							required
						/>
					</InputBox>
					<Button
						type="submit"
						className={
							click
								? phone_number !== "" && requested_amount !== "" && email !== ""
									? "active"
									: null
								: "inactive"
						}
					>
						Take Loan
					</Button>
				</form>

				<Load loading={loading}>
					<ReactLoading type={"spin"} color="#000" />
				</Load>
			</Form>

			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="7000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
}

// export default RequestForm;

//styles
const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Container = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${man});
	background-size: cover;
	color: #fff;
	padding: 7rem 10rem 2rem;
	position: relative;

	@media screen and (max-width: 1200px) {
		padding: 2rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 2rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 2rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 2rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 2rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 2rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 2rem 2rem;
	}

	h4 {
		z-index: 1;
		font-weight: 200;
		text-align: center;
	}

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: #0872ba;
		opacity: 0.66;
	}
`;

const Form = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 0 2rem;
	z-index: 1;
	position: relative;
	form {
		width: 100%;
	}
	@media screen and (min-width: 780px) and (max-width: 1200px) {
		width: 60%;
	}
	@media screen and (max-width: 780px) {
		width: 60%;
		padding: 0 1rem;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
		padding: 0;
	}

	input {
		outline: none;
		border: 1px solid #a4afb7;
		border-left: none;
		background: #fff;
		font-size: 15px;
		height: 2.5rem;
		width: 100%;
		color: black;
		font-weight: 400;
		::-webkit-inner-spin-button {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
		}
		::-webkit-outer-spin-button {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
		}
		-moz-appearance: textfield;
	}
	.active {
		background-color: #01579b;
	}

	.inactive {
		background: #89c2d9;
		cursor: not-allowed;
	}
`;

const InputBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;

	margin-bottom: 1rem;
	div {
		background-color: #fff;
		color: #757575;
		height: 2.5rem;
		border: 1px solid #a4afb7;
		border-right: none;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.2rem;
	}
	p {
		margin-right: 0.2rem;
	}
`;
const Error = styled.div`
	justify-content: space-between;
	width: 100%;
	font-size: 13px;
	font-weight: 100;
	margin-bottom: 0.1rem;

	a {
		text-decoration: underline;
	}

	@media screen and (max-width: 400px) {
		font-size: 10px;
	}

	@media screen and (max-width: 320px) {
		font-size: 8px;
	}
`;

const btnstyle = css`
	outline: none;
	border: none;
	padding: 10px 20px;
	background: #89c2d9;
	width: 100%;
	color: #ffffff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 15px;
	font-size: 15px;
`;

const Button = styled.button`
	${btnstyle};
`;

const Proceed = styled(Link)`
	${btnstyle};
`;

export default forwardRef(RequestForm);
