import React from 'react';
import ReactDOM from 'react-dom';
import {store, persistor} from './store'
import {Provider} from 'react-redux'
import App from './App';
import axios from 'axios'
import { PersistGate } from 'redux-persist/integration/react'

axios.defaults.baseURL = 'https://fullrange-api.petrongsoftware.com/api/'
// axios.defaults.baseURL = 'http://localhost:8000/api/'

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
     </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

