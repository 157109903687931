import { useState, useEffect } from "react";
import {
	Body,
	Container,
	ContainerChild,
	Head,
	HeadRow,
	Loader,
	Row,
	Table,
} from "./Loantable";
import { Link, useParams } from "react-router-dom";
import { Pajination } from "../pagination/Pajination";
import NumberFormat from "react-number-format";
import ReactLoading from "react-loading";

const LoanTable = ({ userLoan, loading }) => {
	//pagination
	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 5;
	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;

		setCurrentItems(userLoan?.slice(itemOffset, endOffset));
	}, [itemOffset, itemsPerPage, userLoan]);

	const { user } = useParams();

	return (
		<Container>
			<ContainerChild>
				<Table>
					<HeadRow>
						<Head>S/N</Head>
						<Head>Date</Head>
						<Head style={{marginRight: "1rem"}}>Loan Amount</Head>
					</HeadRow>
					{currentItems?.length > 0 ?
						currentItems.map((loan, index) => {
							const { id, created_at, requested_amount } = loan;

							const date = created_at.split(" ");
							return (
								<Link
									to={`/admin-dashboard/customers/loanInfo/${user}/${id}`}
									key={id}
								>
									<Row>
										<Body>{index + 1}</Body>
										<Body>{date[0]}</Body>
										<Body><NumberFormat
                                value=	{requested_amount}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></Body>
									</Row>
								</Link>
							);
						}) : (<h3 style={{textAlign: "center", marginTop: "5rem"}}>NO loan</h3>)}
						 <Loader
                    style={{ display: loading ? "block" : "none" }}
                    loading={loading}
                  >
                    <ReactLoading type={"spin"} color="#000" />
                  </Loader>
				</Table>

				<Pajination
					itemsPerPage={itemsPerPage}
					items={userLoan}
					setItemOffset={setItemOffset}
				/>
			</ContainerChild>
		</Container>
	);
};
export default LoanTable;
