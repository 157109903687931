import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

//components
import DashboardTopbar from "../../components/DashboardTopbars/Client";

//chart images
import requested from "../../assets/requested.png";
import repaid from "../../assets/repaid.png";
import outstanding from "../../assets/outstanding.png";

import { FetchAccountBalance } from "../../actions/takeLoan";
import NumberFormat from "react-number-format";

const CustomerDashboard = () => {
	const history = useHistory();
	const [balance, setBalance] = useState(0)

	const { id } = JSON.parse(localStorage.getItem("user-info"));
console.log(id)
 const token = localStorage.getItem("user-token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
useEffect(() => {
		console.log(id)
		axios.get(`/user/accountBalance/${id}`, config)
		.then((response) => {
			// console.log(response)
			setBalance(response.data?.totalamountdisbursed)
		})
		.catch((error) => {
			console.log({error})
			history.push("./")
		})
	 
	}, []);
	const totalamountdisbursed = useSelector(
		(state) => state.loan?.accountBalance
	);
	let data = totalamountdisbursed;
console.log(data)

	useEffect(() => {
	 
	}, [balance])
	
	
	useEffect(() => {
		console.log(totalamountdisbursed);
		const user = localStorage.getItem("user-token");
		if (!user || user === "undefined") {
			history.push("./login");
		}
	}, [totalamountdisbursed, history]);

	return (
		<Container className="dashboard">
			<Contents>
				<DashboardTopbar />

				<Board>
					<Info>
						<h2>Customer Dashboard</h2>
					</Info>

					<Info>
						<div>
							<h5>Total Amount of Loan Requested</h5>

							<PrCh>
								<h3>
									<NumberFormat
                                value=	{balance}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
								</h3>

								<Ch>
									<img src={requested} alt="chart" />
								</Ch>
							</PrCh>
						</div>

						<div>
							<Link to="/dashboard/transactions">
								<h5>Amount Repaid</h5>

								<PrCh>
									<h3>
                              <h3>
									<NumberFormat
                                value=	{0}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
								</h3>
								</h3>

									<Ch>
										<img src={repaid} alt="chart" />
									</Ch>
								</PrCh>
							</Link>
						</div>

						<div>
							<h5>Outstanding Repayments</h5>

							<PrCh>
							<h3>
									<NumberFormat
                                value=	{balance}
                                prefix="&#8358;"
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
								</h3>

								<Ch>
									<img src={outstanding} alt="chart" />
								</Ch>
							</PrCh>
						</div>
					</Info>

					<Btns>
						<Button to="/dashboard/history" style={{ background: "#43425D" }}>
							View History
						</Button>
						<Button to="/loan" style={{ background: "#3B86FF" }}>
							Request Loan
						</Button>
					</Btns>
				</Board>
			</Contents>
		</Container>
	);
};

export default CustomerDashboard;

//styles
const Container = styled.section`
	display: flex;
	width: 100%;
	height: 100vh;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	width: 100%;
	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		font-weight: 500;

		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	width: 90%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-top: 2rem;

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}

	div {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		background: #fff;

		h5 {
			font-size: 14px;
			font-weight: 300;
			color: #ccc;
			height: 50px;
		}
	}
`;

const PrCh = styled.section`
	display: flex;
	justify-content: space-between;

	h3 {
		color: #43425d;
	}
`;

const btnstyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.6rem 1rem;
	border-radius: 3px;
	cursor: pointer;
	font-size: 12px;
	color: #f5f5f5;
	background: transparent;
	margin-right: 15px;
	font-size: 15px;

	@media screen and (max-width: 310px) {
		margin-bottom: 15px;
		margin-right: 0;
	}
`;

const Btns = styled.div`
	display: flex;
	width: 90%;
	margin: 2rem auto;
	@media screen and (max-width: 310px) {
		flex-direction: column;
	}
`;

const Button = styled(Link)`
	${btnstyle};
`;

const Ch = styled.section`
	display: flex;
	width: 100px;

	img {
		height: 40px;
		width: 100%;
	}
`;
