import React from "react";
import styled, { css } from "styled-components/macro";
import bg from "../../assets/rodna.jpg";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${bg});
	background-size: cover;
	height: calc(100vh - 6rem);
	margin-top: 4rem;
	position: relative;
	padding: 0 15rem;

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		z-index: 3;
		color: #ffffff;

		h1 {
			font-size: 50px;

			@media screen and (max-width: 675px) {
				font-size: 40px;
			}

			@media screen and (max-width: 350px) {
				font-size: 30px;
			}
		}

		p {
			font-size: 1.4rem;
			font-weight: 500;
			margin-top: 1rem;

			@media screen and (max-width: 675px) {
				font-size: 18px;
			}
		}
	}

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to right, #0872ba, #f79421);
		opacity: 0.36;
	}

	@media screen and (max-width: 1200px) {
		padding: 0 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 0 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 0 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 0 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 0 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 0 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 0 2rem;
	}
`;

const Banner = () => {
	return (
		<Container>
			<div>
				<h1>We can loan you your Salary in Advance.</h1>
				{/* <p>
					Now discover a whole new online account, built to give you more
					control over your card and your time.. Log in from anywhere with a
					design optimized for any device. Manage your account your way with all
					the features you enjoyed before—and more.
				</p> */}
			</div>
		</Container>
	);
};

export default Banner;
