import axios from 'axios'
import {adminAuthHeader} from '../api/authToken'
const API = axios.create({baseURL: `https://fullrange-api.petrongsoftware.com/api/admin`});
// const API = axios.create({baseURL: `http://localhost:8000/api/admin`});

export const getAdmins = () => API.get(`/getall`, { headers: adminAuthHeader() })
// const instance=axios.create({
//     baseURL:'www.google.com/'
//     headers:{
//         'Content-Type':'application/json',
//                     'Acess-Control-Allow-Origin':'*',
//                     'Authorization':`Bearer ${token}`,
//                     'Accept': "application/json"
//         }
//     })
// export const createAdmins = () => API.post(`/create`, { 
//     headers: 
//     {
//         {adminAuthHeader()},
//         {'Content-Type' : 'application/json'},
//         {'Accept' : 'application/json'}
//     }
// })
export const deleteAdmins = (id) => API.get(`/delete/${id}`, { headers: adminAuthHeader() })
export const getOneAdmin = (id) => API.get(`/getone/${id}`, { headers: adminAuthHeader() })
export const updateAdmins = (id) => API.get(`/update/${id}`, { headers: adminAuthHeader() })
export const editAdmins = () => API.get(`/edit`, { headers: adminAuthHeader() })
export const loanStats = () => API.get(`/adminloanStatistics`, { headers: adminAuthHeader() })
