import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";

//components
import Topbar from "../../components/DashboardTopbars/Admin";

const EditAdmin = () => {
	const handleSubmit = async (event) => {
		event.preventDefault();
		var data = {
			username,
			password,
		};

		// const res = await axios.patch('https://httpbin.org/patch', { data });

		await fetch("admin/edit", {
			method: "PATCH",
			headers: {
				Accept: "application/form-data",
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		});
		history.push("/admin-dashboard/show");
	};

	const history = useHistory();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const token = localStorage.getItem("admin-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	// const postData = (e) => {
	//     e.preventDefault()
	//     axios.patch(`admin/edit`, config, {
	//         username,
	//         password,
	//     }).then(() => {
	//         history.push("/admin-dashboard/show")
	//     }).catch(e => {
	//         console.log(e);
	//       });
	// }

	useEffect(() => {
		if (!localStorage.getItem("super-admin-token")) {
			history.push("/admin-dashboard/login");
		}
	}, []);

	const logOut = () => {
		localStorage.removeItem("super-admin-token");
		history.push("/admin-dashboard/login");
	};

	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Super Admin</h2>

					<Info className="info">
						<Exit onClick={logOut}>Exit Super Admin</Exit>
						<Btns>
							<Button className="btn active-btn">Edit Login</Button>
							<Button to="/admin-dashboard/show">Administrators</Button>
							<Button to="/admin-dashboard/log">Change Log</Button>
							<Button to="/admin-charges/show">Charges</Button>
						</Btns>

						<div>
							<Edit>
								<h4>Edit Super Admin Login</h4>

								<form onSubmit={handleSubmit}>
									<input
										type="text"
										placeholder="USERNAME"
										onChange={(e) => setUsername(e.target.value)}
									/>
									<input
										type="password"
										placeholder="PASSWORD"
										onChange={(e) => setPassword(e.target.value)}
									/>

									<button type="submit">Update</button>
								</form>
							</Edit>
						</div>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default EditAdmin;

const Container = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem 0;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;
	overflow: hidden;
	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: calc(100vh - 60px);
	padding: 2rem 2rem 2rem 5rem;
	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 200;
		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	padding: 10px;
	background: #fff;
	position: relative;
	@media screen and (max-width: 860px) {
		padding-top: 4rem;
	}
	div {
		display: flex;
	}
`;
const Exit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	font-size: 12px;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	background: #43425d;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 120px 150px 120px;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;
	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Space = styled.div`
	padding: 0.5rem;
	width: 150px;
	border-right: 1px solid #e9e9f0;
	@media screen and (max-width: 750px) {
		display: none;
	}
`;

const Back = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: #3b86ff;
	color: #fff;
	cursor: pointer;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	width: 100%;
	@media screen and (max-width: 750px) {
		/* font-size: 11px; */
	}
`;

const Form = styled.section`
	width: 700px;
	padding: 2rem 0 3rem 2rem;
	position: relative;
	@media screen and (max-width: 1200px) {
		padding: 2rem 1rem 3rem 1rem;
		width: 100%;
	}
	form {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		input {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #808495;
			font-weight: 100;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}
		div {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 400px) {
				flex-direction: column;
			}
		}
	}
`;

const Edit = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	width: 70%;
	@media screen and (max-width: 1000px) {
		padding: 2rem 1rem;
	}
	h4 {
		font-weight: 200;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin: 20px 5rem 0 10rem;
		@media screen and (max-width: 1000px) {
			margin: 20px 2rem 0 2rem;
		}

		@media screen and (max-width: 1000px) {
			margin: 20px 0 0 0;
		}
		input {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			font-size: 15px;
			color: #808495;
			font-weight: 100;
			width: 100%;
			border: 1px solid #e9e9f0;
			outline: none;
			margin-bottom: 15px;
		}

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px;
			font-size: 13px;
			background: #3b86ff;
			border-radius: 3px;
			color: #fff;
			width: 150px;
			margin-top: 30px;
			cursor: pointer;
			outline: none;
			border: none;
		}
	}
`;
