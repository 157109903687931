import * as actionType from "../constant/actionType";

const initialState = {
	biodata: [],
	employeeInfo: [],
	accountInfo: [],
};

export const users = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionType.FETCHBIODATA:
			return {
				...state,
				biodata: payload,
			};
		case actionType.REGISTERUSER:
			return {
				...state,
				accountInfo: payload,
			};
		case actionType.LOGINUSER:
			console.log(payload);
			return {
				...state,
				employeeInfo: payload,
			};

		default:
			return state;
	}
};
