import { Route, Redirect } from "react-router-dom";

export const UserLoginRoute = ({ component: Component, ...rest }) => {
	const userToken = localStorage.getItem("user-token");

	const adminToken = localStorage.getItem("admin-token");
	const isLoggedIn = userToken;

	const toast = "load toast";
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!adminToken) {
					if (!isLoggedIn) {
						return <Component {...props} />;
					} else {
						// localStorage.setItem("toast", toast);
						return (
							<Redirect
								to={{
									pathname: "/dashboard",
									state: {
										from: props.location,
									},
								}}
							/>
						);
					}
				} else {
					return (
						<Redirect
							to={{
								pathname: "/admin-dashboard",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};
