import * as actionType from '../constant/actionType';

const initialState = {
    list: [],
}


export const charges = (state=initialState, action) => {

    const {type, payload} = action

    switch (type) {
        case actionType.FETCHCHARGES: 
            return {
                ...state,
                list: [...payload]
            }
        // case actionType.TAKELOAN: 
        //     return {
        //         ...state,
        //         list: [...state.list, {...payload}]
        //     }
        // case actionType.LOANDETAILS: 
        //     return {
        //         ...state,
        //         loanDetails: payload
        //     }
        // case ACTION_TYPES.UPDATE: 
        //     return {
        //         ...state,
        //         list: state.list.map(x => x._id === action.payload._id ? action.payload : x)
        //     }
        // case ACTION_TYPES.DELETE: 
        //     return {
        //         ...state,
        //         list: state.list.filter(x => x._id !== action.payload)
        //     }
        default:
            return state;
    }
}