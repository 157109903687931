import React from "react";
import styled, { css } from "styled-components/macro";
import calc from "../../assets/calculator.svg";
import profits from "../../assets/profits.svg";
import investment from "../../assets/investment.svg";
import portfolio from "../../assets/portfolio.svg";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 4rem 10rem;
	text-align: center;
	p {
		font-weight: 550;
		color: #757575;
	}
	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 30px;

	@media screen and (max-width: 1090px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 533px) {
		grid-template-columns: 1fr;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	div {
		min-height: 5rem;
	}
	h5 {
		color: #143763;
		font-size: 15px;
		margin-bottom: 30px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			margin-left: 50%;
			transform: translateX(-50%);
			height: 2px;
			width: 40px;
			background: #f79421;
		}
	}

	p {
		font-size: 13px;
	}
`;

const Svg = styled.div`
	width: 40px;
	height: 40px;
	margin-bottom: 15px;

	img {
		width: 100%;
		height: 100%;
	}
`;

const Services = () => {
	return (
		<Container>
			<h2 style={{ marginBottom: "20px" }}>Why Fullrange Loan</h2>
			{/* <p style={{ marginBottom: "60px" }}>
				We provide expert tax and advisory services to individuals and small
				businesses.
			</p> */}

			<Grid>
				<Item>
					{/* <Svg>
						<img src={calc} alt="calculator" />
					</Svg> */}

					<h5>Fullrange Salary Advance</h5>

					<div>
						<p>
							Our qualified customers can access their salary in advance before
							their employers pay them.
						</p>
					</div>
				</Item>

				<Item>
					{/* <Svg>
						<img src={profits} alt="calculator" />
					</Svg> */}

					<h5>Instant Disbursement</h5>

					<div>
						<p>Disbursement is done immediately customer requests for loan.</p>
					</div>
				</Item>

				<Item>
					{/* <Svg>
						<img src={investment} alt="calculator" />
					</Svg> */}

					<h5>No documentation.</h5>

					<div>
						<p>There are No Documentation or collateral requirements</p>
					</div>
				</Item>

				<Item>
					{/* <Svg>
						<img src={portfolio} alt="calculator" />
					</Svg> */}

					<h5>Easy access to loan.</h5>

					<div>
						<p>
							Our customers are assured of a speedy, efficient and easy access
							to funds.
						</p>
					</div>
				</Item>
			</Grid>
		</Container>
	);
};

export default Services;
