import * as actionType from "../constant/actionType";

const initialState = {
	list: [],
	loanDetails: [],
	accountBalance: [],
	loanHistory: [],
	loanHistories: [],
	loading: true,
};

export const loan = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionType.ACCOUNTBALANCE:
			console.log(payload);
			return {
				...state,
				accountBalance: payload.totalamountdisbursed,
				// accountBalance: payload
			};
		case actionType.LOANHISTORY:
			console.log(payload);
			return {
				...state,
				loanHistory: [payload].sort(function (a, b) {
					return new Date(b.created_at) - new Date(a.created_at);
				}),
				// loanHistory: payload
			};
		case actionType.LOANHISTORIES:
			return {
				...state,
				// loanHistories: payload
				loanHistories: payload,
			};
		case actionType.TAKELOAN:
			let user = payload.User;
			localStorage.setItem("user-info", JSON.stringify(user));
			return {
				...state,
				list: [...state.list, { ...payload }],
			};
		case actionType.CONFIRMLOAN:
			return {
				...state,
				list: [...state.list, { ...payload }],
			};
		case actionType.LOANDETAILS:
			return {
				...state,
				loanDetails: payload,
				// loanDetails: payload
			};
		default:
			return state;
	}
};
