import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

//svgs
import { ReactComponent as Srch } from "../../assets/icons/search.svg";
import { ReactComponent as View } from "../../assets/icons/search.svg";
import ReactLoading from "react-loading";
import { FetchLoans } from "../../actions/takeLoan";
import Select from "react-select";
//components
import DashboardTopbar from "../../components/DashboardTopbars/Client";
import { Pajination } from "../../components/pagination/Pajination";

const CustomerTransaction = () => {
	const [dayArray, setdayArray] = useState([]);
	const [yearArray, setyearArray] = useState([]);
	const [loading, setLoading] = useState(true);
	const initialValue = {
		day: "",
		month: "",
		year: "",
	};
	// const initialData = {
	// 	search: "",
	// };
	const [reportInfo, setreportInfo] = useState(initialValue);
	const [loanArray, setloanArray] = useState(null);
	const [search, setsearch] = useState("");
	const userInfo = JSON.parse(localStorage.getItem("user-info"));
	let user_id = userInfo?.id;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(FetchLoans(user_id));
	}, [dispatch]);
	const token = localStorage.getItem("user-token");
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const retrieveLoans = async () => {
		await axios
			.get(`user/loans/${user_id}`, config)
			.then((response) => {
				console.log(response);
				setLoading(false);
				setloanArray(response.data.LoanHistories);
			})
			.catch((error) => console.log(error));
	};
	useEffect(() => {
		retrieveLoans();
	}, []);
	// const employerOptions = employerArray.map((employer, index) => {
	// 	return {
	// 		label: employer,
	// 		value: employer,
	// 		key: index,
	// 	};
	// });

	const monthOptions = [
		{ value: 1, label: "January" },
		{ value: 2, label: "February" },
		{ value: 3, label: "March" },
		{ value: 4, label: "April" },
		{ value: 5, label: "May" },
		{ value: 6, label: "June" },
		{ value: 7, label: "July" },
		{ value: 8, label: "August" },
		{ value: 9, label: "September" },
		{ value: 10, label: "October" },
		{ value: 11, label: "November" },
		{ value: 12, label: "December" },
	];

	const yearOptions = yearArray.map((year, index) => {
		return {
			label: year,
			value: year,
			key: index,
		};
	});

	useEffect(() => {
		let employers = [];
		let dates = [];
		let months = [];

		for (let i = 0; i < loanArray?.length; i++) {
			let employer = loanArray[i]?.company_name;
			let date = loanArray[i].created_at;
			let year = new Date(date).getFullYear();
			let month = new Date("2021-01-15").getMonth();
			let newMonth = month + 1;

			months.push(newMonth);
			if (!employers.includes(employer)) {
				employers.push(employer);
			}
			if (!dates.includes(year)) {
				dates.push(year);
			}

			// setemployerArray(employers);

			setyearArray(dates);
		}
	}, [loanArray]);
	const handleDayChange = (value) => {
		setreportInfo({
			...reportInfo,
			day: value.value,
		});
	};
	const handleMonthChange = (value) => {
		setreportInfo({
			...reportInfo,
			month: value.value,
		});
	};
	const handleYearChange = (value) => {
		setreportInfo({
			...reportInfo,
			year: value.value,
		});
		// console.log(reportInfo);
	};
	const handleInputChange = (e) => {
		setsearch(e.target.value);
		console.log(search);
	};

	//new pagination

	const [currentItems, setCurrentItems] = useState(null);
	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 15;
	useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;

		setCurrentItems(loanArray?.slice(itemOffset, endOffset));
	}, [itemOffset, itemsPerPage, loanArray]);

	useEffect(() => {
		const getDaysInMonth = (selectMonth, selectYear) => {
			//
			// console.log(reportInfo?.month);
			// console.log(reportInfo?.year);
			return new Date(selectYear, selectMonth, 0).getDate();
		};
		const { month, year } = reportInfo;
		const selectMonth = reportInfo?.month;
		const selectYear = reportInfo?.year;
		const dateNumber = getDaysInMonth(selectMonth, selectYear);
		// console.log(dateNumber);

		const newDayArray = [];
		for (let i = 1; i <= dateNumber; i++) {
			newDayArray.push(i);
		}
		setdayArray(newDayArray);
	}, [reportInfo]);

	useEffect(() => {}, [dayArray]);
	const dayOptions = dayArray.map((day, index) => {
		return {
			label: day,
			value: day,
			key: index,
		};
	});
	//SEARCH
	const handleSearch = async () => {
		if (search !== "") {
			console.log("not empty");
			await axios
				.get(`search`, search)
				.then((response) => {
					console.log(response);
				})
				.catch((error) => console.log(error));
		} else {
			console.log("empty");
		}
	};
	return (
		<Container>
			<Contents>
				<DashboardTopbar />

				<Board>
					<HeadingSearch>
						<h2>Customers</h2>

						<Search>
							<button onClick={handleSearch}>
								<Srch />{" "}
							</button>
							<input
								type="text"
								placeholder="Search..."
								value={search}
								name="search"
								onChange={handleInputChange}
								// onInput={handleSearch}
							/>
						</Search>
					</HeadingSearch>
					<Info>
						<Space />

						<Details>
							<div>
								<Report>
									<TextBox>
										<h3>Transactions</h3>
									</TextBox>
									<SelectBox>
										<Box>
											<p>Year</p>
											<Select
												placeholder="Year"
												options={yearOptions}
												onChange={handleYearChange}
												name="year"
											/>
										</Box>
										<Box>
											<p>Month</p>
											<Select
												placeholder="Month"
												options={monthOptions}
												onChange={handleMonthChange}
												name="month"
											/>
										</Box>

										<Box>
											<p>Day</p>
											<Select
												placeholder="Day"
												options={dayOptions}
												onChange={handleDayChange}
												name="day"
											/>
										</Box>
									</SelectBox>
									<button onClick={() => console.log(reportInfo)}>
										Generate Report
									</button>
								</Report>
							</div>

							<div style={{ overflowX: "scroll" }}>
								<Table>
									<tr>
										<th>#</th>
										<th>DATE</th>
										<th>LOAN AMOUNT</th>
										<th>AMOUNT PAID</th>
										<th>PAYMENT CHANNEL</th>
									</tr>{" "}
									{/* <Loader
										style={{ display: loading ? "block" : "none" }}
										loading={loading}
									>
										<ReactLoading type={"spin"} color="#000" />
									</Loader> */}
								</Table>
							</div>
							{/* {loanArray?.length > 10 ? (
								<Pajination
									itemsPerPage={itemsPerPage}
									items={loanArray}
									setItemOffset={setItemOffset}
								/>
							) : null} */}
						</Details>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default CustomerTransaction;

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;
const TextBox = styled.div`
	width: 15%;
	h3 {
		color: #616161;
	}
`;
const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 2rem 5rem;
	height: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}
`;

const HeadingSearch = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	@media screen and (max-width: 920px) {
		flex-direction: column;
	}

	h2 {
		font-weight: 200;

		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Search = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 20px;
	width: 400px;
	background: #fff;

	button {
		width: 8%;
		border-radius: 50%;
		padding: 0.3rem 0.2rem;
		border: none;
		padding: none;
	}
	@media screen and (max-width: 920px) {
		width: 100%;
	}

	input {
		width: 87%;
		outline: none;
		border: none;
	}
`;

const Info = styled.div`
	display: flex;
	background: #fff;
	min-height: 70vh;
`;

const Space = styled.div`
	width: 0px;
	display: none;
	border-right: 1px solid #e9e9f0;

	@media screen and (max-width: 1180px) {
		display: none;
	}
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
`;

const Report = styled.div`
	display: flex;
	align-items: center;
	@media screen and (max-width: 930px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
	}

	@media screen and (max-width: 670px) {
		grid-template-columns: 1fr;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		background: #3b86ff;
		border-radius: 3px;
		outline: none;
		border: none;
		padding: 10px 12px;
		cursor: pointer;
		font-size: 15px;
		@media only screen and (max-width: 480px) {
			margin-bottom: 0.5rem;
		}
	}
`;

const SelectBox = styled.div`
	width: 70%;
	display: flex;
	justify-content: space-around;
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;

const Box = styled.div`
	width: 32%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px 12px;
	font-size: 15px;
	color: #808495;
	font-weight: 100;
	.css-b62m3t-container {
		width: 100%;
	}
	p {
		display: none;
	}

	@media screen and (max-width: 930px) {
		margin-right: 0;
	}
	@media only screen and (max-width: 480px) {
		padding: 0;
		p {
			display: block;
			margin-bottom: 0.2rem;
		}
	}
`;

const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;

	@media screen and (max-width: 1090px) {
		width: 800px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: center;
		font-size: 13px;
		border-bottom: 1px solid #e9e9f0;
	}
`;

const Pages = styled.div`
	display: flex;
	justify-content: end;

	&:last-child {
		margin-right: 0;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;

const Loader = styled.div`
	/* display: ${({ loading }) => (loading ? "flex" : "none")}; */
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
