import * as actionType from '../constant/actionType';

const initialState = {
    repayment: [],
}


export const repayment = (state=initialState, action) => {

    const {type, payload} = action

    switch (type) {
        case actionType.FETCHREPAYMENT: 
            return {
                ...state,
                repayment: [{...payload}]
                // repayment: payload
            }
       
        default:
            return state;
    }
}