import { useEffect, useState } from "react";
import styled from "styled-components";
import DashboardTopbar from "../../components/DashboardTopbars/Client";
import { useSelector } from "react-redux";

//svgs
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 1rem 2rem 2rem 5rem;
	overflow-x: hidden;
	overflow-y: scroll;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 10px;
		font-weight: 200;
	}
`;
const Infos = styled.div`
	width: 80%;
	margin: 2rem auto;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	min-height: 70vh;
	padding: 2rem 0;
	border-radius: 5px;
	@media only screen and (max-width: 768px) {
		width: 95%;
		margin: 2rem auto;
	}
	@media only screen and (max-width: 480px) {
		width: 100%;
		margin: 2rem auto;
	}
`;
const InputBox = styled.div`
	width: 90%;
	margin: 1.2rem auto;
	display: flex;
	align-items: center;
	@media only screen and (max-width: 480px) {
		width: 100%;
	}
`;
const Input = styled.div`
	width: 95%;
	margin: 0 auto;
	input {
		width: 100%;
		height: 2.5rem;
		padding: 0.5rem;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		&:focus {
			outline: 1px solid #757575;
		}
	}
`;
const ButtonBox = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	width: 150px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
cursor: pointer;
	outline: none;
	border: none;
	color: #fff;
`;
export const CustomerProfileEdit = () => {
	const userData = useSelector((state) => state.users.biodata);
	const initialData = {
		email: userData.email,
		company_name: userData.company_name,
		address: "",
	};
	// console.log(userData);
	const [userInfo, setUserInfo] = useState(initialData);

	
	const handleChange = (e) => {
		setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(userInfo);
	};
	return (
		<Container>
			<Contents>
				<DashboardTopbar />
				<Board>
					<h2>Edit Profile Information</h2>
					<Infos>
					
						<InputBox>
							<Input>
								<label htmlFor="">Email</label>
								<input
									type="text"
									name="email"
									id=""
									disabled
									value={userInfo.email}
									onChange={handleChange}
								/>
							</Input>
						
						</InputBox>
						<InputBox>
							<Input>
								<label htmlFor="">Address</label>
								<input
									type="text"
									name="address"
									id=""
									value={userInfo.address}
									onChange={handleChange}
								/>
							</Input>
						
						</InputBox>

						<InputBox>
							<Input>
								<label htmlFor="">Employer Information</label>
								<input
									type="text"
									name="company_name"
									id=""
									value={userInfo.company_name}
									onChange={handleChange}
								/>
							</Input>
						
						</InputBox>
						<ButtonBox>
							<Button onClick={handleSubmit}>Update</Button>
						</ButtonBox>
					</Infos>
				</Board>
			</Contents>
		</Container>
	);
};
