import React, { useEffect } from 'react'

//components
import Navbar from '../components/Navbar/Navbar'
import Banner from '../components/Banner/LoanBanner'
import SuccessMessage from '../components/CustomerInformation/Success'
import Footer from '../components/Footer/Footer'
  
const Success = () => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'auto'})
    }, []);

    return (
        <>
            <Navbar /> 
            <Banner /> 
            <SuccessMessage />
            <Footer />
        </>
    )
}

export default Success
