import React, { useEffect } from 'react'

//components
import Navbar from '../components/Navbar/Navbar'
import Banner from '../components/Banner/LoanBanner'
import Signup from '../components/LoginRegister/Signup'
import Footer from '../components/Footer/Footer'

const CustomerSignup = () => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'auto'})
    }, []);
    
    return (
        <>
            <Navbar /> 
            <Banner /> 
            <Signup />
            <Footer />
        </>
    )
}

export default CustomerSignup
