import * as actionType from '../constant/actionType';

const initialState = {
    admins: [],
    loanStat: []
}


export const admin = (state=initialState, action) => {

    const {type, payload} = action

    switch (type) {
        case actionType.GETADMINS: 
            return {
                ...state,
                admins: [...payload]
            }
        case actionType.GETLOANS: 
            return {
                ...state,
                loanStat: [{...payload}]
            }
        // case actionType.TAKELOAN: 
        //     return {
        //         ...state,
        //         list: [...state.list, {...payload}]
        //     }
        // case actionType.LOANDETAILS: 
        //     return {
        //         ...state,
        //         loanDetails: payload
        //     }
        // case ACTION_TYPES.UPDATE: 
        //     return {
        //         ...state,
        //         list: state.list.map(x => x._id === action.payload._id ? action.payload : x)
        //     }
        // case ACTION_TYPES.DELETE: 
        //     return {
        //         ...state,
        //         list: state.list.filter(x => x._id !== action.payload)
        //     }
        default:
            return state;
    }
}