import React from "react";
import styled, { css } from "styled-components/macro";
import numbers from "../../assets/numbers.png";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${numbers});
	background-size: cover;
	color: #bdbdbd;
	text-align: center;
	padding: 4rem 10rem;

	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}

	h2 {
		margin-bottom: 60px;
		font-weight: 200;
		width: 70%;

		@media screen and (max-width: 840px) {
			width: 100%;
		}
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	width: 100%;

	@media screen and (max-width: 822px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;
	}

	@media screen and (max-width: 410px) {
		grid-template-columns: 1fr;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	color: #bdbdbd;

	h1 {
		font-size: 40px;
		font-weight: 400;
		margin-bottom: 20px;
	}

	p {
		font-size: 16px;
		font-weight: 200;
	}
`;

const Numbers = () => {
	return (
		<Container>
			<h2>
				We’re Always Ahead. Fully Packed Loan Services For You & Your Business.
			</h2>

			<Grid>
				<Item>
					<h1>80</h1>

					<p>Customers Served</p>
				</Item>

				<Item>
					<h1>35</h1>

					<p>National</p>
				</Item>

				<Item>
					<h1>45</h1>

					<p>International</p>
				</Item>

				<Item>
					<h1>56</h1>

					<p>International</p>
				</Item>
			</Grid>
		</Container>
	);
};

export default Numbers;
