import React from 'react'
import styled, { css } from 'styled-components/macro'
import customers from '../../assets/customers.png'

//styles
const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 4rem 10rem;

    @media screen and (max-width: 1200px) {
        padding: 4rem 8rem;
    }
    @media screen and (max-width: 1000px) {
        padding: 4rem 7rem;
    }

    @media screen and (max-width: 920px) {
        padding: 4rem 6rem;
    }
    @media screen and (max-width: 768px) {
        padding: 4rem 5rem;
    }
    @media screen and (max-width: 600px) {
        padding: 4rem 4rem;
    }
    @media screen and (max-width: 500px) {
        padding: 4rem 3rem;
    }

    @media screen and (max-width: 450px) {
        padding: 4rem 2rem;
    }

    h2 {
        color: #0872BA;
    }
`

const Logos = styled.div`
    display: flex;
`

const Customers = () => {
    return (
        <Container>
            <h2 style={{color: '#0872BA', textAlign: 'center', marginBottom: '40px'}}>Some of Our Valued Customers</h2>

            <Logos>
                <img src={customers} alt='customers' style={{width: '100%',}} />
            </Logos>
        </Container>
    )
}

export default Customers
