import React from 'react'
import styled, { css } from 'styled-components/macro'
import bg from '../../assets/bg.png'

//styles
const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${bg});
    background-size: cover;
    height: 250px;
    margin-top: 75px;
    position: relative;
    padding: 0 10rem;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 3;
        color: #fff;

        h1 {
            font-size: 50px;

            @media screen and (max-width: 675px) {
                font-size: 40px;
            }
            
            @media screen and (max-width: 350px) {
                font-size: 30px;
            }
        }
    
        p {
            font-size: 20px;
            font-weight: 500;

            @media screen and (max-width: 675px) {
                font-size: 17px;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, #0872BA, #F79421);
        opacity: .36;
    }

    @media screen and (max-width: 1200px) {
        padding: 0 8rem;
    }
    @media screen and (max-width: 1000px) {
        padding: 0 7rem;
    }

    @media screen and (max-width: 920px) {
        padding: 0 6rem;
    }
    @media screen and (max-width: 768px) {
        padding: 0 5rem;
    }
    @media screen and (max-width: 600px) {
        padding: 0 4rem;
    }
    @media screen and (max-width: 500px) {
        padding: 0 3rem;
    }

    @media screen and (max-width: 450px) {
        padding: 0 2rem;
    }
`

const Banner = () => {
    return (
        <Container>
            <div>
                <h1>Get a Loan in 5minutes </h1>
                <p>Do you want to pay your school fees or your children school fees?</p>
            </div>
        </Container>
    )
}

export default Banner