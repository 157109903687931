import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import ReactLoading from "react-loading";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import NumberFormat from "react-number-format";

const ChargesTable = ({ showEdit, formData, onDelete, loading }) => {
	console.log(formData);
	return (
		<>
			<Table>
				<tr>
					<th>#</th>
					<th>TITLE</th>
					<th>AMOUNT</th>
					<th>CHARGE TYPE</th>
					<th>DATE CREATED</th>
					<th>ACTIONS</th>
				</tr>

				{formData &&
					formData.map((data) => {
						let date = data.created_at.split("T");
						return (
							<tr key={data.id}>
								<td>{data.id}</td>
								<td>{data.name}</td>
								<td><NumberFormat
                                value=	{data.amount}
                               suffix={data.charge_type === "fixed" ? "" : "%"}
							   prefix={data.charge_type === "fixed" ? "&#8358;" : ""}
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></td>
								<td>{data.charge_type}</td>
								<td>{date[0]}</td>
								<td>
									<Link to={`/admin-charges/update/${data.id}`}>
										<button style={{ marginRight: "10px" }}>
											{" "}
											<Edit />{" "}
										</button>{" "}
									</Link>
									<button onClick={() => onDelete(data.id)}>
										<Delete />{" "}
									</button>
								</td>
							</tr>
						);
					})}

				<Loader
					style={{ display: loading ? "block" : "none" }}
					loading={loading}
				>
					<ReactLoading type={"spin"} color="#000" />
				</Loader>
			</Table>
		</>
	);
};

export default ChargesTable;

//styles
const Table = styled.table`
	border-collapse: collapse;
	/* margin-bottom: 15px; */
	width: 100%;

	@media screen and (max-width: 1300px) {
		width: 900px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		border: none;
		text-align: left;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: left;
		font-size: 13px;
		border-bottom: 1px solid #e9e9f0;

		button {
			outline: none;
			border: none;
			background: transparent;
			cursor: pointer;
		}
	}
`;

const Loader = styled.div`
	/* display: ${({ loading }) => (loading ? "flex" : "none")}; */
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
