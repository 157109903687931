import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import dp from "../../assets/avatar.png";
import "./styles.css";
import Success from "./Success";

import { FetchBioDaata } from "../../actions/user";

const EmployeeInfo = () => {
	const [toggle, setToggle] = useState(1);
	const [success, setSuccess] = useState(false);

	const changeTab = (index) => {
		setToggle(index);
	};

	const showSuccess = () => {
		setSuccess(true);
	};

	const dispatch = useDispatch();
	const { id } = JSON.parse(localStorage.getItem("user-info"));

	const userData = useSelector((state) => state.users.biodata);

	useEffect(() => {
		dispatch(FetchBioDaata(id));
	}, [dispatch]);

	return (
		<Container className="info">
			{!success ? (
				<>
					<h1>Customer Information</h1>
					<p> Confirm that the details retrieved match your profile</p>

					<Wrapper>
						<DpNo>
							<Dp>
								<img src={dp} alt="display" />
							</Dp>

							<div>
								<h3>{userData.customer_name}</h3>
								<h5>Mobile : {userData.phone_number}</h5>
							</div>
						</DpNo>

						<Infos>
							<Btns>
								<Button to="/bio-data">Bio-Data</Button>
								<Button to="/employee-info">Employee Info</Button>
								<Button to="/account-info">Account Information</Button>
								<Button to="/take-loan">Loan Details</Button>
							</Btns>

							<Details>
								<Group>
									<div>
										<h3>ORGANIZATION</h3>
										<h5>{userData.company_name}</h5>
									</div>

									<div>
										<h3>RETIREMENT DATE</h3>
										<h5></h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>EMPLOYEE NO</h3>
										<h5></h5>
									</div>
								</Group>

								<Group>
									<div>
										<h3>EMPLOYMENT DATE</h3>
										<h5></h5>
									</div>
								</Group>
							</Details>
						</Infos>
					</Wrapper>
				</>
			) : (
				<Wrapper success={success}>
					<Success />
				</Wrapper>
			)}
		</Container>
	);
};

export default EmployeeInfo;

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4rem 10rem;
	background: #e8e9ec;

	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}

	h1 {
		text-align: center;
	}

	p {
		margin: 10px 0 2rem 0;
		font-size: 17px;
		text-align: center;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: #fff;
	width: 100%;
`;

const DpNo = styled.div`
	display: flex;
	padding: 15px;

	@media screen and (max-width: 330px) {
		flex-direction: column;
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: center;

		h3 {
			color: #4d4f5c;
			margin-bottom: 15px;

			@media screen and (max-width: 400px) {
				font-size: 15px;
			}

			@media screen and (max-width: 330px) {
				margin-top: 15px;
			}
		}

		h5 {
			color: #707070;
			font-weight: 100;
			font-size: 14px;
		}
	}
`;

const Dp = styled.div`
	width: 90px;
	height: 90px;
	border-radius: 50%;
	margin-right: 150px;

	@media screen and (max-width: 860px) {
		margin-right: 50px;
	}

	@media screen and (max-width: 480px) {
		margin-right: 10px;
	}

	@media screen and (max-width: 400px) {
		width: 60px;
		height: 60px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;

const Infos = styled.div`
	display: flex;
	flex-direction: column;
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 150px 150px 200px 150px;
	border: 1px solid #e9e9f0;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 18px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: black;

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Details = styled.div``;

const Group = styled.div`
	display: flex;
	padding: 40px 0 15px 50px;
	border-bottom: 1px solid #e9e9f0;

	@media screen and (max-width: 860px) {
		padding: 40px 15px 15px 15px;
	}

	@media screen and (max-width: 555px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}

	div {
		display: flex;
		flex-direction: column;
		width: 400px;

		h3 {
			font-weight: 400;
			color: #4d4f5c;
			margin-bottom: 15px;
		}

		h5 {
			color: #43425d;
			font-size: 15px;
			font-weight: 200;
		}
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	padding: 15px 15px 60px 15px;
	width: 80%;
	position: relative;

	@media screen and (max-width: 860px) {
		width: 100%;
	}

	@media screen and (max-width: 610px) {
		grid-template-columns: 1fr;
	}
`;

const Box = styled.div`
	border: 1px solid #bbb;
	padding: 8px 20px;
	color: #bbb;
	font-size: 14px;
	font-weight: 500;
`;

const Range = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
`;

const Proceed = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 15px;
	border-radius: 3px;
	background: #3b86ff;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	position: absolute;
	right: 15px;
	bottom: 15px;
	cursor: pointer;
`;
