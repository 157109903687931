import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//svgs
import { ReactComponent as View } from "../../assets/icons/search.svg";
import ReactLoading from "react-loading";
import { FetchLoans } from "../../actions/takeLoan";
import Select from "react-select";
//components
import DashboardTopbar from "../../components/DashboardTopbars/Client";
import { Pajination } from "../../components/pagination/Pajination";
import NumberFormat from "react-number-format";

const CustomerHistory = () => {
  const [dayArray, setdayArray] = useState([]);
  const [yearArray, setyearArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialValue = {
    month: "0",
    year: "0",
  };
  // const initialData = {
  // 	search: "",
  // };
  const [reportInfo, setreportInfo] = useState(initialValue);
  const [loanArray, setloanArray] = useState(null);
  const [search, setsearch] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("user-info"));
  let user_id = userInfo?.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchLoans(user_id));
  }, [dispatch]);
  const token = localStorage.getItem("user-token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
   
  };
  const config2 = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'arraybuffer',
  };
  const retrieveLoans = async () => {
    await axios
      .get(`user/loans/${user_id}`, config)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setloanArray(response.data.LoanHistories);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    retrieveLoans();
  }, []);
  
  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const yearOptions = yearArray.map((year, index) => {
    return {
      label: year,
      value: year,
      key: index,
    };
  });

  useEffect(() => {
    let employers = [];
    let dates = [];
    let months = [];

    for (let i = 0; i < loanArray?.length; i++) {
      let employer = loanArray[i]?.company_name;
      let date = loanArray[i].created_at;
      let year = new Date(date).getFullYear();
      let month = new Date("2021-01-15").getMonth();
      let newMonth = month + 1;

      months.push(newMonth);
      if (!employers.includes(employer)) {
        employers.push(employer);
      }
      if (!dates.includes(year)) {
        dates.push(year);
      }

      // setemployerArray(employers);

      setyearArray(dates);
    }
  }, [loanArray]);

  useEffect(() => {
    const getDaysInMonth = (selectMonth, selectYear) => {
      //
      // console.log(reportInfo?.month);
      // console.log(reportInfo?.year);
      return new Date(selectYear, selectMonth, 0).getDate();
    };
    const { month, year } = reportInfo;
    const selectMonth = reportInfo?.month;
    const selectYear = reportInfo?.year;
    const dateNumber = getDaysInMonth(selectMonth, selectYear);
    // console.log(dateNumber);

    const newDayArray = [];
    for (let i = 1; i <= dateNumber; i++) {
      newDayArray.push(i);
    }
    setdayArray(newDayArray);
  }, [reportInfo]);

  
  const handleMonthChange = (value) => {
    setreportInfo({
      ...reportInfo,
      month: value.value,
    });
  };
  const handleYearChange = (value) => {
    setreportInfo({
      ...reportInfo,
      year: value.value,
    });
  };

  //new pagination

  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(loanArray?.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, loanArray]);

  const generateReport = (e) => {
    e.preventDefault();
    console.log(reportInfo);
  console.log(config2)
    axios.get(`/user/generate-report/${user_id}/${reportInfo?.year}/${reportInfo?.month}`, config2)
    .then((response)=>{
      console.log(response)
      
       const url = window.URL.createObjectURL(new Blob([(response.data)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
    const link = document.createElement('a');
    console.log(url)
    link.href = url;
    link.setAttribute('download', 'report'); //or any other extension
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    })
    .catch((error)=>{
        
      console.log({error})
      toast.error ("Error Generating Report")
    })
  }
  return (
    <Container>
      <Contents>
        <DashboardTopbar />

        <Board>
          <HeadingSearch>
            <h2>Customers</h2>
          </HeadingSearch>
          <Info>
            <Space />

            <Details>
              <div>
                <Report>
                  <TextBox>
                    <h3>Loans</h3>
                  </TextBox>
                  <SelectBox>
                    <Box>
                      <p>Year</p>
                      <Select
                        placeholder="Year"
                        options={yearOptions}
                        onChange={handleYearChange}
                        name="year"
                      />
                    </Box>
                    <Box>
                      <p>Month</p>
                      <Select
                        placeholder="Month"
                        options={monthOptions}
                        onChange={handleMonthChange}
                        name="month"
                      />
                    </Box>
                  
                  </SelectBox>
                  <button onClick={generateReport}>
                    Generate Report
                  </button>
                </Report>
              </div>

              <TableBox>
                <Table>
                  <tr>
                    <th>#</th>
                    <th>DATE</th>
                    <th>LOAN AMOUNT (&#8358;)</th>
                    <th>INTEREST(&#8358;)</th>
                    <th>TENURE</th>
                   
                    <th>MONTHLY PAYMENT (&#8358;)</th>
                    <th>ACTIONS</th>
                  </tr>{" "}
                  {currentItems &&
                    currentItems
                      .filter((loan) => {
                        if (!search) return true;

                        if (
                          loan.company_name.includes(search) ||
                          loan.created_at.includes(search) ||
                          loan.requested_amount.includes(search)
                        ) {
                          return true;
                        }
                        return false;
                      })
                      .map((data, index) => {
                        let date = data.created_at?.split(" ");
                        return (
                          <tr key={data.id}>
                            <td>{index + 1}</td>
                            <td>{date[0]}</td>
                            <td>
                              <NumberFormat
                                value={data.requested_amount}
                              
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              />
                            </td>
                            <td> <NumberFormat
                                value={data.interest_rate}
                              
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></td>
                            <td>{data.tenure}</td>
                           
                            <td> <NumberFormat
                                value={data.monthly_installment}
                              
                                decimalSeparator="."
                                thousandSeparator={true}
                                displayType="text"
                              /></td>
                            <td>
                              <Link to={`/dashboard/history/${data.id}`}>
                                <View />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  <Loader
                    style={{ display: loading ? "block" : "none" }}
                    loading={loading}
                  >
                    <ReactLoading type={"spin"} color="#000" />
                  </Loader>
                </Table>
              </TableBox>
              {loanArray?.length > 10 ? (
                <Pajination
                  itemsPerPage={itemsPerPage}
                  items={loanArray}
                  setItemOffset={setItemOffset}
                />
              ) : null}
            </Details>
          </Info>
        </Board>
      </Contents>
      <ToastContainer
        className="toast"
        position="top-right"
        autoClose="4000"
        hideProgressBar="false"
        closeOnClick
      ></ToastContainer>
    </Container>
  );
};

export default CustomerHistory;

//styles
const Container = styled.section`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 200px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    padding-left: 0;
  }
`;
const TextBox = styled.div`
  width: 15%;
  h3 {
    color: #616161;
  }
`;
const Board = styled.div`
  background: #f0f0f7;
  padding: 1rem 2rem 2rem 5rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  @media screen and (max-width: 700px) {
    padding: 1rem 2rem 2rem 2rem;
  }
`;

const HeadingSearch = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }

  h2 {
    font-weight: 200;

    @media screen and (max-width: 920px) {
      margin-bottom: 15px;
    }
  }
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 400px;
  background: #fff;

  button {
    width: 8%;
    border-radius: 50%;
    padding: 0.3rem 0.2rem;
    border: none;
    padding: none;
  }
  @media screen and (max-width: 920px) {
    width: 100%;
  }

  input {
    width: 87%;
    outline: none;
    border: none;
  }
`;

const Info = styled.div`
  display: flex;
  background: #fff;
  min-height: 70vh;
`;

const Space = styled.div`
  width: 0px;
  display: none;
  border-right: 1px solid #e9e9f0;

  @media screen and (max-width: 1180px) {
    display: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;

const Report = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* @media screen and (max-width: 930px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  } */

  @media screen and (max-width: 759px) {
     display: grid;
    grid-template-columns: 1fr;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #3b86ff;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 10px 12px;
    cursor: pointer;
    font-size: 15px;
    @media only screen and (max-width: 759px) {
      margin-bottom: 1rem;
    }
  }
`;

const SelectBox = styled.div`
  width: 60%;
  display: flex;
  
  @media only screen and (max-width: 759px) {
    width: 100%;
    margin: 1rem 0;
  }
`;

const Box = styled.div`
  width: 42%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  font-size: 15px;
  color: #808495;
  font-weight: 100;
  .css-b62m3t-container {
    width: 100%;
  }
  p {
    display: none;
  }

  @media screen and (max-width: 930px) {
    margin-right: 0;
  }
@media screen and (max-width: 1024px) and (min-width: 760px) {
  width: 50%;
}
  @media only screen and (max-width: 480px) {
    width: 50%;
    padding: 0;
    p {
      display: block;
      margin-bottom: 0.2rem;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;

  @media screen and (max-width: 1090px) {
    width: 800px;
  }

  th {
    background: #e9e9f0;
    color: #808495;
    padding: 8px 4px;
    font-weight: 400;
    font-size: 14px;
    border: none;
    text-align: left;
    margin-right: -1rem;
  }

  td {
    padding: 8px;
    color: #43425d;

    font-size: 13px;
    border-bottom: 1px solid #e9e9f0;
  }
`;

const Pages = styled.div`
  display: flex;
  justify-content: end;

  &:last-child {
    margin-right: 0;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    color: #43425d;
    font-size: 14px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e9e9f0;
    margin-right: 5px;
    cursor: pointer;
  }
`;

const Loader = styled.div`
  /* display: ${({ loading }) => (loading ? "flex" : "none")}; */
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


const TableBox = styled.div`
  
  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
   &::-webkit-scrollbar {
  display: none;
}
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-overflow-style: none;
  @media screen and (max-width: 1240px) {
	  overflow-x: scroll;
  }
`;
