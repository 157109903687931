import React from "react";
import styled, { css } from "styled-components/macro";
import man from "../../assets/man.png";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${man});
	background-color: #039be5;
	background-size: cover;
	color: #fff;
	padding: 4rem 10rem;
	position: relative;

	@media screen and (max-width: 1200px) {
		padding: 4rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 4rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 4rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem;
	}

	h4 {
		text-align: center;
		margin-bottom: 30px;
		z-index: 1;
		font-size: 15px;
		font-weight: normal;
		width: 60%;

		@media screen and (max-width: 1100px) {
			width: 80%;
		}

		@media screen and (max-width: 900px) {
			width: 100%;
		}
	}

	// &:before {
	//     content: '';
	//     position: absolute;
	//     width: 100%;
	//     height: 100%;
	//     background: #0872BA;
	//     opacity: .36;
	// }
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	background: #f79421;
	outline: none;
	border: none;
	color: #fff;
	font-weight: 500;
	font-size: 15px;
	z-index: 1;
	cursor: pointer;
`;

const Consultation = () => {
	return (
		<Container>
			<h2>Features & Benefits</h2>
			<h4>
				Flexible collateral arrangements depending on loan size and No 
				documentation is required.
			</h4>
			{/* <h2> Flexible documentation requirements.</h2> */}
			<h4>
				Long-standing clients with impeccable repayment record qualify for
				successive interest rate discounts and even faster loan processing. SMS
				reminder for your monthly repayments
			</h4>

			{/* <Button>Free Consultation</Button> */}
		</Container>
	);
};

export default Consultation;
