import React from "react";
import styled, { css } from "styled-components/macro";
import video from "../../assets/video.png";
import dell from "../../assets/dell-logo.png";

//styles
const Container = styled.div`
	display: flex;
	height: 350px;

	@media screen and (max-width: 800px) {
		flex-direction: column;
		height: auto;
	}
`;

const divstyle = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
	width: 70%;

	p {
		margin-bottom: 20px;
		font-size: 15px;
		text-align: center;
	}

	a {
		color: #fff;
		font-size: 15px;
		text-decoration: underline;
		font-weight: 200;
	}
`;

const WtCs = css`
	display: flex;
	flex-direction: column;
	align-items: start;
	position: absolute;
	bottom: 0;
	left: 2rem;

	h3 {
		font-size: 18px;
	}

	p {
		margin-bottom: 0;
		font-size: 15px;
	}
`;

const Video = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${video});
	background-size: cover;
	font-weight: 550;
	color: #e0e0e0;
	text-align: center;
	width: 50%;
	padding: 3rem 1rem;
	position: relative;

	@media screen and (max-width: 800px) {
		width: 100%;
		height: 300px;
	}

	@media screen and (max-width: 450px) {
		height: 350px;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		background: #0872ba;
		opacity: 0.66;
	}

	div {
		${divstyle};
		a {
			color: #fff;
			text-align: center;
			font-weight: 550;
			font-size: 1rem;
		}
	}
	div {
		p {
			font-weight: 0.8rem;
		}
	}
`;

const Wt = styled.div`
	${WtCs};
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	padding: 2rem 0rem;
	position: relative;
	width: 50%;
	font-weight: 550;
	@media screen and (max-width: 800px) {
		width: 100%;
		height: 350px;
	}

	@media screen and (max-width: 500px) {
		height: 400px;
	}

	img {
		width: 90px;
		margin-bottom: 30px;
	}

	div {
		${divstyle};

		a {
			color: #01579b;
			text-align: center;
			font-weight: 550;
			font-size: 1.3rem;
		}
	}
	div {
		p {
			font-weight: 0.8rem;
		}
	}
`;

const Cs = styled.div`
	${WtCs};
	color: #000;
`;

const VidDet = () => {
	return (
		<Container>
			<Video>
				<div>
					<p>
						We Strive For Quality and Ensure Integrity,
						<br /> Professionalism and Commitment to Every Engagement
					</p>

					<a href="/">Play Video</a>
				</div>

				<Wt>
					<h3>What Customers </h3>
					<p>Think About Us</p>
				</Wt>
			</Video>

			<Details>
				<img src={dell} alt="Dell-Logo" />
				<div>
					<p style={{ color: "#455a64" }}>
						We Strive For Quality and Ensure Integrity,
						<br /> Professionalism and Commitment to Every Engagement
					</p>
					<a href="/">Details</a>
				</div>

				<Cs>
					<h3 style={{ color: "#424242" }}>Our Case Studies</h3>
					<p style={{ marginTop: "0.2rem" }}> What we solved</p>
				</Cs>
			</Details>
		</Container>
	);
};

export default VidDet;
