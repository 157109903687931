import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
// import LoanDetails from "./components/LoanDetails/LoanDetails";
import GlobalStyle from "./GlobalStyles";

//components
import {
	Homepage,
	Loan,
	CustomerLogin,
	CustomerSignup,
	Success,
	TakeLoan,
	BioData,
	AccountInfo,
	AdminBioData,
	AdminEmployeeInfo,
	AdminAccountInfo,
	CustomerBoard,
	CustomerHistory,
	CustomerProfile,
	CustomerSettings,
	CustomerHelp,
	EmployeeInfo,
	AdminLogin,
	AdminBoard,
	AdminCustomers,
	UniqueCustomer,
	AdminSettings,
	SuperAdminLogin,
	SuperAdmin,
	AdminHelp,
	EditAmin,
	CreateAmin,
	UpdateAmin,
	ShowAmin,
	AdminLog,
	CreateCharge,
	ShowCharge,
	UpdateCharge,
	ChargesSuccess,
	ConfirmOTP,
} from "./pages";
import AdminProfile from "./pages/AdminDashboard/AdminProfile";
import ClientLoanDetails from "./pages/ClientDashboard/ClientLoanDetails";
import ConfirmLoanDetails from "./components/CustomerInformation/ConfirmLoanDetails";
import { ProtectedRoute } from "./ProtectedRoute";
import { AdminProtectedRoute } from "./AdminProtectedRoute";
import { LoanProtectedRoute } from "./LoanProtectedRoute";
import { UserLoginRoute } from "./UserLoginRoute";
import { AdminLoginRoute } from "./AdminLoginRoute";
import { NotFound } from "./NotFound";
import { CustomerProfileEdit } from "./pages/ClientDashboard/CustomerProfileEdit";
import CurrentCustomers from "./pages/AdminDashboard/CurrentCustomers";
import LoanDisbursement from "./pages/AdminDashboard/LoanDisbursement";
import LoanRepayment from "./pages/AdminDashboard/LoanRepayment";
import AdminCustomerRepayment from "./pages/AdminDashboard/AdminCustomerRepayment";
import AdminCustomerList from "./pages/AdminDashboard/AdminCustomerList";
import { AdminProfileEdit } from "./pages/AdminDashboard/AdminProfileEdit";
import CustomerTransaction from "./pages/ClientDashboard/CustomerTransaction";
import LoanDetails from "./components/LoanDetails/LoanDetails";
import ConfirmEmail from "./pages/ConfirmEmail";
import ResetPassword from "./pages/ResetPassword";

function App() {
	return (
		<>
			<Router>
				<GlobalStyle />

				<Switch>
					<Route path="/" exact component={Homepage} />
					<Route path="/signup" exact component={CustomerSignup} />
					<Route path="/confirm-email" exact component={ConfirmEmail} />
					<Route path="/reset-password" exact component={ResetPassword} />
					<UserLoginRoute path="/login" exact component={CustomerLogin} />
					<AdminLoginRoute path="/admin" exact component={AdminLogin} />
					<Route path="/loan" exact component={Loan} />
					<LoanProtectedRoute
						path="/confirm-otp"
						exact
						component={ConfirmOTP}
					/>
					{/* <LoanProtectedRoute path="/customer-info" exact component={CustomerInfo} /> */}
					<Route path="/success" exact component={Success} />
					<LoanProtectedRoute path="/take-loan" exact component={TakeLoan} />
					<LoanProtectedRoute
						path="/confirm-loan"
						component={ConfirmLoanDetails}
					/>
					<ProtectedRoute path="/biodata" exact component={BioData} />
					<ProtectedRoute
						path="/employee-info"
						exact
						component={EmployeeInfo}
					/>
					<ProtectedRoute path="/account-info" exact component={AccountInfo} />
					{/* Customer */}
					<ProtectedRoute path="/dashboard" exact component={CustomerBoard} />
					<ProtectedRoute
						path="/dashboard/history"
						exact
						component={CustomerHistory}
					/>
					<ProtectedRoute
						path="/dashboard/transactions"
						exact
						component={CustomerTransaction}
					/>
					<ProtectedRoute
						path={`/dashboard/history/:id`}
						exact
						component={ClientLoanDetails}
					/>
					<ProtectedRoute
						path="/dashboard/profile"
						exact
						component={CustomerProfile}
					/>
					<ProtectedRoute
						path="/dashboard/profile/edit"
						exact
						component={CustomerProfileEdit}
					/>

					<ProtectedRoute
						path="/dashboard/settings"
						exact
						component={CustomerSettings}
					/>
					<ProtectedRoute
						path="/dashboard/help"
						exact
						component={CustomerHelp}
					/>
					{/* Admin */}
					<AdminProtectedRoute
						path="/admin-dashboard"
						exact
						component={AdminBoard}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/customers"
						exact
						component={AdminCustomers}
					/>
					{/* <AdminProtectedRoute
						path="/admin-dashboard/allCustomers"
						exact
						component={AdminCustomerList}
					/> */}
					<AdminProtectedRoute
						path="/admin-dashboard/customers/current"
						exact
						component={CurrentCustomers}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/disbursements"
						exact
						component={LoanDisbursement}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/repayments"
						exact
						component={LoanRepayment}
					/>
					<AdminProtectedRoute
						path={`/admin-dashboard/customers/:user`}
						exact
						component={UniqueCustomer}
					/>
					<AdminProtectedRoute
						path={`/admin-dashboard/customers/loanInfo/:user/:id`}
						exact
						component={LoanDetails}
					/>
					<AdminProtectedRoute
						path={`/admin-dashboard/customers/repayment/:id`}
						exact
						component={AdminCustomerRepayment}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/login"
						exact
						component={SuperAdminLogin}
					/>
					<AdminProtectedRoute
						path="/admin/dashboard/profile"
						exact
						component={AdminProfile}
					/>
					<AdminProtectedRoute
						path="/admin/dashboard/profile/edit"
						exact
						component={AdminProfileEdit}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/settings"
						exact
						component={AdminSettings}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/superadmin"
						exact
						component={SuperAdmin}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/help"
						exact
						component={AdminHelp}
					/>
					<AdminProtectedRoute
						path={`/admin-bio-data/:id`}
						exact
						component={AdminBioData}
					/>
					<AdminProtectedRoute
						path={`/admin-employee-info/:id`}
						exact
						component={AdminEmployeeInfo}
					/>
					<AdminProtectedRoute
						path={`/admin-account-info/:id`}
						exact
						component={AdminAccountInfo}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/create"
						exact
						component={CreateAmin}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/edit/:id"
						exact
						component={CreateAmin}
					/>
					{/* <AdminProtectedRoute
						path="/admin-dashboard/edit"
						exact
						component={SuperAdmin}
					/> */}
					<AdminProtectedRoute
						path="/admin-dashboard/show"
						exact
						component={ShowAmin}
					/>
					<AdminProtectedRoute
						path="/admin-dashboard/log"
						exact
						component={AdminLog}
					/>
					<AdminProtectedRoute
						path="/admin-charges/create"
						exact
						component={CreateCharge}
					/>
					<AdminProtectedRoute
						path={`/admin-charges/update/:id`}
						exact
						component={CreateCharge}
					/>
					<AdminProtectedRoute
						path="/admin-charges/show"
						exact
						component={ShowCharge}
					/>
					<AdminProtectedRoute
						path="/admin-charges/success"
						exact
						component={ChargesSuccess}
					/>
					<Route path="*" exact component={NotFound} />
				</Switch>
			</Router>
		</>
	);
}

export default App;
