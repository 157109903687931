import React, { useEffect, useRef } from "react";

//components
import Navbar from "../components/Navbar/Navbar";
import Banner from "../components/Banner/LoanBanner";
import Requirements from "../components/Requirements/Requirements";
import Confirmotp from "../components/RequestForm/ConfirmOTP";
import Footer from "../components/Footer/Footer";
import { scrollTo } from "../utils";

const ConfirmOTP = () => {
	const confirmRef = useRef();

	useEffect(() => {
		scrollTo({
			ref: confirmRef,
		});
	}, []);

	useEffect(() => {}, [confirmRef]);

	return (
		<>
			<Navbar />
			<Banner />
			<Requirements />
			<Confirmotp ref={confirmRef} />
			<Footer />
		</>
	);
};

export default ConfirmOTP;
