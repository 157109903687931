import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

//styles
const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    transition: .1s ease-in-out;
    opacity: ${({ isopen }) => (isopen ? '1' : '0')};
    top: ${({ isopen }) => (isopen ? '0' : '-100%')};
    padding: 2rem 5rem;
    margin-top: 75px;

    @media screen and (max-width: 600px) {
        padding: 2rem 4rem;
    }

    @media screen and (max-width: 500px) {
        padding: 2rem 3rem;
    }

    @media screen and (max-width: 450px) {
        padding: 2rem 2rem;
    }
`

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    text-align: center;
`

const Sidebar = ({ isopen, toggle }) => {
    return (
        <Container  isopen={isopen}>
            <Button to="/login" onClick={toggle} style={{background: '#3B86FF', marginBottom: '20px'}}>Login</Button>
            <Button to="/loan" onClick={toggle} style={{background: '#50CDF6'}}>Instant Loan</Button>
        </Container>
    )
}

export default Sidebar
