import styled from "styled-components";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const Container = styled.div`
	width: 100%;
	text-align: center;
	margin-top: 5rem;
	color: gray;
	p {
		margin-top: 1rem;
		font-size: 0.9rem;
	}
`;
const ButtonBox = styled.div`
	width: 100%;
	margin-top: 1.5rem;
	display: flex;
	justify-content: center;
`;
const Button = styled.button`
	outline: none;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	color: white;
	background-color: gray;
	border: none;
	border-radius: 4px;
`;
export const NotFound = () => {
	return (
		<Container>
			<h2>404 | PAGE NOT FOUND</h2>
			<p>Check that you entered the correct url</p>
			<ButtonBox>
				<Button>
					<Link to="/">
						{" "}
						<FaHome style={{ marginRight: "0.3rem" }} /> GO HOME
					</Link>
				</Button>
			</ButtonBox>
		</Container>
	);
};
