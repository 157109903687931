import React from "react";
import styled, { css } from "styled-components/macro";
import path from "../../assets/thirdman.jpg";
import woman from "../../assets/woman.png";

//styles
const Container = styled.div`
	display: flex;
	width: 100vw;
	height: 60vh;
	overflow: hidden;
	padding: 0 6rem;
	justify-content: space-between;
	margin: 1rem 0;
	@media screen and (max-width: 1200px) {
		padding: 0 0 0 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 0 0 0 7rem;
	}

	@media screen and (max-width: 920px) {
		flex-direction: column;
		padding: 4rem 6rem 0;
	}
	@media screen and (max-width: 768px) {
		padding: 4rem 5rem 0;
	}
	@media screen and (max-width: 600px) {
		padding: 4rem 4rem 0;
	}
	@media screen and (max-width: 500px) {
		padding: 4rem 3rem 0;
	}

	@media screen and (max-width: 450px) {
		padding: 4rem 2rem 0;
	}
`;

const Text = styled.div`
	display: flex;
	flex-direction: column;
	padding: 4rem 0;
	width: 48%;

	@media screen and (max-width: 920px) {
		width: 100%;
		padding: 0;
	}

	.first-parag {
		margin-bottom: 40px;
	}

	p {
		font-size: 15px;
	}
`;

const Image = styled.div`
	background-image: url(${path});
	background-size: cover;
	background-repeat: no-repeat;
	padding: 3rem 0 0 5rem;
	position: relative;
	width: 48%;
	top: 3px;

	@media screen and (max-width: 920px) {
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: 500px 500px;
		padding: 3rem 0 0 0;
		width: 100%;
		height: 450px;
	}

	img {
		position: absolute;
		bottom: 0;

		@media screen and (max-width: 920px) {
			position: initial;
		}
	}
`;

const Description = () => {
	return (
		<Container>
			<Text>
				<p className="first-parag">
					<span style={{ color: "#0872BA" }}>Fullrange Microfinance Bank</span>{" "}
					offers salary earners the opportunity to easily access loans in a
					quick, transparent and efficient environment. We offer salary earners
					the opportunity to access their salary in advance in a quick,
					transparent and efficient way.
				</p>
				<div>
					<p style={{ marginBottom: "40px" }}>
						Building on our global expertise and attaining international
						standards, we can assure our clients highest quality service and
						delivery. Prospective borrowers can save money with Fullrange
						Microfinance Bank and also apply for a loan, as we do not support
						forced savings. All that is needed is an existing account with
						Remita.
					</p>

					<p style={{ marginBottom: "10px" }}>
						Our Loans currently range from N 20,000. to N5,000,000 with a
						minimum tenor of 1month and a maximum tenor of 18 months.
					</p>
				</div>

				{/* <span style={{ color: "#0872BA" }}>About Fullrange Loan</span>{" "}
					Fullrange Microfinance Bank offers entrepreneurs the opportunity to
					easily access loans in a quick, transparent and efficient environment.
					We offer business owners of Small and Medium Size Enterprises the
					opportunity to access loan products in a quick, transparent and
					efficient way. Building on our global expertise and attaining
					international standards, we can assure our clients highest quality
					service and delivery. Prospective borrowers can save money with
					Fullrange Microfinance Bank and also apply for a loan, as we do not
					support forced savings. All that is needed is an existing, viable
					business.
				

				<div>
					<p style={{ marginBottom: "10px" }}>
						What sets FULLRANGE Microfinance Bank apart is primarily the
						efficiency and ease of access to credit facilities. Documentation
						and collateral requirements are significantly more flexible than
						anywhere else in the Nigerian banking sector, and the processing
						time is the shortest available.
					</p>

					<p>
						Our expertise, Willingness to serve and the drive at Fullrange MFB
						assure our clients quality service and delivery. Our Loans currently
						range from N 20,000. to N5,000,000 with a minimum tenor of 1month
						and a maximum tenor of 18 months
					</p>
				 */}
			</Text>

			<Image>
				{/* <Path src={path} alt="loan" /> */}
				{/* <img
					src={woman}
					alt="loan"
					style={{
						height: "440px",
						position: "relative",
						zIndex: "10",
					}}
				/> */}
			</Image>
		</Container>
	);
};

export default Description;
